import React, { MouseEvent } from 'react';
import classnames from 'classnames';

import { isExist } from 'util/helper';

import Badge from 'components/atoms/Badge';

import styles from './index.css';

interface IconWithBadge extends IconProperty {
	badgeContent: string | number;
    animationDependency?: any;
}

export const IconWithBadge: React.FC<IconWithBadge> = ({
	className,
	src: Svg,
	badgeContent = '',
    animationDependency = undefined,
	onClick = () => {},
}) => (
	<div
		className={classnames(styles.icon, className, {
			[styles.clickable]: isExist(onClick),
		})}
		role="button"
		tabIndex={0}
		onClick={onClick}
		onKeyPress={() => {}}
	>
		{(badgeContent || badgeContent !== 0) && <Badge className={styles.badge} animationDependency={animationDependency}>{badgeContent}</Badge>}
		<Svg />
	</div>
);

interface IconWithLabel extends IconProperty {
	labelContent: string;
	labelColor?: 'red' | 'yellow';
}

export const IconWithLabel: React.FC<IconWithLabel> = ({
	className,
	src: Svg,
	labelContent = '',
	labelColor = 'red',
	onClick = () => {},
}) => (
	<div
		className={classnames(styles.icon, className, {
			[styles.clickable]: isExist(onClick),
		})}
		role="button"
		tabIndex={0}
		onClick={onClick}
		onKeyPress={() => {}}
	>
		{labelContent && (
			<div className={classnames(styles.label, isExist(labelColor) && styles[labelColor])}>
				{labelContent}
			</div>
		)}
		<Svg />
	</div>
);

interface IconProperty {
	className?: string;
	src: string;
	onClick?: (event: MouseEvent<HTMLDivElement>) => void;
	onMouseEnter?: (event: MouseEvent<HTMLDivElement>) => void;
	onMouseLeave?: (event: MouseEvent<HTMLDivElement>) => void;
}

const Icon: React.FC<IconProperty> = ({
	className,
	src: Svg,
	onClick,
	onMouseEnter,
	onMouseLeave,
}) => (
	<div
		className={classnames(styles.icon, className, {
			[styles.clickable]: isExist(onClick),
		})}
		role="button"
		tabIndex={0}
		onClick={onClick}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
		onKeyPress={() => {}}
	>
		<Svg />
	</div>
);

export default Icon;
