import React, { MouseEvent } from 'react';

import { useHistory } from 'models/routing';
import { History } from 'history';
import { useLanguage } from 'models/i18n';
import { lanList } from 'util/i18nConfig';

const onClickHandler = (callback: (e: MouseEvent<HTMLAnchorElement>) => void, history: History) => {
	return (e: MouseEvent<HTMLAnchorElement>): void => {
		const { pathname } = e.currentTarget;
		const { search } = e.currentTarget;

		e.preventDefault();
		history.push({
			pathname,
			search,
			state: { prevPathname: history.location.pathname, prevSearch: history.location.search },
		});
		callback(e);
	};
};

interface LinkProperty {
	className?: string;
	type?: 'internal' | 'external';
	withLang?: boolean;
	to?: string;
	onClick?: (e: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
}

const Link: React.FC<LinkProperty> = ({
	className,
	type = 'internal',
	withLang = true,
	to,
	onClick = () => {},
	children,
}) => {
	const history = useHistory();
	const [
		{
			i18n: { lan },
		},
	] = useLanguage();

	if (!to) {
		return (
			<div onClick={onClick} role="button" tabIndex={0} onKeyPress={() => {}} className={className}>
				{children}
			</div>
		);
	}

	if (
		type === 'external' ||
		((to?.includes('http') || to?.includes('mailto:') || to?.includes('tel:')) &&
			!to?.startsWith(process.env.SELF_HOST_ENDPOINT as string))
	) {
		return (
			<a
				className={className}
				href={
					to?.includes('http') || to?.includes('mailto:') || to?.includes('tel:')
						? to
						: `/${lan}${to}`
				}
				target="_blank"
				rel="noopener noreferrer"
				onClick={onClick}
			>
				{children}
			</a>
		);
	}

	let internalLink = to || '';

	if (to?.startsWith(process.env.SELF_HOST_ENDPOINT as string)) {
		internalLink = internalLink.replace(process.env.SELF_HOST_ENDPOINT as string, '');
	}

	if (withLang && to !== '/') {
		const lanRegex = new RegExp(lanList.map(_lan => `^\/${_lan}`).join('|'));
		internalLink = internalLink.replace(lanRegex, '');

		internalLink = `/${lan}${internalLink}`;
	}

	return (
		<a
			className={className}
			href={internalLink}
			role="button"
			tabIndex={0}
			onClick={onClickHandler(onClick, history)}
			onKeyPress={() => {}}
			style={{ cursor: 'pointer' }}
		>
			{children}
		</a>
	);
};

export default Link;
