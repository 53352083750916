import { createAction, handleActions } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import storage from 'util/storage';
import { wrapLangFetch } from 'util/api';
import { GetState, State as GlobalState } from './reducers';

export const setCompareIdList = createAction('SET_COMPARE_ID_LIST', (idList: number[]) => {
	storage.setItem('compare-list', idList.toString());
	return idList;
});

export interface FetchCompareListPayload {
	id: number[];
	pic: string[];
	pic_alt: string[];
	name: string[];
	product_no: string[];
	intro: string[];
	texture: string[];
	size: string[];
	thickness: string[];
	surface: string[];
	capacity: string[];
}

const fetchCompareList = createAction(
	'FETCH_COMPARE_LIST',
	(idList: number[]) => async (dispatch: Dispatch) => {
		const result: FetchCompareListPayload = {
			id: [],
			pic: [],
			pic_alt: [],
			name: [],
			product_no: [],
			intro: [],
			texture: [],
			size: [],
			thickness: [],
			surface: [],
			capacity: [],
		};

		if (idList.length === 0) {
			dispatch(setCompareIdList(idList));
			return result;
		}

		const { status, message, data } = await wrapLangFetch('product/compare', {
			method: 'POST',
			body: JSON.stringify({
				ids: idList,
			}),
		});

		if (status !== 200) {
			throw new Error(message);
		}

		interface DataType {
			id: number;
			pic: string;
			pic_alt: string;
			name: string;
			product_no: string;
			intro: string;
			material: string;
			size: string;
			thickness: string;
			surface_treatment: string;
			capacity: string;
		}

		data.forEach(
			({
				id,
				pic,
				pic_alt,
				name,
				product_no,
				intro,
				material: texture,
				size,
				thickness,
				surface_treatment: surface,
				capacity,
			}: DataType) => {
				return {
					id: result.id?.push(id),
					pic: result.pic?.push(pic),
					pic_alt: result.pic_alt?.push(pic_alt),
					name: result.name?.push(name),
					product_no: result.product_no?.push(product_no),
					intro: result.intro?.push(intro),
					texture: result.texture?.push(texture),
					size: result.size?.push(size),
					thickness: result.thickness?.push(thickness),
					surface: result.surface?.push(surface),
					capacity: result.capacity?.push(capacity),
				};
			},
		);

		dispatch(setCompareIdList(result.id));
		return result;
	},
);

interface CheckCompareListResponseProperty {
	id: number;
	product_no: string;
	name: string;
	price: number;
	pic: string;
	pic_alt: string;
}

const checkCompareList = createAction(
	'CHECK_COMPARE_LIST',
	(idList: number[]) => async (dispatch: Dispatch) => {
		const { status, message, data } = await wrapLangFetch('product/cart/check', {
			method: 'POST',
			body: JSON.stringify({
				ids: idList,
			}),
		});

		if (status !== 200) {
			throw new Error(message);
		}

		if (data.length === 0) {
			dispatch(setCompareIdList(data));
			return;
		}

		dispatch(fetchCompareList((data as CheckCompareListResponseProperty[]).map(({ id }) => id)));
	},
);

export const initializeCompare = createAction(
	'INITIALIZE_COMPARE',
	() => async (dispatch: Dispatch<AnyAction>, getState: GetState) => {
		const {
			compare: { idList },
		} = getState();
		dispatch(checkCompareList(idList));
	},
);

export interface State {
	loading: boolean;
	idList: number[];
	list: FetchCompareListPayload;
}

export const initialState: State = {
	loading: true,
	idList: [],
	list: {
		id: [],
		pic: [],
		pic_alt: [],
		name: [],
		product_no: [],
		intro: [],
		texture: [],
		size: [],
		thickness: [],
		surface: [],
		capacity: [],
	},
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	compare: handleActions<State, any>(
		{
			SET_COMPARE_ID_LIST: (state, action) => ({
				...state,
				idList: action.payload,
			}),

			FETCH_COMPARE_LIST_FULFILLED: (state, action) => ({
				...state,
				list: action.payload,
			}),

			INITIALIZE_COMPARE_FULFILLED: state => ({
				...state,
				loading: false,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.compare,
});

export const useCompare = () =>
	useRedux(mapHooksToState, {
		fetchCompareList,
	});
