import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';

const routes: CustomRoute = {
	path: '/faq',
	components: () => [import(/* webpackChunkName: 'faq' */ './component')],
	render: ([Faq]) => <Faq />,
	onEnter: async ({ store, next }) => {
		console.log('on Enter Faq');
		const children = await next();
		store.dispatch(storeActivePage('faq'));
		console.log('on Enter Faq / end');
		return children;
	},
};

export default routes;
