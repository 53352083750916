/* eslint-disable indent */
import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useMedia, useScroll } from 'util/event';
import { useBoolean } from 'util/hook';
import { useOnclickOutside } from 'util/hook/useOnclickOutside';

import { useRouting } from 'models/routing';
import { useAuth } from 'models/auth/common';
import { useCartList } from 'models/cart/list';
import { useModal } from 'models/modal';
import { useHeaderState } from 'models/header';
import { useMemberAccount } from 'models/member/account';

import Logo from 'components/atoms/Logo';
import Navigation from 'components/molecules/Navigation';
import Link from 'components/atoms/Link';
import Icon, { IconWithBadge } from 'components/atoms/Icon';
import UserMenu from 'components/molecules/UserMenu';
import ButtonLanguage from 'components/atoms/ButtonLanguage';
import SearchInput from 'components/atoms/SearchInput';

import SearchIcon from 'images/common/search.inline.svg';
import CartIcon from 'images/common/cart.inline.svg';
import HamburgerIcon from 'images/common/hamburger.inline.svg';
import CloseIcon from 'images/common/close.inline.svg';

import styles from './index.css';
import { withLang } from 'util/i18n';

// 桌面版 Header 屬性
interface DesktopComponentProperty {
	cartCount?: number;
	isLogin: boolean;
	logout: () => void;
}
// 桌面版 Header
const DesktopComponent: React.FC<DesktopComponentProperty> = ({
	cartCount = 0,
	isLogin = false,
	logout = () => {},
}) => {
	const { t } = useTranslation('nav');
	const [{ isTransparent }] = useHeaderState();
	const [
		{
			serverAccount: { name },
		},
	] = useMemberAccount();

	const [isShowSearchBar, { setFalse: hideSearchBar, toggle: toggleSearchBar }] = useBoolean({
		defaultBoolean: false,
	});
	const headerRef = useRef(null);
	useOnclickOutside(headerRef, () => hideSearchBar());

	const [isShowMemberMenu, { setTrue: showMemberMenu, setFalse: hideMemberMenu }] = useBoolean({
		defaultBoolean: false,
	});
	const memberRef = useRef(null);
	useOnclickOutside(memberRef, () => hideMemberMenu());
    
	return (
		<header
			ref={headerRef}
			className={classnames(styles.header, styles.desktop, {
				[styles.transparentBg]: isTransparent,
			})}
		>
			<Logo className={classnames(styles.logo, { [styles.blackBg]: !isTransparent })} />
			<Navigation whiteText={isTransparent} />
			<div className={styles.actionGroup}>
				<div
					className={styles.search}
					onClick={() => toggleSearchBar()}
					area-hidden="true"
					role="button"
					onKeyUp={() => {}}
					tabIndex={0}
				>
					<Icon src={SearchIcon} />
				</div>
				{withLang('') !== 'en/' && (
					<>
						<Link className={styles.cart} to="/cart">
							<IconWithBadge
								badgeContent={cartCount}
								src={CartIcon}
								animationDependency={cartCount}
							/>
						</Link>
						<div
							className={classnames(styles.member, { [styles.show]: isLogin })}
							ref={memberRef}
							onClick={() => showMemberMenu()}
							role="button"
							onKeyPress={() => {}}
							tabIndex={0}
						>
							<div className={styles.greeting}>Hi, {name}</div>
							<UserMenu
								className={classnames(styles.menu, { [styles.show]: isShowMemberMenu })}
								logout={logout}
							/>
						</div>
						<Link
							to="/auth/login"
							className={classnames(styles.member, { [styles.show]: !isLogin })}
						>
							{t('login')}
						</Link>
					</>
				)}
				<ButtonLanguage className={styles.lan} />
			</div>
			<div className={classnames(styles.searchBar, { [styles.show]: isShowSearchBar })}>
				<SearchInput className={styles.input} />
			</div>
		</header>
	);
};
// 手機版 Header 屬性
interface MobileComponentProperty {
	cartCount?: number;
	isMenuOpen: boolean;
	toggleMenu: () => void;
	closeMenu: () => void;
}
// 手機版 Header
const MobileComponent: React.FC<MobileComponentProperty> = ({
	cartCount = 0,
	isMenuOpen = false,
	toggleMenu = () => {},
	closeMenu = () => {},
}) => {
	const [{ isTransparent }] = useHeaderState();

	const menuRef = useRef(null);
	useOnclickOutside(menuRef, () => closeMenu());

	return (
		<header
			className={classnames(styles.header, styles.mobile, {
				[styles.transparentBg]: isTransparent,
			})}
		>
			<div className={styles.main}>
				<Logo className={styles.logo} />
				<div className={styles.actionGroup}>
					{withLang('') !== 'en/' && (
						<Link className={styles.cart} to="/cart">
							<IconWithBadge badgeContent={cartCount} src={CartIcon} animationDependency={cartCount}/>
						</Link>
					)}
					<Icon className={styles.hamburger} src={HamburgerIcon} onClick={toggleMenu} />
				</div>
			</div>
			<div className={classnames(styles.menu, { [styles.show]: isMenuOpen })} ref={menuRef}>
				<div className={styles.iconGroup}>
					<ButtonLanguage className={styles.lan} />
					<Icon className={styles.close} src={CloseIcon} onClick={toggleMenu} />
				</div>
				<SearchInput className={styles.input} />
				<Navigation className={styles.navigation} />
			</div>
		</header>
	);
};

interface HeaderProperty {
	top?: number;
}

const Header: React.FC<HeaderProperty> = () => {
	const media = useMedia();
	const [{ isLogin }, { logout }] = useAuth();
	const [isMenuOpen, { toggle: toggleMenu, setFalse: closeMenu }] = useBoolean({
		defaultBoolean: false,
	});
	const [{ cartTotalQuantity }] = useCartList();

	const [{ pathname, search }] = useRouting();
	const [
		{
			modal: { isOpen },
		},
	] = useModal();
	const [
		{ pageWithTransparentHeader, isTransparent, transparentHeight },
		{ checkIsPageWithTransparentHeader, setIsHeaderTransparent },
	] = useHeaderState();

	const { scrollY } = useScroll();

	useEffect(() => {
		closeMenu();
	}, [media, pathname, search]);

	useEffect(() => {
		checkIsPageWithTransparentHeader(pathname);
	}, [pathname, isOpen]);

	useEffect(() => {
		if (pageWithTransparentHeader) {
			let shouldTransparent = false;

			// header height: 60 or 54
			const detectHeight = transparentHeight + (media === 'desktop' ? 60 : 54);

			if (scrollY < detectHeight) {
				shouldTransparent = true;
			}

			if (shouldTransparent !== isTransparent) {
				setIsHeaderTransparent(shouldTransparent);
			}
		} else if (!pageWithTransparentHeader && isTransparent) {
			setIsHeaderTransparent(false);
		}
	}, [scrollY, transparentHeight, pageWithTransparentHeader]);

	return media === 'desktop' ? (
		<DesktopComponent cartCount={cartTotalQuantity} isLogin={isLogin} logout={logout} />
	) : (
		<MobileComponent
			cartCount={cartTotalQuantity}
			isMenuOpen={isMenuOpen}
			toggleMenu={toggleMenu}
			closeMenu={closeMenu}
		/>
	);
};

export default Header;
