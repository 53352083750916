import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage, initializeCommon } from 'models/common';
import { closeAllModal } from 'models/modal';
import { initializeHome } from 'models/home';

import NewsRoute from './News';
import ArticleRoute from './Article';
import AboutRoute from './About';
import HistoryRoute from './History';
import CategoryRoute from './Category';
import ProductRoute from './Product';
import CompareRoute from './Compare';
import DownloadRoute from './Download';
import ContactRoute from './Contact';
import SearchRoute from './Search';
import ShopRoute from './Shop';
import InquireRoute from './Inquire';
import MemberRoute from './Member';
import CartRoute from './Cart';
import PoliciesRoute from './Policies';
import FaqRoute from './Faq';
import AuthRoute from './Auth';
import OrderRoute from './Order';
import RedirectZH from './RedirectZH';

const routes: CustomRoute = {
	path: '/',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next, store }) => {
		console.log('on Enter Root');
		window.scrollTo(0, 0);

		const {
			modal: { isOpen },
			common: { commonInitialized },
		} = store.getState();

		const pathname = store.getState().routing.pathname;
		const queryUrl = pathname === '/' ? pathname : pathname.substring(1);

		if (!commonInitialized) {
			await store.dispatch(initializeCommon(queryUrl));
		}

		if (isOpen) await store.dispatch(closeAllModal());

		const children = await next();

		console.log('on Enter Root / end');

		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'home' */ './Home')],
			render: ([Home]) => <Home />,
			onEnter: async ({ store, next }) => {
				console.log('on Enter Home');
				const children = await next();
				store.dispatch(storeActivePage('home'));
				store.dispatch(initializeHome());
				console.log('on Enter Home / end');
				return children;
			},
		},
		{
			path: '/zh',
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: '',
					components: () => [import(/* webpackChunkName: 'home' */ './Home')],
					render: ([Home]) => <Home />,
					onEnter: async ({ store, next }) => {
						console.log('on Enter Home');
						const children = await next();
						store.dispatch(storeActivePage('home'));
						store.dispatch(initializeHome());
						console.log('on Enter Home / end');
						return children;
					},
				},
				RedirectZH,
				NewsRoute,
				ArticleRoute,
				AboutRoute,
				HistoryRoute,
				CategoryRoute,
				ProductRoute,
				CompareRoute,
				DownloadRoute,
				ContactRoute,
				SearchRoute,
				ShopRoute,
				InquireRoute,
				MemberRoute,
				CartRoute,
				PoliciesRoute,
				FaqRoute,
				AuthRoute,
				OrderRoute,
			],
		},
		{
			path: '/en',
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: ['', 'cart', 'cart/result/:orderNo', 'shop', 'shop/:id', 'order'],
					components: () => [import(/* webpackChunkName: 'home' */ './Home')],
					render: ([Home]) => <Home />,
					onEnter: async ({ store, next }) => {
						console.log('on Enter Home');
						const children = await next();
						store.dispatch(storeActivePage('home'));
						store.dispatch(initializeHome());
						console.log('on Enter Home / end');
						return children;
					},
				},
				NewsRoute,
				ArticleRoute,
				AboutRoute,
				HistoryRoute,
				CategoryRoute,
				ProductRoute,
				CompareRoute,
				DownloadRoute,
				ContactRoute,
				SearchRoute,
				InquireRoute,
				// MemberRoute,
				PoliciesRoute,
				// FaqRoute,
				// AuthRoute,
			],
		},
	],
};

export default routes;
