import React, { Suspense, useEffect, useState, lazy } from 'react';
import classnames from 'classnames';

import Loading from 'layouts/Loading';
import Header from 'components/organisms/Header';
import FixedButtons from 'components/molecules/FixedButtons';
import MemberLayout from 'layouts/Member';
import CookieNotification from 'components/molecules/CookieNotification';
import SEO from 'components/atoms/SEO';

import { useRouting } from 'models/routing';
import { useHeaderState } from 'models/header';
import { useModal } from 'models/modal';

import styles from './index.css';

const Footer = lazy(() => import('components/organisms/Footer'));
const Notification = lazy(() => import('components/atoms/Notification'));
const ProductListStatus = lazy(() => import('components/atoms/ProductListStatus'));
const ModalAuth = lazy(() => import('components/organisms/ModalAuth'));
const ModalAlert = lazy(() => import('components/organisms/ModalAlert'));
const ModalNotification = lazy(() => import('components/organisms/ModalNotification'));

const Layout: React.FC = ({ children }) => {
	const [{ pathname }] = useRouting();

	if (pathname.includes('/member')) {
		return <MemberLayout>{children}</MemberLayout>;
	}

	return <>{children}</>;
};

const App: React.FC = ({ children }) => {
	const [{ pageWithTransparentHeader }] = useHeaderState();
	const [
		{
			pathname,
			queries: { modal },
		},
	] = useRouting();

	const [, { openModal }] = useModal();

	const [layout, setLayout] = useState('page');

	const detectPageType = (path: string) => {
		let type;
		switch (true) {
			case path.includes('/notSupport'):
				type = 'fullscreen';
				break;

			default:
				type = 'page';
		}

		return type;
	};

	useEffect(() => {
		setLayout(detectPageType(pathname));
	}, [pathname]);

	useEffect(() => {
		if (modal === 'contact') {
			openModal({ category: 'form', type: 'contact' });
		}
	}, [modal]);

	return (
		<Suspense fallback={<Loading />}>
			<div className={styles.app}>
				{layout !== 'fullscreen' ? (
					<>
						<Header />
						<div
							className={classnames(styles.layout, {
								[styles.withoutPaddingTop]: pageWithTransparentHeader,
							})}
						>
							<Layout>{children}</Layout>
							<FixedButtons />
						</div>

						<Suspense fallback={<Loading />}>
							<Footer />
							<Notification />
							<ProductListStatus />
							<ModalAuth />
							<ModalAlert />
							<ModalNotification />
						</Suspense>
						<CookieNotification />
					</>
				) : (
					<>{children}</>
				)}
				<SEO />
			</div>
		</Suspense>
	);
};

export default App;
