import { combineEpics } from 'redux-observable';

import { closeModalEpic, closeAllModalEpic, openModalEpic, closeModalRedirectEpic } from './modal';
import { handleError } from './error';

export default combineEpics(
	closeModalEpic,
	closeAllModalEpic,
	openModalEpic,
	closeModalRedirectEpic,
	handleError,
);
