import { createAction, handleActions, Action } from 'redux-actions';
import classnames from 'classnames';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { map, filter } from 'rxjs/operators';
import { useRedux } from 'util/hook/redux';
import { isEmpty } from 'util/helper';

import { historyPush } from './routing';
import { State as GlobalState } from './reducers';

type modalCategoryType = 'auth' | 'alert' | 'notification' | 'editor' | 'form' | 'other' | 'member';

export interface OpenModalActionState {
	category: modalCategoryType;
	type: string;
	data?: {};
}

export interface OpenModalPayloadActionState {
	category: modalCategoryType;
	type: string;
	data: {};
}

export const openModal = createAction<OpenModalActionState, OpenModalActionState>(
	'OPEN_MODAL',
	({ category, type, data = {} }) => ({
		category,
		type,
		data,
	}),
);

export interface CloseModalActionState {
	category: modalCategoryType;
}

export const closeModal = createAction<CloseModalActionState, CloseModalActionState>(
	'CLOSE_MODAL',
	({ category }) => ({ category }),
);

export const closeAllModal = createAction('CLOSE_ALL_MODAL');

const setModalBackgroundScrollY = createAction('SET_MODAL_BACKGROUND_SCROLLY', () => {
	if (!document.body.className.includes('no-scroll')) {
		document.body.style.top = `-${window.pageYOffset}px`;
		document.body.className = classnames(document.body.className, 'no-scroll');
	}
});

const restoreModalBackgroundScrollY = createAction('RESTORE_MODAL_BACKGROUND_SCROLLY', () => {
	const classNameArray = document.body.className.split(' ');
	const newClassName = classNameArray.filter(item => item !== 'no-scroll').join(' ');
	document.body.className = newClassName;

	const matchesTop = document.body.style.top.match(/\d+/g);
	document.body.style.top = 'unset';
	if (matchesTop !== null && matchesTop.length > 0) {
		window.scrollTo(0, parseInt(matchesTop[0], 10));
	} else {
		window.scrollTo(0, 0);
	}
});

export const closeModalRedirectEpic = (
	action$: ActionsObservable<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
	state$: StateObservable<GlobalState>,
) =>
	action$.pipe(
		filter(action => action.type === 'CLOSE_MODAL' && action.payload.category === 'auth'),
		map(() => {
			const {
				routing: { pathname, queries },
				auth: { token: accessToken },
			} = state$.value;

			if (
				(isEmpty(accessToken) && pathname.includes('member')) ||
				pathname.includes('login') ||
				pathname.includes('auth')
			) {
				return historyPush({ pathname: queries?.redirect || '/', withLan: false });
			}

			return { type: 'NO_NEED_TO_CAHGNE_URL' };
		}),
	);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const openModalEpic = (action$: ActionsObservable<any>) =>
	action$.pipe(
		filter(action => action.type === 'OPEN_MODAL'),
		filter(action => action.payload.category === 'auth'),
		map(() => setModalBackgroundScrollY()),
	);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const closeModalEpic = (action$: ActionsObservable<any>) =>
	action$.pipe(
		filter(action => action.type === 'CLOSE_MODAL'),
		filter(action => action.payload.category === 'auth'),
		map(() => restoreModalBackgroundScrollY()),
	);

export const closeAllModalEpic = (action$: ActionsObservable<any>) =>
	action$.pipe(
		filter(action => action.type === 'CLOSE_ALL_MODAL'),
		map(() => restoreModalBackgroundScrollY()),
	);

interface ModalData {
	type: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
}

export type State = Record<modalCategoryType, ModalData> & { isOpen: boolean };

const initialState: State = {
	auth: {
		type: '',
		data: {},
	},
	alert: {
		type: '',
		data: {},
	},
	notification: {
		type: '',
		data: {},
	},
	editor: {
		type: '',
		data: {},
	},
	form: {
		type: '',
		data: {},
	},
	other: {
		type: '',
		data: {},
	},
	member: {
		type: '',
		data: {},
	},
	isOpen: false,
};

export const reducer = {
	modal: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			OPEN_MODAL: (state, action: Action<OpenModalPayloadActionState>) => ({
				...state,
				[action.payload.category]: {
					type: action.payload.type,
					data: action.payload.data,
				},
				isOpen: true,
			}),

			CLOSE_MODAL: (state, action: Action<CloseModalActionState>) => ({
				...state,
				[action.payload.category]: {
					type: '',
					data: {},
				},
				isOpen: false,
			}),

			CLOSE_ALL_MODAL: state => ({
				...state,

				...initialState,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	modal: state.modal,
});

const modalActionsMap = {
	openModal,
	closeModal,
	setModalBackgroundScrollY,
	restoreModalBackgroundScrollY,
};

type ModalSelector = ReturnType<typeof mapHooksToState>;
type ModalActionsMap = typeof modalActionsMap;

export const useModal = () =>
	useRedux<ModalSelector, ModalActionsMap>(mapHooksToState, modalActionsMap);
