import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';

const routes: CustomRoute = {
	path: '/history',
	components: () => [import(/* webpackChunkName: 'history' */ './component')],
	render: ([History]) => <History />,
	onEnter: async ({ store, next }) => {
		console.log('on Enter History');
		const children = await next();
		store.dispatch(storeActivePage('history'));
		console.log('on Enter History / end');
		return children;
	},
};

export default routes;
