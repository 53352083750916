import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { wrapLangFetch } from 'util/api';
import { State as GlobalState } from '../reducers';

const fetchOrderResult = createAction('FETCH_ORDER_RESULT', (orderNo: string) => async () => {
	const { status, message, data } = await wrapLangFetch(`order/verify/${orderNo}`, {
		method: 'GET',
	});

	if (status !== 200) {
		throw Error(message);
	}

	return data;
});

export const initializeCartResult = createAction(
	'INITIALIZE_CART_RESULT',
	(orderNo: string) => async (dispatch: Dispatch) => {
		dispatch(fetchOrderResult(orderNo));
	},
);

interface ProductProperty {
	id: number;
	pic: string;
	pic_alt: string;
	product_no: string;
	name: string;
	quantity: number;
	price_total: string;
}

export interface State {
	loading: boolean;
	orderNo: string;
	detail: {
		products: ProductProperty[];
		amount: number;
		discount: number;
		deliveryFee: number;
		total: number;
	};
	payment: string;
	payment_date: string;
	receipt: {
		type: string;
		GUI: string;
		heading: string;
	};
	recipient: {
		name: string;
		number: string;
		email: string;
		city: string;
		dist: string;
		address: string;
	};
}

const initialState: State = {
	loading: true,
	orderNo: '',
	detail: {
		products: [],
		amount: 0,
		discount: 0,
		deliveryFee: 0,
		total: 0,
	},
	payment: '',
	payment_date: '',
	receipt: {
		type: '',
		GUI: '',
		heading: '',
	},
	recipient: {
		name: '',
		number: '',
		email: '',
		city: '',
		dist: '',
		address: '',
	},
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cartResult: handleActions<State, any>(
		{
			FETCH_ORDER_RESULT_PENDING: state => ({
				...state,
				loading: true,
			}),

			FETCH_ORDER_RESULT_REJECTED: state => ({
				...state,
				loading: false,
			}),

			FETCH_ORDER_RESULT_FULFILLED: (state, action) => ({
				...state,
				loading: false,
				orderNo: action.payload.order_no,
				detail: {
					products: action.payload.products,
					amount: action.payload.subtotal,
					discount: action.payload.promo_free,
					deliveryFee: action.payload.shipping_fee,
					total: action.payload.total,
				},
				payment: action.payload.payment_type,
				payment_date: action.payload.paid_at,
				receipt: {
					type: action.payload.invoice.type,
					GUI: action.payload.invoice.tax_id,
					heading: action.payload.invoice.title,
				},
				recipient: {
					name: action.payload.addressee.name,
					number: action.payload.addressee.number,
					email: action.payload.addressee.email,
					city: action.payload.addressee.city,
					dist: action.payload.addressee.dist,
					address: action.payload.addressee.address,
				},
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.cartResult,
});

export const useCartResult = () => useRedux(mapHooksToState, {});
