import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { wrapAuthFetch } from 'util/api';
import {
	haveFullwidth,
	isExist,
	isEmpty,
	passwordValid,
	mobileValid,
	telValid,
	emailValid,
} from 'util/helper';
import { isGuiNumberValid } from 'taiwan-id-validator';
import { openNotification } from 'models/notification';
import { t } from 'util/i18n';
import { openModal } from 'models/modal';
import { logout } from 'models/auth/common';
import { GetState, State as GlobalState } from '../reducers';

const setAccountCompany = createAction('SET_ACCOUNT_COMPANY', (company: string) => {
	const valid = isExist(company);
	return { valid, value: company };
});

const setAccountGUI = createAction('SET_ACCOUNT_GUI', (GUI: string) => {
	const valid = isGuiNumberValid(GUI) || isEmpty(GUI);
	return { valid, value: GUI };
});

const setAccountDist = createAction('SET_ACCOUNT_DIST', (dist: string) => {
	const valid = isExist(dist);
	return { valid, value: dist };
});

const setAccountCity = createAction(
	'SET_ACCOUNT_CITY',
	(city: string) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			memberAccount: {
				clientAccount: { city: prevCity },
			},
		} = getState();

		if (city !== prevCity.value) {
			dispatch(setAccountDist(''));
		}

		const valid = isExist(city);
		return { valid, value: city };
	},
);

const setAccountAddress = createAction('SET_ACCOUNT_ADDRESS', (address: string) => {
	const valid = isExist(address);
	return { valid, value: address };
});

const setAccountCountry = createAction('SET_ACCOUNT_COUNTRY', (country: string) => {
	const valid = isExist(country);
	return { valid, value: country };
});

const setAccountName = createAction('SET_ACCOUNT_NAME', (name: string) => {
	const valid = isExist(name);
	return { valid, value: name };
});

const setAccountNumber = createAction('SET_ACCOUNT_NUMBER', (number: string) => {
	const valid = (mobileValid(number) || telValid(number)) && !haveFullwidth(number);
	return { valid, value: number };
});

const setAccountEmail = createAction('SET_ACCOUNT_EMAIL', (email: string) => {
	const valid = emailValid(email) && !haveFullwidth(email);
	return { valid, value: email };
});

const setProfileStatus = createAction('SET_PROFILE_STATUS', (profileStatus: 'Y' | 'N') => {
	return profileStatus;
});

export const setAllMemberInputFromAPI = createAction(
	'SET_ALL_MEMBER_INPUT_FROM_API',
	(data: {
		profile_status: 'Y' | 'N';
		company: string;
		tax_id: string;
		city_id: number;
		dist_id: number;
		address: string;
		country: string;
		country_type: 'home' | 'FOREIGN';
		contact_name: string;
		contact_phone: string;
		email: string;
	}) => async (dispatch: Dispatch) => {
		const {
			profile_status,
			company,
			tax_id,
			city_id,
			dist_id,
			address,
			country,
			country_type,
			contact_name,
			contact_phone,
			email,
		} = data;

		dispatch(setAccountCompany(company || ''));
		dispatch(setAccountGUI(tax_id || ''));
		dispatch(setAccountCity(city_id ? `${city_id}` : ''));
		dispatch(setAccountDist(dist_id ? `${dist_id}` : ''));
		dispatch(setAccountAddress(address || ''));
		dispatch(setAccountCountry(country || ''));
		dispatch(setAccountName(contact_name || ''));
		dispatch(setAccountNumber(contact_phone || ''));
		dispatch(setAccountEmail(email || ''));
		dispatch(setProfileStatus(profile_status || 'N'));

		return {
			account: {
				company,
				GUI: tax_id,
				city: `${city_id}`,
				dist: `${dist_id}`,
				address,
				name: contact_name,
				number: contact_phone,
				email,
			},
			isForeigner: country_type === 'FOREIGN',
			profileStatus: profile_status,
		};
	},
);

export const fetchAccount = createAction(
	'FETCH_ACCOUNT',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			auth: { token },
		} = getState();

		const { status, message, data } = await wrapAuthFetch('member/profile', {
			method: 'GET',
		});

		switch (true) {
			case isEmpty(token):
				dispatch(
					openNotification({
						type: 'error',
						message: t('請先登入'),
					}),
				);
				dispatch(
					openModal({
						category: 'auth',
						type: 'login',
					}),
				);
				throw new Error(t('auth:notLogin'));
			case status === 401: // invalid token
				dispatch(
					openNotification({
						type: 'error',
						message: t('auth:expiredToken'),
					}),
				);
				dispatch(logout());
				dispatch(
					openModal({
						category: 'auth',
						type: 'login',
					}),
				);
				throw new Error(t('auth:expiredToken'));
			case status !== 200:
				dispatch(
					openNotification({
						type: 'error',
						message,
					}),
				);
				throw new Error(message);
			default: {
				dispatch(setAllMemberInputFromAPI(data));
			}
		}
	},
);

export const initializeMemberAccount = createAction(
	'INITIALIZE_MEMBER_ACCOUNT',
	() => async (dispatch: Dispatch) => {
		dispatch(fetchAccount());
	},
);

const updateAccount = createAction(
	'UPDATE_ACCOUNT',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			memberAccount: {
				isForeigner,
				serverAccount: { email: serverEmail },
				clientAccount: { company, GUI, dist, address, country, name, number, email },
			},
		} = getState();

		/* eslint-disable */
		const body = isForeigner
			? {
					company: company.value,
					tax_id: GUI.value,
					country: country.value,
					contact_name: name.value,
					contact_phone: number.value,
					email: isEmpty(serverEmail) ? email.value : undefined,
			  }
			: {
					company: company.value,
					tax_id: GUI.value,
					dist_id: dist.value,
					address: address.value,
					contact_name: name.value,
					contact_phone: number.value,
					email: isEmpty(serverEmail) ? email.value : undefined,
			  };
		/* eslint-enable */

		const { status, message, data } = await wrapAuthFetch('member/profile', {
			method: 'PUT',
			body: JSON.stringify(body),
		});

		switch (true) {
			case status === 401: // invalid token
				dispatch(
					openNotification({
						type: 'error',
						message: t('auth:expiredToken'),
					}),
				);
				dispatch(logout());
				dispatch(
					openModal({
						category: 'auth',
						type: 'login',
					}),
				);
				throw new Error(t('auth:expiredToken'));
			case status !== 200:
				dispatch(
					openNotification({
						type: 'error',
						message,
					}),
				);
				throw new Error(message);
			default: {
				dispatch(setAllMemberInputFromAPI(data));
				dispatch(
					openNotification({
						type: 'success',
						message: t('memberAccount:updateSuccess'),
					}),
				);

				const {
					company: apiCompany,
					tax_id,
					city_id,
					dist_id,
					address: apiAddress,
					contact_name,
					contact_phone,
					email: apiEmail,
				} = data;

				return {
					company: apiCompany,
					GUI: tax_id,
					city: city_id,
					dist: dist_id,
					code: `${dist_id}`,
					address: apiAddress,
					name: contact_name,
					number: contact_phone,
					email: apiEmail,
				};
			}
		}
	},
);

const setAccountConfirmPassword = createAction(
	'SET_ACCOUNT_CONFIRM_PASSWORD',
	(confirmPassword: string) => async (_: Dispatch, getState: GetState) => {
		const {
			memberAccount: { password },
		} = getState();

		const valid = confirmPassword === password.value && isExist(confirmPassword);
		return { valid, value: confirmPassword };
	},
);

const setAccountPassword = createAction(
	'SET_ACCOUNT_PASSWORD',
	(password: string) => async (dispatch: Dispatch) => {
		dispatch(setAccountConfirmPassword(''));

		const valid = passwordValid(password);
		return { valid, value: password };
	},
);

const updatePassword = createAction(
	'UPDATE_PASSWORD',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			memberAccount: { password, confirmPassword },
		} = getState();

		const { status, message, data } = await wrapAuthFetch('member/password', {
			method: 'PUT',
			body: JSON.stringify({
				password: password.value,
				password_confirmation: confirmPassword.value,
			}),
		});

		switch (true) {
			case status === 401: // invalid token
				dispatch(
					openNotification({
						type: 'error',
						message: t('auth:expiredToken'),
					}),
				);
				dispatch(logout());
				dispatch(
					openModal({
						category: 'auth',
						type: 'login',
					}),
				);
				throw new Error(t('auth:expiredToken'));
			case status !== 200:
				dispatch(
					openNotification({
						type: 'error',
						message,
					}),
				);
				throw new Error(message);
			default: {
				dispatch(setAllMemberInputFromAPI(data));
				dispatch(
					openNotification({
						type: 'success',
						message: t('memberAccount:changeSuccess'),
					}),
				);
				return data?.id;
			}
		}
	},
);

export const clearMemberAccount = createAction('CLEAR_MEMBER_ACCOUNT');

interface InputType {
	value: string;
	valid: boolean;
}

interface MemberAccountData {
	company: string;
	GUI: string;
	city: string;
	dist: string;
	address: string;
	country: string;
	name: string;
	number: string;
	email: string;
}

type ChangeKeysType<T> = {
	[P in keyof T]: InputType;
};

type ClientAccountData = ChangeKeysType<MemberAccountData>;

export interface State {
	isForeigner: boolean;
	profileStatus: 'Y' | 'N';
	serverAccount: MemberAccountData;
	clientAccount: ClientAccountData;
	password: InputType;
	confirmPassword: InputType;
	updatePasswordId: string | null;
	loading: boolean;
	error: boolean;
	message: string;
}

export const initialState: State = {
	isForeigner: false,
	profileStatus: 'N',
	serverAccount: {
		company: '',
		GUI: '',
		city: '',
		dist: '',
		address: '',
		country: '',
		name: '',
		number: '',
		email: '',
	},
	clientAccount: {
		company: {
			value: '',
			valid: false,
		},
		GUI: {
			value: '',
			valid: false,
		},
		city: {
			value: '',
			valid: false,
		},
		dist: {
			value: '',
			valid: false,
		},
		address: {
			value: '',
			valid: false,
		},
		country: {
			value: '',
			valid: false,
		},
		name: {
			value: '',
			valid: false,
		},
		number: {
			value: '',
			valid: false,
		},
		email: {
			value: '',
			valid: false,
		},
	},
	password: {
		value: '',
		valid: false,
	},
	confirmPassword: {
		value: '',
		valid: false,
	},
	updatePasswordId: null,
	loading: false,
	error: false,
	message: '',
};

export const reducer = {
	memberAccount: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_ACCOUNT_COMPANY: (state, action) => ({
				...state,
				clientAccount: {
					...state.clientAccount,
					company: {
						valid: action.payload.valid,
						value: action.payload.value,
					},
				},
			}),

			SET_ACCOUNT_GUI: (state, action) => ({
				...state,
				clientAccount: {
					...state.clientAccount,
					GUI: {
						valid: action.payload.valid,
						value: action.payload.value,
					},
				},
			}),

			SET_ACCOUNT_CITY_FULFILLED: (state, action) => ({
				...state,
				clientAccount: {
					...state.clientAccount,
					city: {
						valid: action.payload.valid,
						value: action.payload.value,
					},
				},
			}),

			SET_ACCOUNT_DIST: (state, action) => ({
				...state,
				clientAccount: {
					...state.clientAccount,
					dist: {
						valid: action.payload.valid,
						value: action.payload.value,
					},
				},
			}),

			SET_ACCOUNT_ADDRESS: (state, action) => ({
				...state,
				clientAccount: {
					...state.clientAccount,
					address: {
						valid: action.payload.valid,
						value: action.payload.value,
					},
				},
			}),

			SET_ACCOUNT_COUNTRY: (state, action) => ({
				...state,
				clientAccount: {
					...state.clientAccount,
					country: {
						valid: action.payload.valid,
						value: action.payload.value,
					},
				},
			}),

			SET_ACCOUNT_NAME: (state, action) => ({
				...state,
				clientAccount: {
					...state.clientAccount,
					name: {
						valid: action.payload.valid,
						value: action.payload.value,
					},
				},
			}),

			SET_ACCOUNT_NUMBER: (state, action) => ({
				...state,
				clientAccount: {
					...state.clientAccount,
					number: {
						valid: action.payload.valid,
						value: action.payload.value,
					},
				},
			}),

			SET_ACCOUNT_EMAIL: (state, action) => ({
				...state,
				clientAccount: {
					...state.clientAccount,
					email: {
						valid: action.payload.valid,
						value: action.payload.value,
					},
				},
			}),

			SET_PROFILE_STATUS: (state, action) => ({
				...state,
				profileStatus: action.payload,
			}),

			SET_ALL_MEMBER_INPUT_FROM_API_FULFILLED: (state, action) => ({
				...state,
				serverAccount: action.payload.account,
				isForeigner: action.payload.isForeigner,
			}),

			FETCH_ACCOUNT_PENDING: state => ({
				...state,
				loading: true,
			}),

			FETCH_ACCOUNT_FULFILLED: state => ({
				...state,
				error: false,
				loading: false,
			}),

			FETCH_ACCOUNT_REJECTED: (state, action) => ({
				...state,
				error: true,
				loading: false,
				message: action.payload.message,
			}),

			UPDATE_ACCOUNT_PENDING: state => ({
				...state,
				loading: true,
			}),

			UPDATE_ACCOUNT_FULFILLED: (state, action) => ({
				...state,
				error: false,
				loading: false,
				serverAccount: action.payload,
			}),

			UPDATE_ACCOUNT_REJECTED: (state, action) => ({
				...state,
				error: true,
				loading: false,
				message: action.payload.message,
			}),

			SET_ACCOUNT_CONFIRM_PASSWORD_FULFILLED: (state, action) => ({
				...state,
				confirmPassword: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_ACCOUNT_PASSWORD_FULFILLED: (state, action) => ({
				...state,
				password: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			UPDATE_PASSWORD_PENDING: state => ({
				...state,
				loading: true,
			}),

			UPDATE_PASSWORD_FULFILLED: (state, action) => ({
				...state,
				error: false,
				loading: false,
				password: {
					valid: false,
					value: '',
				},
				confirmPassword: {
					valid: false,
					value: '',
				},
				updatePasswordId: action.payload,
			}),

			UPDATE_PASSWORD_REJECTED: (state, action) => ({
				...state,
				error: true,
				loading: false,
				message: action.payload.message,
			}),

			CLEAR_MEMBER_ACCOUNT: state => ({
				...state,
				...initialState,
			}),
		},
		initialState,
	),
};

const selectMemberAccount = (state: GlobalState) => state.memberAccount;

const memberActionsMap = {
	setAccountCompany,
	setAccountGUI,
	setAccountCity,
	setAccountDist,
	setAccountAddress,
	setAccountCountry,
	setAccountName,
	setAccountNumber,
	setAccountEmail,
	setProfileStatus,
	updateAccount,
	setAccountPassword,
	setAccountConfirmPassword,
	updatePassword,
};

export const useMemberAccount = () => useRedux(selectMemberAccount, memberActionsMap);
