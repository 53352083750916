import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import { wrapFetch } from 'util/api';
import { t } from 'util/i18n';
import { isExist, passwordValid } from 'util/helper';
import { openNotification } from 'models/notification';
import { historyPush } from 'models/routing';
import { GetState, State as GlobalState } from '../reducers';

const setResetConfirmPassword = createAction(
	'SET_RESET_CONFIRM_PASSWORD',
	(confirmPassword: string) => async (_: Dispatch, getState: GetState) => {
		const {
			authReset: { newPassword },
		} = getState();

		const valid = confirmPassword === newPassword.value && isExist(confirmPassword);
		return { valid, value: confirmPassword };
	},
);

const setResetNewPassword = createAction(
	'SET_RESET_NEW_PASSWORD',
	(newPassword: string) => async (dispatch: Dispatch) => {
		dispatch(setResetConfirmPassword(''));

		const valid = passwordValid(newPassword);
		return { valid, value: newPassword };
	},
);

const Reset = createAction('RESET', () => async (dispatch: Dispatch, getState: GetState) => {
	const {
		authReset: { newPassword, confirmPassword },
		routing: { queries },
	} = getState();

	const { status, message } = await wrapFetch('member/resetPassword', {
		method: 'PUT',
		body: JSON.stringify({
			password: newPassword.value,
			password_confirmation: confirmPassword.value,
			code: queries.code || '',
		}),
	});

	switch (true) {
		case status === 401: // Expired code
			dispatch(
				openNotification({
					type: 'error',
					message: t('auth:resetLinkExpired'),
				}),
			);
			throw new Error(t('auth:resetLinkExpired'));
		case status === 422: // Invalid code
			dispatch(
				openNotification({
					type: 'error',
					message: t('auth:invalidResetCode'),
				}),
			);
			throw new Error(t('auth:invalidResetCode'));
		case status !== 200:
			dispatch(
				openNotification({
					type: 'error',
					message,
				}),
			);
			throw new Error(message);
		default:
			dispatch(
				openNotification({
					type: 'success',
					message: t('auth:resetSuccess'),
				}),
			);
			dispatch(historyPush({ pathname: '/auth/login' }));
	}
});

interface InputType {
	value: string;
	valid: boolean;
}

export interface State {
	newPassword: InputType;
	confirmPassword: InputType;
	loading: boolean;
	error: boolean;
	message: string;
	success: boolean;
}

const initialState: State = {
	newPassword: {
		value: '',
		valid: false,
	},
	confirmPassword: {
		value: '',
		valid: false,
	},
	loading: false,
	error: false,
	message: '',
	success: false,
};

export const reducer = {
	authReset: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_RESET_CONFIRM_PASSWORD_FULFILLED: (state, action) => ({
				...state,
				confirmPassword: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_RESET_NEW_PASSWORD_FULFILLED: (state, action) => ({
				...state,
				newPassword: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			RESET_PENDING: state => ({
				...state,
				loading: true,
			}),

			RESET_FULFILLED: state => ({
				...state,
				loading: false,
				error: false,
				success: true,
			}),

			RESET_REJECTED: (state, action) => ({
				...state,
				loading: false,
				error: true,
				message: action.payload.message,
				success: false,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.authReset,
});

export const useAuthReset = () =>
	useRedux(mapHooksToState, { setResetNewPassword, setResetConfirmPassword, Reset });
