import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { VerifyAccount } from 'models/auth/verify';

const routes: CustomRoute = {
	path: '/verify',
	components: () => [import(/* webpackChunkName: 'verify' */ './component')],
	render: ([Verify]) => <Verify />,
	onEnter: async ({ store }) => {
		const {
			routing: { queries },
		} = store.getState();
		await store.dispatch(VerifyAccount(queries.code));
	},
};

export default routes;
