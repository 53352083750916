import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { State as GlobalState } from './reducers';

const empty_data = {
	id: '',
	order_no: '',
	member: {
		company: '',
		email: '',
		id: 0,
		member_no: '',
	},
	status: '',
	unpaid_at: '',
	paid_at: '',
	processing_at: '',
	shipping_at: '',
	cancelled_at: '',
};

export const fetchOrder = createAction('FETCH_ORDER', (order_num: number | string) => {
	return async () => {
		const { status, message, data } = await wrapFetch(`order/check/${order_num}`, {
			method: 'GET',
		});

		if (status !== 200 && status !== 422 && status !== 400) {
			throw new Error(message);
		}

		if (status === 200) {
			return {
				status,
				data,
			};
		}

		return {
			status,
			empty_data,
		};
	};
});

interface OrderCheckData {
	id: number | string;
	order_no: string;
	member: {
		company: string;
		email: string;
		id: number;
		member_no: string;
	};
	status: string;
	unpaid_at: string;
	paid_at: string;
	processing_at: string;
	shipping_at: string;
	cancelled_at: string;
}

export interface State {
	status: number | null;
	data: OrderCheckData;
}

const initialState: State = {
	status: null,
	data: empty_data,
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	order: handleActions<State, any>(
		{
			// eslint-disable-next-line no-shadow
			FETCH_ORDER_FULFILLED: (state, action) => ({
				...state,
				...action.payload,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.order,
});

export const useOrder = () => {
	return useRedux(mapHooksToState, {
		fetchOrder,
	});
};
