import { CustomRoute } from 'util/hook/useRouter';
import { historyPush } from 'models/routing';
import AccountRoute from './Account';
import OrderRoute from './Order';
import InquireRoute from './Inquire';

const routes: CustomRoute = {
	path: '/member',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ store, next }) => {
		const {
			auth: { isLogin },
			routing: { pathname },
		} = store.getState();

		if (!isLogin) {
			store.dispatch(
				historyPush({
					pathname: '/auth/login',
					search: `?redirect=${encodeURIComponent(pathname)}`,
				}),
			);
		}

		const children = await next();
		return children;
	},
	children: [AccountRoute, OrderRoute, InquireRoute],
};
export default routes;
