import { createAction, handleActions } from 'redux-actions';
import { Dispatch, AnyAction } from 'redux';
import { useRedux } from 'util/hook/redux';
import { wrapLangFetch } from 'util/api';
import { ProductHot } from 'models/common';
import { GetState, State as GlobalState } from '../reducers';
import { historyPush } from 'models/routing';

const clearShopDetail = createAction('CLEAR_SHOP_DETAIL');

const fetchShopDetail = createAction(
	'FETCH_SHOP_DETAIL',
	(id: string) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			routing: { queries },
		} = getState();

		if (queries.backstage_token) {
			const { status, message, data } = await wrapLangFetch(`shop/product/preview/${id}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${queries.backstage_token}`,
				},
			});

			if (status !== 200) {
				throw Error(message);
			}

			return data;
		}

		const { status, message, data } = await wrapLangFetch(`shop/product/${id}`, { method: 'GET' });

		if (status !== 200) {
			throw Error(message);
		}

		if (!data) dispatch(historyPush({ pathname: '/' }));

		return data.product;
	},
);

export const fetchShopProductHots = createAction(
	'FETCH_SHOP_PRODUCT_HOTS',
	(id: string) => async () => {
		const { status, message, data } = await wrapLangFetch(
			'shop/product/recommend',
			{
				method: 'GET',
			},
			{
				id,
			},
		);

		if (status !== 200) {
			throw new Error(message);
		}

		return data.data;
	},
);

export const initializeShopDetail = createAction(
	'INITIALIZE_SHOP_DETAIL',
	(id: string) => async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fetchShopDetail(id));
		dispatch(fetchShopProductHots(id));
	},
);

export interface ProductPicProperty {
	id: number;
	pic: string;
	pic_alt: string;
}

export interface State {
	meta: {
		title: string;
		description: string;
		json_ld: string;
	};
	category: {
		id: number;
		name: string;
	};
	product: {
		id: number;
		name: string;
		product_no: string;
		intro: string;
		price: number;
		inventory: number;
		texture: string;
		size: string;
		thickness: string;
		surface: string;
		capacity: string;
		description: string;
		pics: ProductPicProperty[];
		is_recommend: 'Y' | 'N';
		is_new: 'Y' | 'N';
	};
	productHots: ProductHot[];
}

const initialState: State = {
	meta: {
		title: '',
		description: '',
		json_ld: '',
	},
	category: {
		id: 0,
		name: '',
	},
	product: {
		id: 0,
		name: '',
		product_no: '',
		intro: '',
		price: 0,
		inventory: 0,
		texture: '',
		size: '',
		thickness: '',
		surface: '',
		capacity: '',
		description: '',
		pics: [],
		is_recommend: 'N',
		is_new: 'N',
	},
	productHots: [],
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	shopDetail: handleActions<State, any>(
		{
			CLEAR_SHOP_DETAIL: state => ({
				...state,
				...initialState,
			}),

			FETCH_SHOP_DETAIL_FULFILLED: (state, action) => ({
				...state,
				meta: {
					title: action.payload.product_category?.meta_title,
					description: action.payload.product_category?.meta_description,
					json_ld: action.payload.product_category?.json_ld,
				},
				category: {
					id: action.payload.product_category?.id,
					name: action.payload.product_category?.name,
				},
				product: {
					id: action.payload.id,
					name: action.payload.name,
					product_no: action.payload.product_no,
					intro: action.payload.intro,
					price: action.payload.price,
					inventory: action.payload.inventory,
					texture: action.payload.material,
					size: action.payload.size,
					thickness: action.payload.thickness,
					surface: action.payload.surface_treatment,
					capacity: action.payload.capacity,
					description: action.payload.content,
					pics: [
						...[{ id: 0, pic: action.payload.pic, pic_alt: action.payload.pic_alt }],
						...action.payload.other_pic,
					],
					is_recommend: action.payload.is_recommend,
					is_new: action.payload.is_new,
				},
			}),

			FETCH_SHOP_PRODUCT_HOTS_FULFILLED: (state, action) => ({
				...state,
				productHots: action.payload,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.shopDetail,
});

export const useShopDetail = () => useRedux(mapHooksToState, { clearShopDetail });
