import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRouting } from 'models/routing';
import { useMemberOrderDetail } from 'models/member/order/detail';
import { useMemberInquireDetail } from 'models/member/inquire/detail';
import { withLang } from 'util/i18n';
import { useOnclickOutside } from 'util/hook/useOnclickOutside';
import Link from 'components/atoms/Link';
import ArrowDown from 'images/icon/arrow-down.inline.svg';
import styles from './index.css';
import { useMemberAccount } from 'models/member/account';

interface SidebarMemberProperty {
	className?: string;
}

const SidebarMember: React.FC<SidebarMemberProperty> = ({ className }) => {
	const { t } = useTranslation('memberAccount');
	const [{ pathname }] = useRouting();
	const [{ id: orderDetailId }] = useMemberOrderDetail();
	const [{ id: inquireDetailId }] = useMemberInquireDetail();
	const [{ isForeigner }] = useMemberAccount();

	let links = isForeigner
		? [
				{
					url: '/member/account',
					name: t('account'),
				},
				{
					url: '/member/inquire',
					activeUrls: [`/member/inquire/${inquireDetailId}`],
					name: t('inquire'),
				},
		  ]
		: [
				{
					url: '/member/account',
					name: t('account'),
				},
				{
					url: '/member/order',
					activeUrls: [`/member/order/${orderDetailId}`],
					name: t('order'),
				},
				{
					url: '/member/inquire',
					activeUrls: [`/member/inquire/${inquireDetailId}`],
					name: t('inquire'),
				},
		  ];

	const [openOptionsMobile, setOpenOptionsMobile] = useState(false);

	const optionsRef = useRef(null);
	useOnclickOutside(optionsRef, () => setOpenOptionsMobile(false));

	const checkLinkIsActive = (url: string, activeUrls?: string[]) => {
		switch (true) {
			case withLang(url) === pathname:
				return true;
			case activeUrls?.some(activeUrl => withLang(activeUrl) === pathname):
				return true;
			default:
				return false;
		}
	};

	return (
		<>
			<div className={classnames(styles.sidebarMember, className)}>
				<div className={styles.links}>
					{links.map(({ url, name, activeUrls }, index) => (
						<Link
							to={withLang(url) === pathname ? '' : url}
							className={classnames(styles.link, {
								[styles.active]:
									checkLinkIsActive(url, activeUrls) ||
									(`/${withLang('cart')}` === pathname && index === 0),
							})}
							key={url}
						>
							{name}
						</Link>
					))}
				</div>
			</div>
			<div className={classnames(styles.sidebarMemberM, className)}>
				<div
					className={styles.active}
					onClick={() => setOpenOptionsMobile(!openOptionsMobile)}
					aria-hidden="true"
				>
					{links.map(({ url, name, activeUrls }, index) => {
						if (
							checkLinkIsActive(url, activeUrls) ||
							(`/${withLang('cart')}` === pathname && index === 0)
						) {
							return (
								<span className={styles.name} key={url}>
									{name}
								</span>
							);
						}
						return null;
					})}
					<span className={classnames(styles.icon, { [styles.open]: openOptionsMobile })}>
						<ArrowDown />
					</span>
					<div
						className={classnames(styles.options, { [styles.show]: openOptionsMobile })}
						ref={optionsRef}
					>
						{links.map(({ url, name }) => {
							if (withLang(url) === pathname) {
								return null;
							}
							return (
								<Link to={url} className={styles.option} key={url}>
									{name}
								</Link>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default SidebarMember;
