import React from 'react';
import classnames from 'classnames';
// import LoadingImg from 'images/common/loading.gif';
import styles from './index.css';

interface LoadingProperty {
	className?: string;
}

const Loading: React.FC<LoadingProperty> = ({ className }) => (
	<div className={classnames(styles.loading, className)}>
		{/* <img src={LoadingImg} alt="loading" className={styles.image} /> */}
	</div>
);

export default Loading;
