import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { State as GlobalState } from './reducers';

type NotificationType = 'success' | 'error';

interface OpenNotificationActionState {
	type: NotificationType;
	message: string;
}

export const openNotification = createAction<
	OpenNotificationActionState,
	OpenNotificationActionState
>('OPEN_NOTIFICATION', ({ type = 'success', message = '' }) => {
	return {
		type,
		message,
	};
});

export const closeNotification = createAction('CLOSE_NOTIFICATION', () => false);

export interface State {
	notificationActive: boolean;
	notificationType: NotificationType;
	notificationMessage: string;
}

const initialState: State = {
	notificationActive: false,
	notificationType: 'success',
	notificationMessage: '',
};

export const reducer = {
	notification: handleActions<State, any>(
		{
			OPEN_NOTIFICATION: (state, action) => ({
				...state,
				notificationActive: true,
				notificationType: action.payload.type,
				notificationMessage: action.payload.message,
			}),

			CLOSE_NOTIFICATION: (state, action) => ({
				...state,
				notificationActive: action.payload,
			}),
		},
		initialState,
	),
};

const mapHookToState = (state: GlobalState) => ({
	...state.notification,
});

export const useNotification = () =>
	useRedux(mapHookToState, {
		openNotification,
		closeNotification,
	});
