import { createAction, handleActions } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import storage from 'util/storage';
import { wrapLangFetch } from 'util/api';
import { openNotification } from 'models/notification';
import { t } from 'util/i18n';
import { GetState, State as GlobalState } from '../reducers';

interface IdListProperty {
	id: number;
	quantity: number;
}

interface ApiCartListProperty {
	id: number;
	pic: string;
	pic_alt: string;
	name: string;
	product_no: string;
	price: number;
	inventory: number;
}
// 購物車中『商品資料』型別
export interface CartListProperty extends ApiCartListProperty {
	quantity: number;
}

export const setCartItemList = createAction(
	'SET_CART_ITEM_LIST',
	(list: (IdListProperty | CartListProperty)[]) => {
		storage.setItem('cart-list', JSON.stringify(list));

		const totalQuantity = list.reduce((total, { quantity }) => total + quantity, 0);

		const amount = list.reduce((total, { price, quantity }) => {
			// eslint-disable-next-line no-mixed-operators
			return total + quantity * (price || 0);
		}, 0);

		return { list, totalQuantity, amount };
	},
);

export const addQuantityToList = createAction(
	'ADD_QUANTITY_TO_LIST',
	(apiList: ApiCartListProperty[]) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			cartList: { list },
		} = getState();

		let showNotification = !list.every(({ id }) => {
			return apiList.some(({ id: apiId }) => id === apiId);
		});

		const newItemList = apiList.map(item => {
			const matched = list.find(({ id }) => id === item.id);

			if (matched && item.inventory < matched.quantity) {
				showNotification = true;
				return { ...item, quantity: item.inventory };
			}

			return { ...item, quantity: matched?.quantity || 1 };
		});

		if (showNotification) {
			dispatch(openNotification({ type: 'error', message: t('cart:invalidProducts') }));
		}

		dispatch(setCartItemList(newItemList));

		return newItemList;
	},
);

const fetchCartList = createAction(
	'FETCH_CART_LIST',
	(list: (IdListProperty | CartListProperty)[]) => async (dispatch: Dispatch) => {
		const idList = list.map(({ id }) => id);
		const { status, message, data } = await wrapLangFetch('shop/product/cart/check', {
			method: 'POST',
			body: JSON.stringify({
				ids: idList,
			}),
		});

		if (status !== 200) {
			throw new Error(message);
		}

		dispatch(addQuantityToList(data));
	},
);

const setQuantity = createAction(
	'SET_QUANTITY',
	(quantity: number, id: number) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			cartList: { list },
		} = getState();

		const exited = list.some(({ id: itemId }) => itemId === id);

		const newItemList = list.map(item => {
			if (item.id === id) {
				return { ...item, quantity };
			}

			return item;
		});

		dispatch(setCartItemList(exited ? newItemList : [...list, { id, quantity }]));
	},
);

const incrementQuantity = createAction(
	'INCREMENT_QUANTITY',
	({
		id,
		quantity = 1,
		product_no,
	}: {
		id: number;
		quantity?: number;
		product_no?: string;
	}) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			cartList: { list },
		} = getState();

		const exited = list.some(({ id: itemId }) => itemId === id);

		const newItemList = list.map(item => {
			if (item.id === id) {
				return { ...item, quantity: item.quantity + quantity };
			}

			return item;
		});

		dispatch(setCartItemList(exited ? newItemList : [...list, { id, quantity, product_no }]));
	},
);

const decrementQuantity = createAction(
	'DECREMENT_QUANTITY',
	({ id, quantity = 1 }: { id: number; quantity?: number }) => async (
		dispatch: Dispatch,
		getState: GetState,
	) => {
		const {
			cartList: { list },
		} = getState();

		const newItemList = list.map(item => {
			if (item.id === id) {
				return { ...item, quantity: item.quantity - quantity };
			}

			return item;
		});

		dispatch(setCartItemList(newItemList));
	},
);

const deleteItem = createAction(
	'DELETE_ITEM',
	(id: number) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			cartList: { list },
		} = getState();

		const newItemList = list.filter(item => item.id !== id);

		dispatch(setCartItemList(newItemList));
	},
);

export const clearCart = createAction('CLEAR_CART', () => async (dispatch: Dispatch) => {
	dispatch(setCartItemList([]));
});
// 『初始化』購物車
export const initializeCart = createAction(
	'INITIALIZE_CART',
	() => async (dispatch: Dispatch<AnyAction>, getState: GetState) => {
		const {
			cartList: { list },
		} = getState();
		dispatch(fetchCartList(list));
	},
);
// 購物車狀態『型別』
export interface State {
	cartTotalQuantity: number;
	amount: number;
	list: CartListProperty[];
}
// 購物車狀態『初始值』
export const initialState: State = {
	cartTotalQuantity: 0,
	amount: 0,
	list: [],
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cartList: handleActions<State, any>(
		{
			SET_CART_ITEM_LIST: (state, action) => ({
				...state,
				list: action.payload.list,
				cartTotalQuantity: action.payload.totalQuantity,
				amount: action.payload.amount,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.cartList,
});

export const useCartList = () =>
	useRedux(mapHooksToState, {
		setQuantity,
		incrementQuantity,
		decrementQuantity,
		deleteItem,
	});
