import React, { useRef, KeyboardEvent, ChangeEvent, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useRouting } from 'models/routing';
import SearchIcon from 'images/common/search-input-search.inline.svg';
import CloseIcon from 'images/common/search-input-close.inline.svg';
import { animateScroll as scroll } from 'react-scroll';
import Icon from '../Icon';
import styles from './index.css';

interface SearchInputProperty {
	defaultValue?: string;
	type?: 'white' | 'green';
	className?: string;
}

const SearchInput: React.FC<SearchInputProperty> = ({
	defaultValue = '',
	type = 'white',
	className,
}) => {
	const [, { historyPush }] = useRouting();

	const [inputValue, setInputValue] = useState(defaultValue);
	const inputRef = useRef<HTMLInputElement>(null);

	const pushSearch = (e: KeyboardEvent | ChangeEvent<HTMLInputElement>) => {
		const { value } = (e as ChangeEvent).target as HTMLInputElement;
		if (!value) return;
		if ((e as KeyboardEvent).key === 'Enter') {
			historyPush({
				pathname: '/search',
				search: `?q=${value}`,
			});
			scroll.scrollToTop();
		}
	};
	const typeSearch = (e: KeyboardEvent | ChangeEvent<HTMLInputElement>) => {
		const { value } = (e as ChangeEvent).target as HTMLInputElement;
		if (!value) return;
		historyPush({
			pathname: '/search',
			search: `?q=${value}`,
		});
		scroll.scrollToTop();
	};

	const clearInput = () => {
		setInputValue('');
	};

	useEffect(() => {
		setInputValue(defaultValue);
	}, [defaultValue]);

	return (
		<div className={classnames(styles.searchInput, className, styles[type])}>
			<Icon src={SearchIcon} className={styles.search} />
			<input
				placeholder="search"
				type="text"
				onKeyPress={e => {
					pushSearch(e);
				}}
				onChange={e => {
					setInputValue(e.target.value);
				}}
				ref={inputRef}
				value={inputValue}
				size={1}
				enterKeyHint="search"
				className={styles.input}
			/>
			<Icon onClick={() => clearInput()} src={CloseIcon} className={styles.clear} />
		</div>
	);
};

export default SearchInput;
