import { combineReducers } from 'redux';
import * as routing from './routing';
import * as global from './global';
import * as common from './common';
import * as home from './home';
import * as news from './news/news';
import * as newsDetail from './news/newsDetail';
import * as article from './article/article';
import * as articleDetail from './article/articleDetail';
import * as memberAccount from './member/account';
import * as memberOrder from './member/order/list';
import * as memberOrderDetail from './member/order/detail';
import * as memberInquire from './member/inquire/list';
import * as memberInquireDetail from './member/inquire/detail';
import * as modal from './modal';
import * as auth from './auth/common';
import * as authLogin from './auth/login';
import * as authReset from './auth/reset';
import * as authForget from './auth/forget';
import * as authSignup from './auth/signup';
import * as authVerify from './auth/verify';
import * as cartList from './cart/list';
import * as cartCheckout from './cart/checkout';
import * as cartResult from './cart/result';
import * as inquire from './inquire';
import * as i18n from './i18n';
import * as notification from './notification';
import * as header from './header';
import * as faq from './faq';
import * as contact from './contact';
import * as product from './product/product';
import * as productContent from './product/productContent';
import * as shop from './shop/shop';
import * as shopDetail from './shop/shopDetail';
import * as compare from './compare';
import * as download from './download';
import * as search from './search';
import * as order from './order';

// For Global State interface
export interface State {
	global: global.State;
	common: common.State;
	home: home.State;
	news: news.State;
	newsDetail: newsDetail.State;
	article: article.State;
	articleDetail: articleDetail.State;
	memberAccount: memberAccount.State;
	memberOrder: memberOrder.State;
	memberOrderDetail: memberOrderDetail.State;
	memberInquire: memberInquire.State;
	memberInquireDetail: memberInquireDetail.State;
	routing: routing.State;
	modal: modal.State;
	auth: auth.State;
	authLogin: authLogin.State;
	authForget: authForget.State;
	authReset: authReset.State;
	authSignup: authSignup.State;
	authVerify: authVerify.State;
	cartList: cartList.State;
	cartCheckout: cartCheckout.State;
	cartResult: cartResult.State;
	inquire: inquire.State;
	i18n: i18n.State;
	notification: notification.State;
	header: header.State;
	faq: faq.State;
	contact: contact.State;
	product: product.State;
	productContent: productContent.State;
	shop: shop.State;
	shopDetail: shopDetail.State;
	compare: compare.State;
	download: download.State;
	search: search.State;
	order: order.State;
}

export type GetState = () => State;

const reducers = combineReducers<State>({
	...routing.reducer,
	...global.reducer,
	...common.reducer,
	...home.reducer,
	...news.reducer,
	...newsDetail.reducer,
	...article.reducer,
	...articleDetail.reducer,
	...memberAccount.reducer,
	...memberOrder.reducer,
	...memberOrderDetail.reducer,
	...memberInquire.reducer,
	...memberInquireDetail.reducer,
	...modal.reducer,
	...auth.reducer,
	...authLogin.reducer,
	...authForget.reducer,
	...authReset.reducer,
	...authSignup.reducer,
	...authVerify.reducer,
	...cartList.reducer,
	...cartCheckout.reducer,
	...cartResult.reducer,
	...inquire.reducer,
	...i18n.reducer,
	...notification.reducer,
	...header.reducer,
	...faq.reducer,
	...contact.reducer,
	...product.reducer,
	...productContent.reducer,
	...shop.reducer,
	...shopDetail.reducer,
	...compare.reducer,
	...download.reducer,
	...search.reducer,
	...order.reducer,
});

export default reducers;
