import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';
import { initializeShop } from 'models/shop/shop';
import DetailRoute from './ShopDetail';

const routes: CustomRoute = {
	path: '/shop',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'shop' */ './component')],
			render: ([Shop]) => <Shop />,
			onEnter: async ({ store, next }) => {
				console.log('on Enter Shop');
				const children = await next();
				store.dispatch(initializeShop());
				store.dispatch(storeActivePage('shop'));
				console.log('on Enter Shop / end');
				return children;
			},
		},
		DetailRoute,
	],
};

export default routes;
