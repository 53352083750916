import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeShopDetail } from 'models/shop/shopDetail';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [import(/* webpackChunkName: 'shopDetail' */ './component')],
	render: ([ShopDetail]) => <ShopDetail />,
	onEnter: async ({ store, params: { id }, next }) => {
		console.log('on Enter Shop Detail');
		const children = await next();
		store.dispatch(initializeShopDetail((id as string) || ''));
		store.dispatch(storeActivePage(`shop/${id}`));
		console.log('on Enter Shop Detail / end');
		return children;
	},
};

export default routes;
