import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';
import { initializeCartResult } from 'models/cart/result';

const routes: CustomRoute = {
	path: '/result/:orderNo',
	components: () => [import(/* webpackChunkName: 'cartResult' */ './component')],
	render: ([CartResult]) => <CartResult />,
	onEnter: async ({ next, store, params: { orderNo } }) => {
		console.log('on Enter Cart Result');
		const children = await next();
		store.dispatch(initializeCartResult(orderNo as string));
		store.dispatch(storeActivePage('cart/result'));
		console.log('on Enter Cart Result / end');
		return children;
	},
};
export default routes;
