import i18n from 'i18next';
import qs from 'qs';

import { getLangfromURL } from 'util/helper';
import storage from './storage';
import { lanList } from './i18nConfig';

const { API_ENDPOINT } = process.env;

const getToken = () => {
	const tokenData = storage.getItem('token');

	return tokenData === null ? '' : JSON.parse(tokenData);
};

export const generateUrl = (url: string, params?: object) => {
	const paramsString = qs.stringify(params, { arrayFormat: 'brackets' });

	const URL =
		paramsString !== '' ? `${API_ENDPOINT}/${url}?${paramsString}` : `${API_ENDPOINT}/${url}`;

	return URL;
};

export const generateExternalUrl = (url: string, params?: object) => {
	const paramsString = qs.stringify(params, { arrayFormat: 'brackets' });

	const URL = paramsString !== '' ? `${url}?${paramsString}` : `${API_ENDPOINT}/${url}`;

	return URL;
};

interface ApiOptionProperty {
	headers?: {};
	method?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	body?: any;
}

export const wrapFetch = async (
	url: string,
	options: ApiOptionProperty = { headers: {} },
	params = {},
	withLan = true,
) => {
	const localStoragelng = storage.getItem('i18nextLng');
	const URL = generateUrl(url, {
		...(withLan && { lang: i18n.language || localStoragelng || lanList[0] }),
		...params,
	});

	const headers = new Headers({
		'Content-Type': 'application/json',
		Accept: 'application/json',
		...options.headers,
	});
	const result = await fetch(URL, { ...options, headers });

	return result.json();
};

export const wrapLangFetch = async (
	url: string,
	options: ApiOptionProperty = { headers: {} },
	params = {},
) => {
	return wrapFetch(
		url,
		{
			...options,
		},
		{ lang: getLangfromURL(), ...params },
	);
};

export const wrapAuthFetch = async (
	url: string,
	options: ApiOptionProperty = { headers: {} },
	params = {},
	withLan = true,
) => {
	const token = getToken();

	return wrapFetch(
		url,
		{
			...options,
			headers: {
				...(token && {
					Authorization: `Bearer ${token}`,
				}),
				...options.headers,
			},
		},
		params,
		withLan,
	);
};

export const wrapFetchFormData = async (
	url: string,
	options: ApiOptionProperty = { headers: {} },
	params = {},
	withLan = true,
) => {
	const localStoragelng = storage.getItem('i18nextLng');
	const URL = generateUrl(url, {
		...(withLan && { lang: i18n.language || localStoragelng || lanList[0] }),
		...params,
	});

	const result = await fetch(URL, options);

	return result.json();
};

export const wrapAuthFetchFormData = async (
	url: string,
	options: ApiOptionProperty = { headers: {} },
	params = {},
) => {
	const token = getToken();

	return wrapFetchFormData(
		url,
		{
			...options,
			headers: {
				...(token && {
					Authorization: `Bearer ${token}`,
				}),
				...options.headers,
			},
		},
		params,
	);
};
