import React from 'react';
import classnames from 'classnames';
import LogoIcon from 'images/common/logo.inline.svg';
import Link from '../Link';
import Icon from '../Icon';
import styles from './index.css';

interface LogoProperty {
	type?: 'white' | 'black';
	className?: string;
}

const Logo: React.FC<LogoProperty> = ({ type = 'white', className }) => (
	<Link className={classnames(styles.logo, className, styles[type])} to="/">
		<Icon src={LogoIcon} />
	</Link>
);

export default Logo;
