import { loadAuthToken } from 'models/auth/common';
import { completedCustomizeGuide } from 'models/global';
import { Store } from 'redux';

import storage from './storage';
import { lanList } from './i18nConfig';
import history from '../store/history';

export function isElementNotNull<T extends HTMLElement>(element: HTMLElement | null): element is T {
	return element !== null;
}

export function getParanetNode<T extends HTMLElement>(node: HTMLElement): T {
	return node.parentNode as T;
}

export const focusInChildren: (
	relatedTarget: HTMLElement,
	currentTarget: HTMLElement,
) => {} | boolean = (relatedTarget: HTMLElement, currentTarget: HTMLElement) => {
	if (relatedTarget === null) {
		return false;
	}

	if (relatedTarget === currentTarget) {
		return true;
	}

	const targetParent = getParanetNode(relatedTarget);

	return focusInChildren(targetParent, currentTarget);
};

export function clamp(num: number, min: number, max: number) {
	return Math.min(Math.max(num, min), max);
}

export const range = (start: number, length: number) =>
	Array.from(new Array(length), (_, i) => start + i);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isExist = (value: any) =>
	value !== null && value !== '' && typeof value !== 'undefined';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (value: any) => !isExist(value);

export const getLangfromURL = () => {
	let lang = window.location.pathname.split('/')[1];

	if (!lanList.includes(lang)) {
		lang = storage.getItem('i18nextLng') || lanList[0];
	}

	return lang;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mobileValid = (value: any) => {
	const re = getLangfromURL() === 'zh' ? /^([0-9]{4})-?([0-9]{3})-?([0-9]{3})$/ : /^([0-9#\-+])+$/;
	return re.test(value);
};

export const telValid = (value: string) => {
	const re =
		getLangfromURL() === 'zh'
			? /^(0[2-8][0-9]{0,2}?)-?(\d{3,4}-?\d{4})(#[0-9]{1,})?$/
			: /^([0-9#\-+])+$/;
	return re.test(value);
};

export const isNumber = (value: string) => {
	const re = /^[0-9]*$/;
	return re.test(value);
};

export function emailValid(email: string) {
	// eslint-disable-next-line no-useless-escape
	const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
	return re.test(String(email).toLowerCase());
}

export const haveFullwidth = (str: string) => {
	const re = /[\uFF10-\uFF19\uFF21-\uFF3A\uFF41-\uFF5A]/g;
	return re.test(str);
};

export function passwordValid(password: string) {
	// 包含一數字及一小寫英文字母，長度 8 ~ 16
	const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/;
	return re.test(password);
}

export const loadConfigFromLocalStorage = (store: Store) => {
	const tokenData = storage.getItem('token');
	const token = tokenData === 'null' || tokenData === 'undefined' ? '' : JSON.parse(tokenData);

	const customizeGuideData = storage.getItem('customize-guide');
	const customizeGuide = customizeGuideData === null ? false : JSON.parse(customizeGuideData);

	store.dispatch(loadAuthToken(token));

	if (customizeGuide) {
		store.dispatch(completedCustomizeGuide());
	}
};

export const mobileAndTabletCheck = () => {
	let check = false;
	(a => {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
				a,
			) ||
			// eslint-disable-next-line no-useless-escape
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4),
			)
		)
			check = true;
	})(navigator.userAgent || navigator.vendor || window.opera);
	return check;
};

export const numberWithCommas = (x: number) => `${x}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const strLen = (str: string) => {
	let count = 0;
	for (let i = 0, len = str.length; i < len; i += 1) {
		count += str.charCodeAt(i) < 256 ? 1 : 2;
	}
	return count;
};

export const round = (value: number | string, notation: number) => {
	const numValue = typeof value === 'string' ? parseFloat(value) : value;

	// eslint-disable-next-line no-mixed-operators
	return Math.round(numValue * 10 ** notation) / 10 ** notation;
};

// (https://stackoverflow.com/a/58519810)
export const groupArray = (data: any[], num: number) => {
	let newData = [];
	newData = data
		? data.reduce((r, e, i) => (i % num ? r[r.length - 1].push(e) : r.push([e])) && r, [])
		: [];
	return newData;
};

export const getSearchParams = (name: string) => {
	const params = new URLSearchParams(history.location.search);
	return params.get(name) || '';
};

export const setSearchParams = (name: string, value: string | number | any[]) => {
	const searchParams = new URLSearchParams(history.location.search);
	searchParams.set(name, `${value}`);
	history.push(`${window.location.pathname}?${searchParams}`, '_self');
};

export const deleteSearchParams = (name: string) => {
	const searchParams = new URLSearchParams(history.location.search);
	searchParams.delete(name);
	history.push(`${window.location.pathname}?${searchParams}`, '_self');
};

export const testRegex = (str: string, reg: any) => {
	const regex = new RegExp(reg);
	return regex.test(str);
};

export const splitToChunks = (array: any[], parts: number) => {
	const copyArray = [...array];
	const result = [];
	// eslint-disable-next-line no-plusplus
	for (let i = parts; i > 0; i--) {
		result.push(copyArray.splice(0, Math.ceil(copyArray.length / i)));
	}
	return result;
};

export const scrollToRef = (ref: null | React.RefObject<HTMLElement> = null, offset = 0) => {
	if (ref && ref.current) {
		window.scrollTo({ left: 0, top: ref.current.offsetTop + offset, behavior: 'smooth' });
	}
};

export const checkSubNameIsTypeAll = (subName: string) => {
	const NAME_OF_TYPE_ALL = ['所有', 'All'];
	return NAME_OF_TYPE_ALL.some(all => subName.indexOf(all) > -1);
};

export const markKeyword = ({
	content = '',
	keyword = '',
	className = '',
}: {
	content: string;
	keyword: string;
	className: string;
}) => {
	switch (true) {
		case !keyword:
			return content;
		case new RegExp(keyword, 'g').test(content):
			return content.replace(
				new RegExp(keyword, 'g'),
				`<span class="${className}">${keyword}</span>`,
			);
		case new RegExp(keyword, 'i').test(content) && keyword === keyword.toLowerCase():
			return content.replace(
				new RegExp(keyword, 'i'),
				`<span class="${className}">${keyword.toUpperCase()}</span>`,
			);
		case new RegExp(keyword, 'i').test(content) && keyword === keyword.toUpperCase():
			return content.replace(
				new RegExp(keyword, 'i'),
				`<span class="${className}">${keyword.toLowerCase()}</span>`,
			);
		default:
			return content;
	}
};
