import { createAction, handleActions } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';

import { wrapAuthFetch } from 'util/api';
import { GetState, State as GlobalState } from '../../reducers';

export interface InquireListType {
	id: number;
	quotation_no: string;
	created_at: string;
}

export const fetchMemberInquireLists = createAction(
	'FETCH_MEMBER_INQUIRE_LISTS',
	() => async (_: Dispatch, getState: GetState) => {
		const {
			memberInquire: { perPage },
			routing: { queries },
		} = getState();

		const { status, message, data } = await wrapAuthFetch(
			'member/quotation',
			{ method: 'GET' },
			{
				page: +queries.page || 1,
				per_page: perPage,
			},
		);

		if (status !== 200) {
			throw Error(message);
		}

		return data;
	},
);

export const initializeMemberInquire = createAction(
	'INITIALIZE_MEMBER_INQUIRE',
	() => async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fetchMemberInquireLists());
	},
);

export const clearMemberInquire = createAction('CLEAR_MEMBER_INQUIRE');

export interface State {
	inquireLists: InquireListType[];
	perPage: number;
	pagination: {
		current_page: number;
		last_page: number;
		total: number;
	};
}

export const initialState: State = {
	inquireLists: [],
	perPage: 10,
	pagination: {
		current_page: 1,
		last_page: 0,
		total: 0,
	},
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	memberInquire: handleActions<State, any>(
		{
			FETCH_MEMBER_INQUIRE_LISTS_FULFILLED: (state, action) => ({
				...state,
				inquireLists: action.payload.data,
				pagination: action.payload.meta,
			}),

			CLEAR_MEMBER_INQUIRE: state => ({
				...state,
				...initialState,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.memberInquire,
});

export const useMemberInquire = () =>
	useRedux(mapHooksToState, {
		fetchMemberInquireLists,
	});
