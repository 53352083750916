import { createAction, handleActions } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { wrapLangFetch } from 'util/api';
import { ProductHot } from 'models/common';
import { GetState, State as GlobalState } from '../reducers';
import { ArticleTag } from './article';

const clearArticleDetail = createAction('CLEAR_ARTICLE_DETAIL');

const fetchArticleDetail = createAction(
	'FETCH_ARTICLE_DETAIL',
	(id: number) => async (_: Dispatch, getState: GetState) => {
		const {
			routing: { queries },
		} = getState();

		if (queries.backstage_token) {
			const { status, message, data } = await wrapLangFetch(
				`post/preview/${id}`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${queries.backstage_token}`,
					},
				},
				{
					post_category_id: queries.type || '',
					post_tags: queries.tags || '',
				},
			);

			if (status !== 200) {
				throw new Error(message);
			}

			return data;
		}
		const { status, message, data } = await wrapLangFetch(
			`post/${id}`,
			{ method: 'GET' },
			{
				post_category_id: queries.type || '',
				post_tags: queries.tags || '',
			},
		);

		if (status !== 200) {
			throw Error(message);
		}

		return data;
	},
);

const fetchArticleProductHots = createAction(
	'FETCH_ARTICLE_PRODUCT_HOTS',
	(id: number) => async () => {
		const { status, message, data } = await wrapLangFetch(
			'product/recommend',
			{
				method: 'GET',
			},
			{
				post_id: id,
			},
		);

		if (status !== 200) {
			throw Error(message);
		}

		return data.data;
	},
);

export const initializeArticleDetail = createAction(
	'INITIALIZE_ARTICLE',
	(id: number) => async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fetchArticleDetail(id));
		dispatch(fetchArticleProductHots(id));
	},
);

export interface State {
	meta: {
		title: string;
		description: string;
		json_ld: string;
	};
	type: {
		id: number;
		name: string;
	};
	article: {
		id: number;
		published_at: string;
		title: string;
		pic: string;
		content: string;
		tags: ArticleTag[];
	};
	pagination: {
		previous: {
			id: number;
			title: string;
		};
		next: {
			id: number;
			title: string;
		};
	};
	productHots: ProductHot[];
}

const initialState: State = {
	meta: {
		title: '',
		description: '',
		json_ld: '',
	},
	type: {
		id: 0,
		name: '',
	},
	article: {
		id: 0,
		published_at: '',
		title: '',
		pic: '',
		content: '',
		tags: [],
	},
	pagination: {
		previous: {
			id: 0,
			title: '',
		},
		next: {
			id: 0,
			title: '',
		},
	},
	productHots: [],
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	articleDetail: handleActions<State, any>(
		{
			CLEAR_ARTICLE_DETAIL: state => ({
				...state,
				...initialState,
			}),

			FETCH_ARTICLE_DETAIL_FULFILLED: (state, action) => ({
				...state,
				meta: {
					title: action.payload.post?.meta_title,
					description: action.payload.post?.meta_description,
					json_ld: action.payload.post?.json_ld,
				},
				type: {
					id: action.payload.post?.post_category?.id,
					name: action.payload.post?.post_category?.name,
				},
				article: {
					id: action.payload.post?.id,
					published_at: action.payload.post?.published_at,
					title: action.payload.post?.title,
					pic: action.payload.post?.pic,
					content: action.payload.post?.content,
					tags: action.payload.post?.post_tags,
				},
				pagination: {
					previous: {
						id: action.payload.meta?.previous?.id,
						title: action.payload.meta?.previous?.title,
					},
					next: {
						id: action.payload.meta?.next?.id,
						title: action.payload.meta?.next?.title,
					},
				},
			}),

			FETCH_ARTICLE_PRODUCT_HOTS_FULFILLED: (state, action) => ({
				...state,
				productHots: action.payload,
			}),
		},
		initialState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

const mapHooksToState = (state: GlobalState) => ({
	...state.articleDetail,
});

export const useArticleDetail = () => useRedux(mapHooksToState, { clearArticleDetail });
