import { createAction, handleActions } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { wrapLangFetch } from 'util/api';
import { ProductHot } from 'models/common';
import { GetState, State as GlobalState } from '../reducers';

const clearNewsDetail = createAction('CLEAR_NEWS_DETAIL');

export const fetchNewsDetail = createAction(
	'FETCH_NEWS_DETAIL',
	(id: number) => async (_: Dispatch, getState: GetState) => {
		const {
			routing: { queries },
		} = getState();

		if (queries.backstage_token) {
			const { status, message, data } = await wrapLangFetch(
				`news/preview/${id}`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${queries.backstage_token}`,
					},
				},
				{
					news_category_id: queries.type || '',
					sort: `${queries.sort || 'order'}|${queries.order || 'desc'}`,
				},
			);

			if (status !== 200) {
				throw Error(message);
			}

			return data;
		}

		const { status, message, data } = await wrapLangFetch(
			`news/${id}`,
			{ method: 'GET' },
			{
				news_category_id: queries.type || '',
				sort: `${queries.sort || 'order'}|${queries.order || 'desc'}`,
			},
		);

		if (status !== 200) {
			throw Error(message);
		}

		return data;
	},
);

export const fetchNewsProductHots = createAction(
	'FETCH_NEWS_PRODUCT_HOTS',
	(id: number) => async () => {
		const { status, message, data } = await wrapLangFetch(
			'product/recommend',
			{
				method: 'GET',
			},
			{
				news_id: id,
			},
		);

		if (status !== 200) {
			throw Error(message);
		}

		return data.data;
	},
);

export const initializeNewsDetail = createAction(
	'INITIALIZE_NEWS',
	(id: number) => async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fetchNewsDetail(id));
		dispatch(fetchNewsProductHots(id));
	},
);

export interface State {
	meta: {
		title: string;
		description: string;
		json_ld: string;
	};
	type: {
		id: number;
		name: string;
	};
	news: {
		id: number;
		published_at: string;
		title: string;
		pic: string;
		content: string;
	};
	pagination: {
		previous: {
			id: number;
			title: string;
		};
		next: {
			id: number;
			title: string;
		};
	};
	productHots: ProductHot[];
}

const initialState: State = {
	meta: {
		title: '',
		description: '',
		json_ld: '',
	},
	type: {
		id: 0,
		name: '',
	},
	news: {
		id: 0,
		published_at: '',
		title: '',
		pic: '',
		content: '',
	},
	pagination: {
		previous: {
			id: 0,
			title: '',
		},
		next: {
			id: 0,
			title: '',
		},
	},
	productHots: [],
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	newsDetail: handleActions<State, any>(
		{
			CLEAR_NEWS_DETAIL: state => ({
				...state,
				...initialState,
			}),

			FETCH_NEWS_DETAIL_FULFILLED: (state, action) => ({
				...state,
				meta: {
					title: action.payload.news?.meta_title,
					description: action.payload.news?.meta_description,
					json_ld: action.payload.news?.json_ld,
				},
				type: {
					id: action.payload.news?.news_category?.id,
					name: action.payload.news?.news_category?.name,
				},
				news: {
					id: action.payload.news?.id,
					published_at: action.payload.news?.published_at,
					title: action.payload.news?.title,
					pic: action.payload.news?.pic,
					content: action.payload.news?.content,
				},
				pagination: {
					previous: {
						id: action.payload.meta?.previous?.id,
						title: action.payload.meta?.previous?.title,
					},
					next: {
						id: action.payload.meta?.next?.id,
						title: action.payload.meta?.next?.title,
					},
				},
			}),

			FETCH_NEWS_PRODUCT_HOTS_FULFILLED: (state, action) => ({
				...state,
				productHots: action.payload,
			}),
		},
		initialState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

const mapHooksToState = (state: GlobalState) => ({
	...state.newsDetail,
});

export const useNewsDetail = () => useRedux(mapHooksToState, { clearNewsDetail });
