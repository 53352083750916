import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';

const routes: CustomRoute = {
	path: '/category',
	components: () => [import(/* webpackChunkName: 'category' */ './component')],
	render: ([Category]) => <Category />,
	onEnter: async ({ store, next }) => {
		console.log('on Enter Category');
		const children = await next();
		store.dispatch(storeActivePage('category'));
		console.log('on Enter Category / end');
		return children;
	},
};

export default routes;
