import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { wrapAuthFetch } from 'util/api';
import { GetState, State as GlobalState } from './reducers';

export interface ProductType {
	id: number;
	pic: string;
	pic_alt: string;
	product_category: {
		id: number;
		name: string;
	};
	product_no: string;
	name: string;
	intro: string;
}

export interface ArticleType {
	id: number;
	pic: string;
	post_category: {
		id: number;
		name: string;
	};
	title: string;
	list_info: string;
}

export const setSearchPerPage = createAction('SET_SEARCH_PER_PAGE', (perPage: number) => perPage);

export const fetchSearch = createAction(
	'FETCH_SEARCH',
	() => async (_: Dispatch, getState: GetState) => {
		const {
			search: { searchPerPage },
			routing: { queries },
		} = getState();

		const { status, message, data } = await wrapAuthFetch(
			'siteSearch',
			{ method: 'GET' },
			{
				keyword: queries.q || '',
				sort: `${queries.sort || 'order'}|${queries.order || 'desc'}`,
				page: +queries.page || 1,
				per_page: searchPerPage,
			},
		);

		if (status !== 200) {
			throw Error(message);
		}

		return data;
	},
);

interface PaginationProperty {
	current_page: number;
	last_page: number;
	total: number;
}
export interface State {
	meta: {
		title: string;
		description: string;
		json_ld: string;
	};
	searchPerPage: number;
	products: ProductType[];
	articles: ArticleType[];
	paginations: {
		[tab: string]: PaginationProperty;
		product: PaginationProperty;
		article: PaginationProperty;
	};
}

const initialState: State = {
	meta: {
		title: '',
		description: '',
		json_ld: '',
	},
	searchPerPage: window.innerWidth >= 1280 ? 10 : 7,
	products: [],
	articles: [],
	paginations: {
		product: {
			current_page: 1,
			last_page: 0,
			total: 0,
		},
		article: {
			current_page: 1,
			last_page: 0,
			total: 0,
		},
	},
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	search: handleActions<State, any>(
		{
			SET_SEARCH_PER_PAGE: (state, action) => ({
				...state,
				searchPerPage: action.payload,
			}),
			FETCH_SEARCH_FULFILLED: (state, action) => ({
				...state,
				meta: {
					title: action.payload.meta?.meta_title,
					description: action.payload.meta?.meta_description,
					json_ld: action.payload.meta?.json_ld,
				},
				products: action.payload.products?.data,
				articles: action.payload.posts?.data,
				paginations: {
					product: action.payload.products?.meta,
					article: action.payload.posts.meta,
				},
			}),
		},
		initialState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

const mapHooksToState = (state: GlobalState) => ({
	...state.search,
});

export const useSearch = () =>
	useRedux(mapHooksToState, {
		setSearchPerPage,
		fetchSearch,
	});
