import { map, filter } from 'rxjs/operators';
import { ActionsObservable } from 'redux-observable';

import storage from 'util/storage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleError = (action$: ActionsObservable<any>) =>
	action$.pipe(
		filter(action => action.type.includes('REJECTED')),
		map(action => {
			if (action.payload.message === 'token_error') {
				storage.removeItem('token');
				return { type: 'TOKEN_ERROR' };
			}

			return { type: 'NOT_HANDLE_ERROR' };
		}),
	);
