import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeProductContent } from 'models/product/productContent';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [import(/* webpackChunkName: 'productContent' */ './component')],
	render: ([ProductContent]) => <ProductContent />,
	onEnter: async ({ store, params: { id }, next }) => {
		console.log('on Enter Product Content');
		const children = await next();
		store.dispatch(storeActivePage(`product/${id}`));
		store.dispatch(initializeProductContent((id as string) || ''));
		console.log('on Enter Product Content / end');
		return children;
	},
};

export default routes;
