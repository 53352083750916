import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';
import { t } from 'util/i18n';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { openNotification } from 'models/notification';
import { historyPush } from 'models/routing';
import { setAllMemberInputFromAPI } from 'models/member/account';
import { GetState, State as GlobalState } from '../reducers';
import { openModal } from '../modal';
import { updateAccessToken } from './common';

const setVerifyEmail = createAction('SET_VERIFY_EMAIL', (email: string) => email);

export const VerifyAccount = createAction(
	'VERIFY_ACCOUNT',
	(code: string) => async (dispatch: Dispatch, getState: GetState) => {
		if (!code) {
			dispatch(openModal({ category: 'alert', type: 'verifyFail' }));
			return '';
		}

		const {
			i18n: { lan },
		} = getState();

		const { status, message, data } = await wrapFetch('member/verify', {
			method: 'POST',
			body: JSON.stringify({
				code,
			}),
		});

		switch (true) {
			case status === 401 && !data?.email: // Email not found or code invalid
				dispatch(
					openNotification({
						type: 'error',
						message:
							lan === 'zh'
								? '該帳號不存在，如有相關疑問請聯繫客服人員請求協助'
								: 'Opps! There is an error. Please try again later or contact our customer service for help.',
					}),
				);
				dispatch(historyPush({ pathname: '/auth/login' }));
				throw new Error(t('auth:accountNotExist'));
			case status === 401: // Expired code
				dispatch(setVerifyEmail(data?.email));
				dispatch(openModal({ category: 'alert', type: 'verifyFail' }));
				throw new Error(message);
			case status !== 200:
				dispatch(openModal({ category: 'alert', type: 'verifyFail' }));
				throw new Error(message);
			default:
				dispatch(openModal({ category: 'alert', type: 'signupSuccess' }));
				dispatch(updateAccessToken(data?.token));
				dispatch(setAllMemberInputFromAPI(data?.member));
				return code;
		}
	},
);

const Resend = createAction('RESEND', () => async (dispatch: Dispatch, getState: GetState) => {
	const {
		authSignup: { email: signupEmail },
		authLogin: { email: loginEmail },
	} = getState();

	const { status, message } = await wrapFetch('member/resendVerifyMail', {
		method: 'POST',
		body: JSON.stringify({
			email: signupEmail.value || loginEmail.value,
		}),
	});

	if (status !== 200) {
		dispatch(
			openNotification({
				type: 'error',
				message,
			}),
		);
		throw new Error(message);
	}

	dispatch(
		openNotification({
			type: 'success',
			message: t('auth:emailSent.resent'),
		}),
	);
});

const VerifyResend = createAction(
	'VERIFY_RESEND',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			authVerify: { verifyEmail },
		} = getState();

		const { status, message } = await wrapFetch('member/resendVerifyMail', {
			method: 'POST',
			body: JSON.stringify({
				email: verifyEmail,
			}),
		});

		if (status !== 200) {
			dispatch(
				openNotification({
					type: 'error',
					message,
				}),
			);
			throw new Error(message);
		}

		dispatch(
			openNotification({
				type: 'success',
				message: t('auth:emailSent.resent'),
			}),
		);
	},
);

export interface State {
	verifyEmail: string;
	verifyCode: string;
	loading: boolean;
	error: boolean;
	message: string;
}

const initialState: State = {
	verifyEmail: '',
	verifyCode: '',
	loading: false,
	error: false,
	message: '',
};

export const reducer = {
	authVerify: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_VERIFY_EMAIL: (state, action) => ({
				...state,
				verifyEmail: action.payload,
			}),

			RESEND_PENDING: state => ({
				...state,
				loading: true,
			}),

			RESEND_FULFILLED: state => ({
				...state,
				loading: false,
				success: true,
			}),

			RESEND_REJECTED: (state, action) => ({
				...state,
				error: true,
				success: false,
				loading: false,
				message: action.payload.message,
			}),

			VERIFY_RESEND_PENDING: state => ({
				...state,
				loading: true,
			}),

			VERIFY_RESEND_FULFILLED: state => ({
				...state,
				loading: false,
			}),

			VERIFY_RESEND_REJECTED: (state, action) => ({
				...state,
				error: true,
				loading: false,
				message: action.payload.message,
			}),

			VERIFY_ACCOUNT_FULFILLED: (state, action) => ({
				...state,
				verifyCode: action.payload,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.authVerify,
});

export const useAuthVerify = () => useRedux(mapHooksToState, { Resend, VerifyResend });
