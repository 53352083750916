import { useEffect, RefObject } from 'react';

export const useOnclickOutside = (
	ref: null | RefObject<HTMLElement>,
	handler: (e: MouseEvent | TouchEvent) => void,
) => {
	useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent) => {
			if (!ref || !ref.current || ref.current.contains(event.target as HTMLElement)) {
				return;
			}
			handler(event);
		};
		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);
		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, handler]);
};
