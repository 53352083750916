import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';

const routes: CustomRoute = {
	path: '/search',
	components: () => [import(/* webpackChunkName: 'search' */ './component')],
	render: ([Search]) => <Search />,
	onEnter: async ({ store, next }) => {
		console.log('on Enter Search');
		const children = await next();
		store.dispatch(storeActivePage('search'));
		console.log('on Enter Search / end');
		return children;
	},
};

export default routes;
