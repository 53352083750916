import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';
import { initializeNewsDetail } from 'models/news/newsDetail';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [import(/* webpackChunkName: 'newsDetail' */ './component')],
	render: ([NewsDetail]) => <NewsDetail />,
	onEnter: async ({ store, params: { id }, next }) => {
		console.log('on Enter News Detail');
		const children = await next();
		store.dispatch(storeActivePage(`news_detail_${id}`));
		store.dispatch(initializeNewsDetail(+id));
		console.log('on Enter News Detail / end');
		return children;
	},
};

export default routes;
