import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { animateScroll } from 'react-scroll';
import { useSpring, animated } from 'react-spring';
import { useMedia, useScroll, useResize } from 'util/event';
import ScrollToTopBtn from 'images/icon/btn-scroll-to-top.inline.svg';
import styles from './index.css';

interface FixedButtonsProperty {
	className?: string;
}

const FixedButtons: React.FC<FixedButtonsProperty> = ({ className }) => {
	const media = useMedia();

	// position controls
	const { scrollY } = useScroll();
	const { width: winWidth, height: winHeight } = useResize();

	const [show, setShow] = useState(false);
	const [position, setPosition] = useState('fixed');

	const wrapperStyles = useSpring({
		opacity: show ? 1 : 0,
		position: position as 'fixed' | 'absolute',
	});

	useEffect(() => {
		let canShow = false;
		if (scrollY > 20) {
			canShow = true;
		}

		if (show !== canShow) {
			setShow(canShow);
		}
	}, [scrollY]);

	useEffect(() => {
		const pageHeight = document.body.clientHeight;

		if (pageHeight) {
			let newPosition = 'fixed';
			if (media === 'desktop' && scrollY > pageHeight - winHeight - 260) {
				newPosition = 'absolute';
			}

			if (media === 'mobile' && scrollY > pageHeight - winHeight - 800) {
				newPosition = 'absolute';
			}

			if (position !== newPosition) {
				setPosition(newPosition);
			}
		}
	}, [media, scrollY, winWidth, winHeight]);

	return (
		<>
			<animated.div
				className={classnames(styles.fixedButtons, className)}
				style={{ ...wrapperStyles }}
			>
				<div
					className={classnames(styles.action, styles.circleBtn)}
					role="button"
					tabIndex={0}
					onClick={() => {
						animateScroll.scrollToTop();
					}}
					onKeyPress={() => {}}
				>
					<ScrollToTopBtn />
				</div>
			</animated.div>
		</>
	);
};

export default FixedButtons;
