import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';
import { initializeDownload } from 'models/download';

const routes: CustomRoute = {
	path: '/download',
	components: () => [import(/* webpackChunkName: 'download' */ './component')],
	render: ([Download]) => <Download />,
	onEnter: async ({ store, next }) => {
		console.log('on Enter Download');
		const children = await next();
		store.dispatch(storeActivePage('download'));
		await store.dispatch(initializeDownload());
		console.log('on Enter Download / end');
		return children;
	},
};

export default routes;
