import { createAction, handleActions } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';

import { wrapLangFetch } from 'util/api';
import { GetState, State as GlobalState } from '../reducers';

export interface ArticleTag {
	id: number;
	name: string;
}

export interface ArticleListType {
	id: number;
	post_category: {
		id: number;
		name: string;
	};
	post_tags: ArticleTag[];
	title: string;
	list_info: string;
	published_at: string;
	pic: string;
}

export const setArticlePerPage = createAction('SET_ARTICLE_PER_PAGE', (perPage: number) => perPage);

export const fetchArticleLists = createAction(
	'FETCH_ARTICLE_LISTS',
	() => async (_: Dispatch, getState: GetState) => {
		const {
			routing: { queries },
			article: { articlePerPage },
		} = getState();

		const { status, message, data } = await wrapLangFetch(
			'post',
			{ method: 'GET' },
			{
				post_category_id: queries.type || '',
				post_tags: queries.tags || '',
				page: +queries.page || 1,
				per_page: articlePerPage,
			},
		);

		if (status !== 200) {
			throw Error(message);
		}

		return data;
	},
);

export const fetchArticleTags = createAction('FETCH_ARTICLE_TAGS', () => async () => {
	const { status, message, data } = await wrapLangFetch('postTag', { method: 'GET' });

	if (status !== 200) {
		throw Error(message);
	}

	return data;
});

export const initializeArticle = createAction(
	'INITIALIZE_ARTICLE',
	() => async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fetchArticleLists());
		dispatch(fetchArticleTags());
	},
);

export interface State {
	meta: {
		title: string;
		description: string;
		json_ld: string;
	};
	type: {
		id: number;
		name: string;
	};
	tags: ArticleTag[];
	articlePerPage: number;
	articleLists: ArticleListType[];
	pagination: {
		current_page: number;
		last_page: number;
		total: number;
	};
}

const initialState: State = {
	meta: {
		title: '',
		description: '',
		json_ld: '',
	},
	type: {
		id: 0,
		name: '',
	},
	tags: [],
	articlePerPage: window.innerWidth >= 1280 ? 9 : 4,
	articleLists: [],
	pagination: {
		current_page: 1,
		last_page: 0,
		total: 0,
	},
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	article: handleActions<State, any>(
		{
			SET_ARTICLE_PER_PAGE: (state, action) => ({
				...state,
				articlePerPage: action.payload,
			}),
			FETCH_ARTICLE_LISTS_FULFILLED: (state, action) => ({
				...state,
				meta: {
					title: action.payload.postCategory?.meta_title,
					description: action.payload.postCategory?.meta_description,
					json_ld: action.payload.postCategory?.json_ld,
				},
				type: {
					id: action.payload.postCategory?.id,
					name: action.payload.postCategory?.name,
				},
				articleLists: action.payload.posts?.data,
				pagination: action.payload.posts?.meta,
			}),
			FETCH_ARTICLE_TAGS_FULFILLED: (state, action) => ({
				...state,
				tags: action.payload,
			}),
		},
		initialState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

const mapHooksToState = (state: GlobalState) => ({
	...state.article,
});

export const useArticle = () =>
	useRedux(mapHooksToState, {
		setArticlePerPage,
		fetchArticleLists,
	});
