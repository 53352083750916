import { createAction, handleActions } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';
import { wrapLangFetch } from 'util/api';
import { State as GlobalState } from './reducers';

interface HomeBanner {
	id: number;
	pic: string;
}
interface TagProperty {
	id: number;
	name: string;
}

export interface HomeArticle {
	id: number;
	post_category: {
		id: number;
		name: string;
	};
	title: string;
	list_info: string;
	pic: string;
	post_tags: TagProperty[];
}

export const fetchHome = createAction('FETCH_HOME', () => async () => {
	const { status, message, data } = await wrapLangFetch('homepage', { method: 'GET' });

	if (status !== 200) {
		throw Error(message);
	}

	return data;
});

export const initializeHome = createAction(
	'INITIALIZE_HOME',
	() => async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fetchHome());
	},
);

export interface State {
	banners: {
		desktop: HomeBanner[];
		mobile: HomeBanner[];
	};
	articles: HomeArticle[];
}

const initialState: State = {
	banners: {
		desktop: [],
		mobile: [],
	},
	articles: [],
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	home: handleActions<State, any>(
		{
			FETCH_HOME_FULFILLED: (state, action) => ({
				...state,
				banners: {
					desktop: action.payload.banners.pc,
					mobile: action.payload.banners.mobile,
				},
				articles: action.payload.posts,
			}),
		},
		initialState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

const mapHooksToState = (state: GlobalState) => ({
	...state.home,
});

export const useHome = () => useRedux(mapHooksToState, {});
