import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Link from 'components/atoms/Link';

import styles from './index.css';
import { withLang } from 'util/i18n';

interface UserMenuProperty {
	className?: string;
	logout?: () => void;
}

const UserMenu: React.FC<UserMenuProperty> = ({ className, logout = () => {} }) => {
	const { t } = useTranslation('nav');

	return (
		<div className={classnames(styles.userMenu, className)}>
			<Link className={styles.item} to="/member/account">
				{/* {t('account')} */}
				{t('member')}
			</Link>
			{withLang('') !== 'en/' && (
				<Link className={styles.item} to="/member/order">
					{t('memberOrder')}
				</Link>
			)}
			<Link className={styles.item} to="/member/inquire">
				{t('inquire')}
			</Link>
			<button
				className={styles.item}
				type="button"
				onClick={() => {
					logout();
				}}
			>
				{t('logout')}
			</button>
		</div>
	);
};

export default UserMenu;
