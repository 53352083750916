import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';

import { GetState, State as GlobalState } from './reducers';

const PAGES_WITH_TRANSPARENT_HEADER = ['/', '/about'];

export const checkIsPageWithTransparentHeader = createAction(
	'CHECK_IS_PAGE_WITH_TRANSPARENT_HEADER',
	(pathname: string) => (_: Dispatch, getState: GetState) => {
		const {
			i18n: { lan },
			modal: { isOpen },
		} = getState();

		const isHome = pathname.split('/').length <= 2;

		if (isHome) {
			return PAGES_WITH_TRANSPARENT_HEADER.includes('/') && !isOpen;
		}

		return PAGES_WITH_TRANSPARENT_HEADER.map(page => `/${lan}${page}`).includes(pathname);
	},
);

export const setIsHeaderTransparent = createAction(
	'SET_IS_HEADER_TRANSPARENT',
	(state: boolean) => state,
);

export const setTransparentHeight = createAction(
	'SET_TRANSPARENT_HEIGHT',
	(height: string) => height,
);

export interface State {
	pageWithTransparentHeader: boolean;
	isTransparent: boolean;
	transparentHeight: number;
}

const initialState: State = {
	pageWithTransparentHeader: false,
	isTransparent: false,
	transparentHeight: 0,
};

export const reducer = {
	header: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			CHECK_IS_PAGE_WITH_TRANSPARENT_HEADER: (state, action) => ({
				...state,
				pageWithTransparentHeader: action.payload,
			}),

			SET_IS_HEADER_TRANSPARENT: (state, action) => ({
				...state,
				isTransparent: action.payload,
			}),

			SET_TRANSPARENT_HEIGHT: (state, action) => ({
				...state,
				transparentHeight: action.payload,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.header,
});

export const useHeaderState = () =>
	useRedux(mapHooksToState, {
		checkIsPageWithTransparentHeader,
		setIsHeaderTransparent,
		setTransparentHeight,
	});
