import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';
import { initializeArticleDetail } from 'models/article/articleDetail';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [import(/* webpackChunkName: 'articleDetail' */ './component')],
	render: ([ArticleDetail]) => <ArticleDetail />,
	onEnter: async ({ store, params: { id }, next }) => {
		console.log('on Enter Article Detail');
		const children = await next();
		store.dispatch(storeActivePage(`article_${id}`));
		await store.dispatch(initializeArticleDetail(+id));
		console.log('on Enter Article Detail / end');
		return children;
	},
};

export default routes;
