import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { initializeMemberAccount } from 'models/member/account';

const routes: CustomRoute = {
	path: '/account',
	components: () => [import(/* webpackChunkName: 'account' */ './component')],
	render: ([Account]) => <Account />,
	onEnter: async ({ store }) => {
		store.dispatch(initializeMemberAccount());
	},
};
export default routes;
