import React from 'react';
import classnames from 'classnames';

import styles from './index.css';

interface PageTitleProperty {
	className?: string;
}

const PageTitle: React.FC<PageTitleProperty> = ({ children, className }) => (
	<h1 className={classnames(styles.pageTitle, className)}>{children}</h1>
);

export default PageTitle;
