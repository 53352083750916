import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { initializeMemberInquire } from 'models/member/inquire/list';
import { storeActivePage } from 'models/common';
import DetailRoute from './Detail';

const routes: CustomRoute = {
	path: '/inquire',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'memberInquire' */ './component')],
			render: ([MemberInquire]) => <MemberInquire />,
			onEnter: async ({ store, next }) => {
				console.log('on Enter MemberInquire');
				const children = await next();
				store.dispatch(initializeMemberInquire());
				store.dispatch(storeActivePage('member/inquire'));
				console.log('on Enter MemberInquire / end');
				return children;
			},
		},
		DetailRoute,
	],
};
export default routes;
