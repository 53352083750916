import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import Link from 'components/atoms/Link';
import styles from './index.css';

interface CookieNotificationProperty {
	className?: string;
}

const CookieNotification: React.FC<CookieNotificationProperty> = ({ className }) => {
	const { t } = useTranslation('cookie');
	const [active, setActive] = useState(false);

	useEffect(() => {
		const enableCookie = cookies.get('enableCookies');

		if (!enableCookie) {
			setActive(true);
		}
	}, []);

	const agree = () => {
		cookies.set('enableCookies', 'Y');
		setActive(false);
	};

	return (
		<div className={classnames(styles.cookieNotification, className, { [styles.active]: active })}>
			<div className={styles.textContainer}>
				<div className={styles.title}>{t('title')}</div>
				<div className={styles.content}>{t('content')}</div>
			</div>
			<div className={styles.actions}>
				<Link to="/policies" className={styles.more}>
					{t('more')}
				</Link>
				<button className={styles.agree} onClick={() => agree()} type="button">
					{t('agree')}
				</button>
			</div>
		</div>
	);
};

export default CookieNotification;
