import { useState, useEffect, useRef, MutableRefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

interface Option {
	onTrue?: () => void;
	onFalse?: () => void;
	defaultBoolean?: boolean;
}

type useBooleanFunc = (
	options: Option,
) => [boolean, { toggle: () => void; setFalse: () => void; setTrue: () => void }];

export const useBoolean: useBooleanFunc = options => {
	const { onTrue = () => {}, onFalse = () => {}, defaultBoolean = false } = options;

	const [state, setState] = useState<boolean>(defaultBoolean);

	const toggle = () => {
		if (state) {
			onFalse();
		} else {
			onTrue();
		}
		setState(!state);
	};

	const setFalse = () => {
		setState(false);
		onFalse();
	};

	const setTrue = () => {
		setState(true);
		onTrue();
	};

	return [state, { toggle, setFalse, setTrue }];
};

export interface SelectValue {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	label: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
}

type useSelectParams<T> = {
	defaultValue?: T[];
	propsValue?: T[];
	onChange?: (v: T[]) => void;
};

export const useSelect = <T = SelectValue>({
	defaultValue = [],
	propsValue = [],
	onChange = () => {},
}: useSelectParams<T>): [T[], (newValue: T) => void] => {
	const [value, setState] = useState<T[]>(defaultValue);

	const onChangeValue = (newValue: T) => {
		setState([newValue]);
		onChange([newValue]);
	};

	useEffect(() => {
		if (propsValue) {
			setState(propsValue);
		}
	}, [propsValue]);

	return [value, onChangeValue];
};

export const usePrevious = <T = boolean>(value: T) => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
};

type useMeasureFunc = <T extends Element>() => [
	{ ref: MutableRefObject<T | null> },
	{ left: number; top: number; width: number; height: number },
];

export const useMeasure: useMeasureFunc = <T extends Element>() => {
	const ref = useRef<T>(null);
	const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
	const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)));
	useEffect(() => {
		if (ref.current) ro.observe(ref.current);
		return () => ro.disconnect();
	}, []);
	return [{ ref }, bounds];
};
