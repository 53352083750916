import React, { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { animated } from 'react-spring';
import { withLang } from 'util/i18n';
import { useCommon } from 'models/common';
import { useRouting } from 'models/routing';
import { useAuth } from 'models/auth/common';
import { useMemberAccount } from 'models/member/account';
import { useMedia } from 'util/event';
import { checkSubNameIsTypeAll } from 'util/helper';
import Link from 'components/atoms/Link';
import styles from './index.css';

interface NavigationProperty {
	className?: string;
	whiteText?: boolean;
}

interface LinksProperty {
	mobile_only?: boolean;
	name: string;
	link?: string;
	sub?: ({
		id?: number | string;
		name: string;
		link?: string;
		onClick?: () => void;
	} | null)[];
}

const Navigation: React.FC<NavigationProperty> = ({ className, whiteText, ...props }) => {
	const { t } = useTranslation('nav');
	const media = useMedia();
	const [{ activePage, newsTypes, articleTypes }] = useCommon();
	const [{ pathname, queries }] = useRouting();
	const [{ isLogin }, { logout }] = useAuth();
	const [
		{
			serverAccount: { name: userName },
		},
	] = useMemberAccount();

	let links: LinksProperty[] = [];
	if (withLang('') !== 'en/') {
		if (isLogin) {
			links.push({
				mobile_only: true,
				name: `Hi, ${userName}`,
				sub: [
					{
						// name: t('account'),
						name: t('member'),
						link: '/member/account',
					},
					{
						name: t('memberOrder'),
						link: '/member/order',
					},
					{
						name: t('inquire'),
						link: '/member/inquire',
					},
					{
						name: t('logout'),
						onClick: logout,
					},
				],
			});
		} else {
			links.push({ mobile_only: true, name: t('login'), link: '/auth/login' });
		}
	}
	links = links.concat([
		{
			name: t('about'),
			sub: [
				{
					name: t('company'),
					link: '/about',
				},
				{
					name: t('history'),
					link: '/history',
				},
			],
		},
		{
			name: t('news'),
			link: media === 'desktop' ? '/news' : '',
			sub: newsTypes.map(({ id, name }) => {
				switch (true) {
					case !checkSubNameIsTypeAll(name):
						return {
							id,
							name,
							link: `/news?type=${id}`,
						};
					case media === 'mobile':
						return {
							name,
							link: `/news`,
						};
					default:
						return null;
				}
			}),
		},
		{
			name: t('product'),
			sub: [
				{
					name: t('allProduct'),
					link: '/category',
				},
				{
					name: t('download'),
					link: '/download',
				},
			],
		},
		{
			name: t('article'),
			link: media === 'desktop' ? '/article' : '',
			sub: articleTypes.map(({ id, name }) => {
				switch (true) {
					case !checkSubNameIsTypeAll(name):
						return {
							id,
							name,
							link: `/article?type=${id}`,
						};
					case media === 'mobile':
						return {
							name,
							link: `/article`,
						};
					default:
						return null;
				}
			}),
		},
		{
			name: t('faq'),
			link: '/faq',
		},
		{
			name: t('order'),
			link: '/order',
		},
		{
			name: t('contact'),
			link: '/contact',
		},
		{
			name: t('shop'),
			link: '/shop',
		},
		/* eslint-enable indent */
	]);

	const [openMobileSubName, setOpenMobileSubName] = useState<string | null>(null);

	return (
		<animated.nav
			className={classnames(styles.navigation, className, { [styles.white]: whiteText })}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			{links.map(({ mobile_only, name, link, sub }) => {
				if (
					withLang(link || '') === '/en/shop' ||
					withLang(link || '') === '/en/order' ||
					withLang(link || '') === '/en/faq'
				)
					return null;
				if (mobile_only && media !== 'mobile') {
					return null;
				}
				if (sub) {
					return (
						<div
							className={classnames(styles.linkWrap, {
								[styles.mobileExpanded]: openMobileSubName === name,
							})}
							onClick={() => setOpenMobileSubName(openMobileSubName === name ? null : name)}
							area-hidden="true"
							role="button"
							onKeyUp={() => {}}
							tabIndex={0}
							key={link || name}
						>
							<Link
								to={link}
								className={classnames(styles.link, {
									[styles.active]: withLang(link || '') === pathname,
								})}
							>
								{name}
								<div className={styles.expand} />
							</Link>
							<div className={styles.subWrap}>
								{sub.map(i => {
									if (!i) {
										return null;
									}
									return (
										<Link
											to={i.link}
											className={classnames(styles.subLink, {
												[styles.active]:
													activePage !== 'home' &&
													withLang(i.link || '').startsWith(pathname) &&
													`${i.id || ''}` === `${queries.type || queries.category || ''}`,
												[styles.white]: whiteText,
											})}
											onClick={i.onClick}
											key={i.link}
										>
											{i.name}
										</Link>
									);
								})}
							</div>
						</div>
					);
				}
				return (
					<div className={styles.linkWrap} key={link || name}>
						<Link
							to={link}
							className={classnames(styles.link, {
								[styles.active]: withLang(link || '') === pathname,
								[styles.white]: whiteText,
							})}
						>
							{name}
						</Link>
					</div>
				);
			})}
		</animated.nav>
	);
};

export default Navigation;
