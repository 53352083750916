import { createAction, handleActions } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';

import { wrapLangFetch } from 'util/api';
import { State as GlobalState } from './reducers';

interface CatalogType {
	id: number;
	name: string;
	pic: string;
	file: string;
}

export const fetchCatalog = createAction('FETCH_CATALOG', () => async () => {
	const { status, message, data } = await wrapLangFetch('catalog', { method: 'GET' });

	if (status !== 200) {
		throw Error(message);
	}

	return data;
});

export const initializeDownload = createAction(
	'INITIALIZE_DOWNLOAD',
	() => async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fetchCatalog());
	},
);

export interface State {
	catalogs: CatalogType[];
}

const initialState: State = {
	catalogs: [],
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	download: handleActions<State, any>(
		{
			FETCH_CATALOG_FULFILLED: (state, action) => ({
				...state,
				catalogs: action.payload,
			}),
		},
		initialState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

const mapHooksToState = (state: GlobalState) => ({
	...state.download,
});

export const useDownload = () => useRedux(mapHooksToState, {});
