import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/atoms/PageTitle';
import SidebarMember from 'components/atoms/SidebarMember';
import styles from './index.css';

const MemberLayout: React.FC = ({ children }) => {
	const { t } = useTranslation('memberAccount');

	return (
		<div className={styles.memberLayout}>
			<PageTitle>{t('member')}</PageTitle>
			<div className={styles.content}>
				<SidebarMember />
				<div className={styles.children}>{children}</div>
			</div>
		</div>
	);
};

export default MemberLayout;
