import { Dispatch } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { haveFullwidth, isExist, mobileValid, telValid, emailValid } from 'util/helper';
import { isGuiNumberValid } from 'taiwan-id-validator';
import { wrapAuthFetch, wrapLangFetch } from 'util/api';
import { t } from 'util/i18n';
import { openNotification } from 'models/notification';
import { logout } from 'models/auth/common';
import { GetState, State as GlobalState } from '../reducers';
import { addQuantityToList, clearCart } from './list';

const setDeliveryFee = createAction('SET_DELIVERY_FEE', (fee: number) => fee);

const fetchDeliveryFee = createAction(
	'FETCH_DELIVERY_FEE',
	(distId: string) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			cartList: { list },
		} = getState();

		const body = {
			dist_id: distId,
			products: list.map(({ id, quantity, price }) => ({ id, quantity, price })),
		};

		const { status, message, data } = await wrapLangFetch('order/getShippingFee', {
			method: 'POST',
			body: JSON.stringify(body),
		});

		switch (true) {
			case status === 422: // invalid products
				dispatch(addQuantityToList(data?.products));
				throw new Error(t('cart:invalidProducts'));
			case status !== 200:
				throw new Error(message);
			default: {
				dispatch(setDeliveryFee(data?.shipping_fee || 0));
			}
		}
	},
);

const setCheckoutCompany = createAction('SET_CHECKOUT_COMPANY', (company: string) => {
	const valid = isExist(company);
	return { valid, value: company };
});

const setCheckoutDist = createAction(
	'SET_CHECKOUT_DIST',
	(dist: string) => async (dispatch: Dispatch) => {
		const valid = dist !== 'undefined' && isExist(dist); // api 回傳的是 'undefined'，isExist 會判定為true，優化需修改isExist

		if (valid) {
			dispatch(fetchDeliveryFee(dist));
		}

		return { valid, value: dist };
	},
);

const setCheckoutCity = createAction(
	'SET_CHECKOUT_CITY',
	(city: string) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			cartCheckout: { city: prevCity },
		} = getState();

		if (city !== prevCity.value) {
			dispatch(setCheckoutDist(''));
		}

		const valid = city !== 'undefined' && isExist(city); // 同上
		return { valid, value: city };
	},
);

const setCheckoutAddress = createAction('SET_CHECKOUT_ADDRESS', (address: string) => {
	const valid = isExist(address);
	return { valid, value: address };
});

const setCheckoutName = createAction('SET_CHECKOUT_NAME', (name: string) => {
	const valid = isExist(name);
	return { valid, value: name };
});

const setCheckoutNumber = createAction('SET_CHECKOUT_NUMBER', (number: string) => {
	const valid = (mobileValid(number) || telValid(number)) && !haveFullwidth(number);
	return { valid, value: number };
});

const setCheckoutEmail = createAction('SET_CHECKOUT_EMAIL', (email: string) => {
	const valid = emailValid(email) && !haveFullwidth(email);
	return { valid, value: email };
});

const setCheckoutGUI = createAction('SET_CHECKOUT_GUI', (GUI: string) => {
	const valid = isGuiNumberValid(GUI);
	return { valid, value: GUI };
});

const setCheckoutInvoice = createAction('SET_CHECKOUT_INVOICE', (invoice: string) => {
	const valid = isExist(invoice);
	return { valid, value: invoice };
});

const setCheckoutHeading = createAction('SET_CHECKOUT_HEADING', (heading: string) => {
	const valid = isExist(heading);
	return { valid, value: heading };
});

const setCheckoutCoupon = createAction('SET_CHECKOUT_COUPON', (coupon: string) => async () => {
	const valid = true;
	return { valid, value: coupon };
});

const setDiscount = createAction('SET_DISCOUNT', (discount: number) => discount);

const resetCoupon = createAction('RESET_COUPON');

const validateCoupon = createAction(
	'VALIDATE_COUPON',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			cartCheckout: {
				coupon: { value },
			},
			cartList: { list },
		} = getState();

		const body = {
			promo_code: value,
			products: list.map(({ id, quantity, price }) => ({ id, quantity, price })),
		};

		const { status, message, data } = await wrapLangFetch('order/usePromoCode', {
			method: 'POST',
			body: JSON.stringify(body),
		});

		switch (true) {
			case status === 400: // invalid coupon
				throw new Error(t('cart:couponAlert.invalidCoupon'));
			case status === 422: // invalid products
				dispatch(addQuantityToList(data?.products));
				throw new Error(t('cart:couponAlert.invalidProducts'));
			case status === 404: // expired coupon
				throw new Error(t('cart:couponAlert.expiredCoupon'));
			case status !== 200:
				throw new Error(message);
			default: {
				dispatch(
					openNotification({
						type: 'success',
						message: t('cart:couponAlert.success'),
					}),
				);
				dispatch(setDiscount(data?.promo_free || 0));
			}
		}
	},
);

const setAllMemberInput = createAction(
	'SET_ALL_MEMBER_INPUT',
	() => async (dispatch: Dispatch, getState: GetState) => {
        // 將會員資料加入預設值
		const {
			memberAccount: {
				serverAccount: { company, GUI, city, dist, address, name, number, email },
			},
            common: {
                activeCityList
            },
		} = getState();

		dispatch(setCheckoutCompany(company));
		dispatch(setCheckoutGUI(GUI));
		dispatch(setCheckoutName(name));
		dispatch(setCheckoutNumber(number));
		dispatch(setCheckoutEmail(email));
        // 如果會員地址資料存在有效運送地區才幫他帶入
        if (activeCityList.some(item => item.id === Number(city))) {
            console.log('good');
            dispatch(setCheckoutCity(city));
            dispatch(setCheckoutDist(dist));
            dispatch(setCheckoutAddress(address));
        }
	},
);

const initializeCartCheckout = createAction(
	'INITIALIZE_CART_CHECKOUT',
	() => async (dispatch: Dispatch) => {
		dispatch(setAllMemberInput());
		dispatch(resetCoupon());
	},
);

const toggleHintModal = createAction('TOGGLE_HINT_MODAL', (show: boolean) => show);

const checkout = createAction('CHECKOUT', () => async (dispatch: Dispatch, getState: GetState) => {
	const {
		cartCheckout: {
			company,
			GUI,
			dist,
			address,
			name,
			number,
			email,
			invoice,
			heading,
			coupon,
			discount,
			deliveryFee,
		},
		cartList: { amount, list },
	} = getState();

	const body = {
		addressee_company: company.value,
		addressee_name: name.value,
		addressee_number: number.value,
		addressee_email: email.value,
		addressee_dist_id: dist.value,
		addressee_address: address.value,
		invoice_type: invoice.value,
		...(invoice.value === 'three' && {
			invoice_title: heading.value,
			invoice_tax_id: GUI.value,
		}),
		promo_code: coupon.value,
		subtotal: amount,
		promo_free: discount,
		shipping_fee: deliveryFee,
		total: amount - discount + deliveryFee,
		products: list.map(({ id, quantity, price }) => ({ id, quantity, price })),
	};

	const { status, message, data } = await wrapAuthFetch('order', {
		method: 'POST',
		body: JSON.stringify(body),
	});

	switch (true) {
		case status === 401: // invalid token
			await dispatch(
				openNotification({
					type: 'error',
					message: t('auth:expiredToken'),
				}),
			);
			dispatch(logout());
			throw new Error(t('auth:expiredToken'));
		case status === 422: // invalid values in body
			dispatch(toggleHintModal(false));
			dispatch(addQuantityToList(data?.products));
			dispatch(setDeliveryFee(data?.shipping_fee || 0));
			dispatch(setDiscount(data?.promo_free || 0));
			dispatch(openNotification({ type: 'error', message: t('cart:invalidProducts') }));
			throw new Error(t('cart:invalidProducts'));
		case status !== 200:
			throw new Error(message);
		default: {
			const { action, input: inputs } = data?.ecpay;
			if (!action || !inputs) {
				throw new Error(message);
			}

			await dispatch(clearCart());

			const redirectToECPay = () => {
				const form = document.createElement('form');

				form.method = 'POST';
				form.action = action;
				form.target = '_self';

				Object.keys(inputs).forEach(key => {
					const input = document.createElement('input');
					input.type = 'hidden';
					input.name = key;
					input.value = inputs[key];
					form.appendChild(input);
				});

				document.body.appendChild(form);

				form.submit();
			};
			redirectToECPay();
		}
	}
});

interface InputType {
	value: string;
	valid: boolean;
}

type Inputs =
	| 'company'
	| 'GUI'
	| 'city'
	| 'dist'
	| 'address'
	| 'name'
	| 'number'
	| 'email'
	| 'invoice'
	| 'heading'
	| 'coupon';

type ClientAccountData = Record<Inputs, InputType>;

export type State = ClientAccountData & {
	loading: boolean;
	message: string;
	showHintModal: boolean;
	deliveryFee: number;
	discount: number;
};

export const initialState: State = {
	company: {
		value: '',
		valid: false,
	},
	GUI: {
		value: '',
		valid: false,
	},
	city: {
		value: '',
		valid: false,
	},
	dist: {
		value: '',
		valid: false,
	},
	address: {
		value: '',
		valid: false,
	},
	name: {
		value: '',
		valid: false,
	},
	number: {
		value: '',
		valid: false,
	},
	email: {
		value: '',
		valid: false,
	},
	invoice: {
		value: '',
		valid: false,
	},
	heading: {
		value: '',
		valid: false,
	},
	coupon: {
		value: '',
		valid: true,
	},
	loading: false,
	message: '',
	showHintModal: false,
	deliveryFee: 0,
	discount: 0,
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cartCheckout: handleActions<State, any>(
		{
			SET_DELIVERY_FEE: (state, action) => ({
				...state,
				deliveryFee: action.payload,
			}),

			SET_CHECKOUT_COMPANY: (state, action) => ({
				...state,
				company: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_CHECKOUT_GUI: (state, action) => ({
				...state,
				GUI: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_CHECKOUT_CITY_FULFILLED: (state, action) => ({
				...state,
				city: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_CHECKOUT_DIST_FULFILLED: (state, action) => ({
				...state,
				dist: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
				...(!action.payload.valid && { deliveryFee: 0 }),
			}),

			SET_CHECKOUT_ADDRESS: (state, action) => ({
				...state,
				address: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_CHECKOUT_NAME: (state, action) => ({
				...state,
				name: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_CHECKOUT_NUMBER: (state, action) => ({
				...state,
				number: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_CHECKOUT_EMAIL: (state, action) => ({
				...state,
				email: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_CHECKOUT_INVOICE: (state, action) => ({
				...state,
				invoice: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_CHECKOUT_HEADING: (state, action) => ({
				...state,
				heading: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_CHECKOUT_COUPON_FULFILLED: (state, action) => ({
				...state,
				coupon: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_DISCOUNT: (state, action) => ({
				...state,
				discount: action.payload,
			}),

			RESET_COUPON: state => ({
				...state,
				coupon: {
					valid: true,
					value: '',
				},
				discount: 0,
			}),

			VALIDATE_COUPON_PENDING: state => ({
				...state,
				loading: true,
			}),

			VALIDATE_COUPON_REJECTED: (state, action) => ({
				...state,
				loading: false,
				message: action.payload.message,
				coupon: {
					valid: false,
					value: state.coupon.value,
				},
			}),

			VALIDATE_COUPON_FULFILLED: state => ({
				...state,
				loading: false,
				coupon: {
					valid: true,
					value: state.coupon.value,
				},
			}),

			TOGGLE_HINT_MODAL: (state, action) => ({
				...state,
				showHintModal: action.payload,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.cartCheckout,
});

export const useCartCheckout = () =>
	useRedux(mapHooksToState, {
		initializeCartCheckout,
		setCheckoutCompany,
		setCheckoutCity,
		setCheckoutDist,
		setCheckoutAddress,
		setCheckoutName,
		setCheckoutNumber,
		setCheckoutEmail,
		setCheckoutGUI,
		setCheckoutInvoice,
		setCheckoutHeading,
		setCheckoutCoupon,
		resetCoupon,
		validateCoupon,
		toggleHintModal,
		checkout,
	});
