import { createAction, handleActions } from 'redux-actions';
import { Dispatch, AnyAction } from 'redux';
import { useRedux } from 'util/hook/redux';
import { wrapLangFetch } from 'util/api';
import { GetState, State as GlobalState } from '../reducers';
import { historyPush } from 'models/routing';

const clearProductContent = createAction('CLEAR_PRODUCT_CONTENT');

interface FetchProductContentPayload {
	product: {
		id: number;
		product_category: {
			meta_title: string;
			meta_description: string;
			json_ld: string;
			id: number;
			name: string;
		};
		meta_title: string;
		meta_description: string;
		json_ld: string;
		product_no: string;
		name: string;
		name_h2: string;
		intro: string;
		material: string;
		thickness: string;
		surface_treatment: string;
		size: string;
		capacity: string;
		net_weight: string;
		content: string;
		pic: string;
		pic_alt: string;
		other_pic: [
			{
				id: number;
				pic: string;
				pic_alt: string;
			},
			{
				id: number;
				pic: string;
				pic_alt: string;
			},
		];
		is_shop: 'Y' | 'N';
		inventory: number;
	};
}

// 定義一個 Thunk Function 用來處理 非同步行為（e.q. send API）
const fetchProductContent = createAction(
	'FETCH_PRODUCT_CONTENT',
	(id: string) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			routing: { queries },
		} = getState();
		// for 後台預覽功能
		if (queries.backstage_token) {
			const { status, message, data } = await wrapLangFetch(`product/preview/${id}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${queries.backstage_token}`,
				},
			});

			if (status !== 200) {
				throw Error(message);
			}

			return data;
		}
		// for 前台
		const { status, message, data } = await wrapLangFetch(`product/${id}`, { method: 'GET' });

		if (status !== 200) {
			throw Error(message);
		}

		if (!data) dispatch(historyPush({ pathname: '/' }));

		return data;
	},
);

export const initializeProductContent = createAction(
	'INITIALIZE_PRODUCT_CONTENT',
	(id: string) => async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fetchProductContent(id));
	},
);

export interface ProductPicProperty {
	id: number;
	pic: string;
	pic_alt: string;
}
// Product Content Redux 全域狀態型別
export interface State {
	meta: {
		title: string;
		description: string;
		json_ld: string;
	};
	category: {
		id: number;
		name: string;
	};
	product: {
		id: number;
		name: string;
		name_h2: string;
		product_no: string;
		intro: string;
		texture: string;
		size: string;
		thickness: string;
		surface: string;
		capacity: string;
		net_weight: string;
		description: string;
		pics: ProductPicProperty[];
		is_shop: 'Y' | 'N';
		inventory: number;
	};
}
// Product Content Redux 全域狀態初始值
const initialState: State = {
	meta: {
		title: '',
		description: '',
		json_ld: '',
	},
	category: {
		id: 0,
		name: '',
	},
	product: {
		id: 0,
		name: '',
		name_h2: '',
		product_no: '',
		intro: '',
		texture: '',
		size: '',
		thickness: '',
		surface: '',
		capacity: '',
		net_weight: '',
		description: '',
		pics: [],
		is_shop: 'N',
		inventory: 0,
	},
};
// Product Content Reducer
export const reducer = {
	productContent: handleActions<State, FetchProductContentPayload>(
		{
			// 將 Product Content 全域狀態回復初始值
			CLEAR_PRODUCT_CONTENT: state => ({
				...state,
				...initialState,
			}),
			// 將 API 成功的 Response 結果填入 Product Content 全域狀態中
			FETCH_PRODUCT_CONTENT_FULFILLED: (state, action) => ({
				...state,
				meta: {
					title: action.payload.product?.meta_title,
					description: action.payload.product?.meta_description,
					json_ld: action.payload.product?.json_ld,
				},
				category: {
					id: action.payload.product?.product_category?.id,
					name: action.payload.product?.product_category?.name,
				},
				product: {
					id: action.payload.product?.id,
					name: action.payload.product?.name,
					name_h2: action.payload.product?.name_h2,
					product_no: action.payload.product?.product_no,
					intro: action.payload.product?.intro,
					texture: action.payload.product?.material,
					size: action.payload.product?.size,
					thickness: action.payload.product?.thickness,
					surface: action.payload.product?.surface_treatment,
					capacity: action.payload.product?.capacity,
					net_weight: action.payload.product?.net_weight,
					description: action.payload.product?.content,
					pics: [
						...[
							{ id: 0, pic: action.payload.product?.pic, pic_alt: action.payload.product?.pic_alt },
						],
						...action.payload.product?.other_pic,
					],
					is_shop: action.payload.product?.is_shop,
					inventory: action.payload.product?.inventory,
				},
			}),
		},
		initialState,
	),
};

// 這是一個 Redux Selector，從全域狀態中提取 Product Content 這個部分的全域狀態
const mapHooksToState = (state: GlobalState) => ({
	...state.productContent,
});
// Redux 與 React 溝通的 Hooks，將 Selector 取得的狀態返回
export const useProductContent = () => useRedux(mapHooksToState, { clearProductContent });
