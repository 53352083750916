import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';

const routes: CustomRoute = {
	path: '/about',
	components: () => [import(/* webpackChunkName: 'about' */ './component')],
	render: ([About]) => <About />,
	onEnter: async ({ store, next }) => {
		console.log('on Enter About');
		const children = await next();
		store.dispatch(storeActivePage('about'));
		console.log('on Enter About / end');
		return children;
	},
};

export default routes;
