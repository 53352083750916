import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';
import { initializeArticle } from 'models/article/article';
import DetailRoute from './ArticleDetail';

const routes: CustomRoute = {
	path: '/article',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'article' */ './component')],
			render: ([Article]) => <Article />,
			onEnter: async ({ store, next }) => {
				console.log('on Enter Article');
				const children = await next();
				store.dispatch(storeActivePage('article'));
				await store.dispatch(initializeArticle());
				console.log('on Enter Article / end');
				return children;
			},
		},
		DetailRoute,
	],
};

export default routes;
