import { CustomRoute } from 'util/hook/useRouter';

const RedirectZH: CustomRoute = {
	path: '',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '/article/71',
			components: () => [],
			render: (_, children) => children,
			onEnter: async () => {
				window.location.replace(`/zh/article/103`);
			},
		},
		{
			path: '/article/101',
			components: () => [],
			render: (_, children) => children,
			onEnter: async () => {
				window.location.replace('/zh/article/103');
			},
		},
	],
};

export default RedirectZH;
