import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { initializeMemberInquireDetail } from 'models/member/inquire/detail';
import { storeActivePage } from 'models/common';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [import(/* webpackChunkName: 'memberInquireDetail' */ './component')],
	render: ([MemberInquireDetail]) => <MemberInquireDetail />,
	onEnter: async ({ store, params: { id } }) => {
		console.log('on Enter MemberInquireDetail');
		store.dispatch(initializeMemberInquireDetail(`${id}`));
		store.dispatch(storeActivePage(`member/inquire/${id}`));
		console.log('on Enter MemberInquireDetail / end');
	},
};
export default routes;
