import { createAction, handleActions } from 'redux-actions';
import { createContext, useContext } from 'react';
import { Dispatch } from 'redux';
import { Location, History } from 'history';
import qs from 'qs';

import { useRedux } from 'util/hook/redux';
import history from 'store/history';

import { GetState, State as GlobalState } from './reducers';

export const routeChange = createAction<Location, Location>(
	'ROUTE_LOCATION_CHANGE',
	(location: Location) => ({
		...location,
		queries: {
			...qs.parse(location.search, { ignoreQueryPrefix: true }),
		},
	}),
);

export const historyPush = createAction(
	'HISTORY_PUSH',
	({ pathname = '', search = '', lan = '', state = {}, withLan = true }) => (
		_: Dispatch,
		getState: GetState,
	) => {
		const {
			routing: { pathname: prevPathname, search: prevSearch },
			i18n: { lan: prevLan },
		} = getState();

		let nextPathname = pathname;
		if (lan) {
			if (!pathname || pathname === '/') {
				nextPathname = `/${lan}`;
			} else {
				nextPathname = `/${lan}${pathname}`;
			}
		} else if (withLan && pathname && pathname !== '/') {
			nextPathname = `/${prevLan}${pathname}`;
		}

		history.push({
			pathname: nextPathname,
			search,
			state: {
				prevPathname,
				prevSearch,
				...state,
			},
		});
	},
);

type Queries = {
	[key: string]: string | number | undefined;
};
interface PushQueriesArgs {
	queries: Queries;
	pathname?: string;
	merge?: boolean;
}

export const pushQueries = createAction(
	'PUSH_QUERIES',
	({ queries, pathname, merge = true }: PushQueriesArgs) => (
		dispatch: Dispatch,
		getState: GetState,
	) => {
		const {
			routing: { pathname: prevPathname },
		} = getState();

		const nextPathname = pathname || prevPathname;

		const prevQueries = qs.parse(history.location.search, { ignoreQueryPrefix: true });
		const nextQueries: Queries = merge ? { ...prevQueries, ...queries } : { ...queries };

		dispatch(
			historyPush({
				pathname: nextPathname,
				search: qs.stringify(nextQueries, { addQueryPrefix: true }),
				withLan: false,
			}),
		);
	},
);

export interface State extends Location {
	queries: {
		[key: string]: string;
	};
	pathname: string;
	search: string;
}

export const reducer = {
	routing: handleActions<State, any>(
		{
			ROUTE_LOCATION_CHANGE: (state, action) => ({
				...state,
				...action.payload,
			}),
		},
		{
			...history.location,
			queries: {},
		},
	),
};

export const HistoryContext = createContext<History>(history);

export const useHistory = () => useContext(HistoryContext);

const mapHooksToState = (state: GlobalState) => state.routing;

export const useRouting = () => useRedux(mapHooksToState, { historyPush, pushQueries });
