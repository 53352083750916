import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { openModal } from 'models/modal';

const routes: CustomRoute = {
	path: '/forget',
	components: () => [import(/* webpackChunkName: 'forget' */ './component')],
	render: ([Forget]) => <Forget />,
	onEnter: async ({ store }) => {
		store.dispatch(openModal({ category: 'auth', type: 'forget' }));
	},
};
export default routes;
