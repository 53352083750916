import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';

const routes: CustomRoute = {
	path: '/result',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'inquireResult' */ './component')],
			render: ([InquireResult]) => <InquireResult />,
			onEnter: async ({ store, next }) => {
				console.log('on Enter InquireResult');
				const children = await next();
				store.dispatch(storeActivePage('inquire/result'));
				console.log('on Enter InquireResult / end');
				return children;
			},
		},
	],
};

export default routes;
