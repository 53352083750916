import { createAction, handleActions } from 'redux-actions';

import { useRedux } from 'util/hook/redux';
import storage from 'util/storage';

import { State as GlobalState } from './reducers';

const loadFonts = createAction('LOAD_FONTS');

export const completedCustomizeGuide = createAction('COMPLETED_CUSTOMIZE_GUIDE', () => {
	storage.setItem('customize-guide', true);
});

export const clearGuideRecord = createAction('CLEAR_CUSTOMIZE_GUIDE_RECORD', () => {
	storage.removeItem('customize-guide');
});

export const showCustomizeGuide = createAction('SHOW_CUSTOMIZE_GUIDE');

export interface State {
	fontLoaded: boolean;
	completedCustomizeGuide: boolean;
}

const initialState: State = {
	fontLoaded: false,
	completedCustomizeGuide: false,
};

export const reducer = {
	global: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			LOAD_FONTS: state => ({
				...state,

				fontLoaded: true,
			}),

			COMPLETED_CUSTOMIZE_GUIDE: state => ({
				...state,

				completedCustomizeGuide: true,
			}),

			SHOW_CUSTOMIZE_GUIDE: state => ({
				...state,

				completedCustomizeGuide: false,
			}),

			CLEAR_CUSTOMIZE_GUIDE_RECORD: state => ({
				...state,

				completedCustomizeGuide: false,
			}),
		},
		initialState,
	),
};

const selectFonts = (state: GlobalState) => ({
	loaded: state.global.fontLoaded,
});

export const useFonts = () => useRedux(selectFonts, { loadFonts });

const selectCustomizeGuide = (state: GlobalState) => ({
	showCustomizeGuide: !state.global.completedCustomizeGuide,
});

export const useCustomizeGuide = () =>
	useRedux(selectCustomizeGuide, { completedCustomizeGuide, showCustomizeGuide });
