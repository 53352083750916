import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { initializeMemberOrderDetail } from 'models/member/order/detail';
import { storeActivePage } from 'models/common';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [import(/* webpackChunkName: 'memberOrderDetail' */ './component')],
	render: ([MemberOrderDetail]) => <MemberOrderDetail />,
	onEnter: async ({ store, params: { id } }) => {
		console.log('on Enter MemberOrderDetail');
		store.dispatch(initializeMemberOrderDetail(`${id}`));
		store.dispatch(storeActivePage(`member/order/${id}`));
		console.log('on Enter MemberOrderDetail / end');
	},
};
export default routes;
