import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { initializeMemberOrder } from 'models/member/order/list';
import { storeActivePage } from 'models/common';
import DetailRoute from './Detail';

const routes: CustomRoute = {
	path: '/order',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'memberOrder' */ './component')],
			render: ([MemberOrder]) => <MemberOrder />,
			onEnter: async ({ store, next }) => {
				console.log('on Enter MemberOrder');
				const children = await next();
				store.dispatch(initializeMemberOrder());
				store.dispatch(storeActivePage('member/order'));
				console.log('on Enter MemberOrder / end');
				return children;
			},
		},
		DetailRoute,
	],
};
export default routes;
