import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';

import { wrapAuthFetch } from 'util/api';
import { pushQueries } from 'models/routing';
import { GetState, State as GlobalState } from '../../reducers';

enum OrderProgress {
	ALL = '',
	UNPAID = 'UNPAID',
	PAID = 'PAID',
	PROCESSING = 'PROCESSING',
	SHIPPING = 'SHIPPING',
	CANCELLED = 'CANCELLED',
}

const setActiveProgress = createAction(
	'SET_ACTIVE_PROGRESS',
	(progress: string) => async (dispatch: Dispatch) => {
		if (!Object.keys(OrderProgress).some(key => key === progress)) {
			const firstProgress = Object.keys(OrderProgress)[0];

			dispatch(pushQueries({ queries: { progress: firstProgress } }));
			return firstProgress;
		}

		return progress;
	},
);

const fetchMemberOrderLists = createAction(
	'FETCH_MEMBER_ORDER_LISTS',
	() => async (_: Dispatch, getState: GetState) => {
		const {
			routing: { queries },
			memberOrder: { progress, perPage },
		} = getState();

		const { status, message, data } = await wrapAuthFetch(
			'member/order',
			{ method: 'GET' },
			{
				status: OrderProgress[progress],
				page: +queries.page || 1,
				per_page: perPage,
			},
		);

		if (status !== 200) {
			throw Error(message);
		}

		return data;
	},
);

export const initializeMemberOrder = createAction(
	'INITIALIZE_MEMBER_ORDER',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			routing: { queries },
		} = getState();

		await dispatch(setActiveProgress(queries.progress));
		dispatch(fetchMemberOrderLists());
	},
);

export const clearMemberOrder = createAction('CLEAR_MEMBER_ORDER');

interface OrderListType {
	id: number;
	order_no: string;
	total: number;
	status: string;
	created_at: string;
}

export interface State {
	orderLists: OrderListType[];
	progress: keyof typeof OrderProgress;
	perPage: number;
	pagination: {
		current_page: number;
		last_page: number;
		total: number;
	};
}

export const initialState: State = {
	orderLists: [],
	progress: (Object.keys(OrderProgress) as Array<keyof typeof OrderProgress>)[0],
	perPage: 10,
	pagination: {
		current_page: 1,
		last_page: 0,
		total: 0,
	},
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	memberOrder: handleActions<State, any>(
		{
			SET_ACTIVE_PROGRESS_FULFILLED: (state, action) => ({
				...state,
				progress: action.payload,
			}),

			FETCH_MEMBER_ORDER_LISTS_FULFILLED: (state, action) => ({
				...state,
				orderLists: action.payload.data,
				pagination: action.payload.meta,
			}),

			CLEAR_MEMBER_ORDER: state => ({
				...state,
				...initialState,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.memberOrder,
});

export const useMemberOrder = () =>
	useRedux(mapHooksToState, {
		setActiveProgress,
		fetchMemberOrderLists,
	});
