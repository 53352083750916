import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';
import { initializeNews } from 'models/news/news';
import DetailRoute from './NewsDetail';

const routes: CustomRoute = {
	path: '/news',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'news' */ './component')],
			render: ([News]) => <News />,
			onEnter: async ({ store, next }) => {
				console.log('on Enter News');
				const children = await next();
				store.dispatch(storeActivePage('news'));
				store.dispatch(initializeNews());
				console.log('on Enter News / end');
				return children;
			},
		},
		DetailRoute,
	],
};

export default routes;
