import React, { useRef, useEffect, ComponentPropsWithoutRef, Ref } from 'react';
import classnames from 'classnames';

import styles from './index.css';

type Props = ComponentPropsWithoutRef<'div'> & {
    animationDependency?: any
}

function Badge(props: Props) {
    const { animationDependency, className, children, ...rest } = props;
    const divRef = useRef<HTMLDivElement>(null!);

    // 依據 dependency 觸發 ref 綁定的 DOM 物件上動畫
    useAnimation(divRef, animationDependency);

    return <div className={classnames(styles.badge, className)} {...rest} ref={divRef}>{children}</div>
}

// custom hook for animation
function useAnimation(ref: Ref<HTMLDivElement>, dependency: any) {
    useEffect(() => {
        const pointBounce = [
            {
                transform: "translate(0, 0)",
            },
            {
                transform: "translate(0, -15px)",
                offset: 0.2
            },
            {
                transform: "translate(0, -15px)",
                offset: 0.23
            },
            {
                transform: "translate(0, 0)",
                offset: 0.4
            },
            {
                transform: "translate(0, -10px)",
                offset: 0.5
            },
            {
                transform: "translate(0, -10px)",
                offset: 0.53
            },
            {
                transform: "translate(0, 0)",
                offset: 0.8
            },
            {
                transform: "translate(0, -4px)",
                offset: 0.9
            },
            {
                transform: "translate(0, 0)"
            }

        ];

        const pointBounceTiming = {
            duration: 700,
            delay: 200,
            iterations: 1,
        };

        (ref as any).current.animate(pointBounce, pointBounceTiming);
    }, [dependency])
}

export default Badge;
