import { createAction, handleActions } from 'redux-actions';
import { Dispatch, AnyAction } from 'redux';

import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

import { State as GlobalState } from './reducers';

interface FaqCategory {
	key: string;
	name: string;
	contents: {
		title: string;
		content: string;
		docName?: string;
		docSrc?: string;
	}[];
}

interface FaqCategoryBackend {
	name: string;
	faqs: {
		title: string;
		content: string;
		doc_name?: string;
		doc?: string;
	}[];
}

export const fetchFaq = createAction(
	'FETCH_FAQ',
	() => async (_: Dispatch<AnyAction>, getState: () => GlobalState) => {
		const {
			i18n: { lan },
		} = getState();

		const { status, message, data } = await wrapFetch(
			'faq/categories',
			{ method: 'GET' },
			{
				lang: lan,
			},
		);

		if (status !== 200) {
			throw Error(message);
		}

		const faqData: { [key: string]: FaqCategory } = {};
		const list = data.map((categoryData: FaqCategoryBackend, index: number) => {
			const key = String(index);

			faqData[key] = {
				key,
				name: categoryData.name,
				contents: categoryData.faqs.map(content => ({
					title: content.title,
					content: content.content,
					docName: content.doc_name,
					docSrc: content.doc,
				})),
			};

			return key;
		});

		return {
			list,
			data: faqData,
		};
	},
);

export interface State {
	list: string[];
	data: {
		[key: string]: FaqCategory;
	};
}

const initialState: State = {
	list: [],
	data: {},
};

export const reducer = {
	faq: handleActions<State, any>(
		{
			FETCH_FAQ_FULFILLED: (state, action) => ({
				...state,
				list: action.payload.list,
				data: action.payload.data,
			}),
		},
		initialState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

const mapHooksToState = (state: GlobalState) => ({
	...state.faq,
});

export const useFaq = () => useRedux(mapHooksToState, { fetchFaq });
