import { storeActivePage } from 'models/common';
import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/order',
	components: () => [import(/* webpackChunkName: 'order' */ './component')],
	render: ([Order]) => <Order />,
	onEnter: async ({ store, next }) => {
		console.log('on Enter Order');
		const children = await next();
		store.dispatch(storeActivePage('order'));
		console.log('on Enter Order / end');
		return children;
	},
};

export default routes;
