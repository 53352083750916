import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import { wrapFetch } from 'util/api';
import { emailValid, haveFullwidth } from 'util/helper';
import { t } from 'util/i18n';
import { openNotification } from 'models/notification';
import { GetState, State as GlobalState } from '../reducers';
import { openModal } from '../modal';

const setForgetEmail = createAction('SET_FORGET_EMAIL', (email: string) => {
	const valid = emailValid(email) && !haveFullwidth(email);
	return { valid, value: email };
});

const Forget = createAction('FORGET', () => async (dispatch: Dispatch, getState: GetState) => {
	const {
		authForget: { email },
	} = getState();

	const { status, message } = await wrapFetch('member/forgotPassword', {
		method: 'POST',
		body: JSON.stringify({
			email: email.value,
		}),
	});

	switch (true) {
		case status === 400: // Email not found or invalid
			dispatch(
				openNotification({
					type: 'error',
					message: t('auth:accountNotExist'),
				}),
			);
			throw new Error(t('auth:accountNotExist'));
		case status !== 200:
			dispatch(
				openNotification({
					type: 'error',
					message,
				}),
			);
			throw new Error(message);
		default:
			dispatch(openModal({ category: 'alert', type: 'forgetEmailSent' }));
	}
});

const setCanResend = createAction('SET_CAN_RESEND', (canResend: boolean) => canResend);

const ResendForget = createAction(
	'RESEND_FORGET',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			authForget: { email },
		} = getState();

		const { status, message } = await wrapFetch('member/forgotPassword', {
			method: 'POST',
			body: JSON.stringify({
				email: email.value,
			}),
		});

		switch (true) {
			case status === 400: // Email not found or invalid
				dispatch(
					openNotification({
						type: 'error',
						message: t('auth:accountNotExist'),
					}),
				);
				throw new Error(t('auth:accountNotExist'));
			case status !== 200:
				dispatch(
					openNotification({
						type: 'error',
						message,
					}),
				);
				throw new Error(message);
			default:
				dispatch(
					openNotification({
						type: 'success',
						message: t('auth:foregetEmailSent.resent'),
					}),
				);
				dispatch(setCanResend(false));
		}
	},
);

export interface State {
	email: {
		value: string;
		valid: boolean;
	};
	canResend: boolean;
	loading: boolean;
	error: boolean;
	message: string;
	success: boolean;
}

const initialState: State = {
	email: {
		valid: false,
		value: '',
	},
	canResend: false,
	loading: false,
	error: false,
	message: '',
	success: false,
};

export const reducer = {
	authForget: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_FORGET_EMAIL: (state, action) => ({
				...state,
				email: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			FORGET_PENDING: state => ({
				...state,
				loading: true,
			}),

			FORGET_FULFILLED: state => ({
				...state,
				loading: false,
				error: false,
				success: true,
			}),

			FORGET_REJECTED: (state, action) => ({
				...state,
				loading: false,
				error: true,
				success: false,
				message: action.payload.message,
			}),

			SET_CAN_RESEND: (state, action) => ({
				...state,
				canResend: action.payload,
			}),

			RESEND_FORGET_PENDING: state => ({
				...state,
				loading: true,
			}),

			RESEND_FORGET_FULFILLED: state => ({
				...state,
				loading: false,
				error: false,
				success: true,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.authForget,
});

export const useAuthForget = () =>
	useRedux(mapHooksToState, { setForgetEmail, Forget, setCanResend, ResendForget });
