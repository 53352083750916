import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { openModal } from 'models/modal';

const routes: CustomRoute = {
	path: '/login',
	components: () => [import(/* webpackChunkName: 'login' */ './component')],
	render: ([Login]) => <Login />,
	onEnter: async ({ store }) => {
		store.dispatch(openModal({ category: 'auth', type: 'login' }));
	},
};
export default routes;
