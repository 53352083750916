import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import { wrapFetch } from 'util/api';
import {
	emailValid,
	haveFullwidth,
	isExist,
	isEmpty,
	passwordValid,
	mobileValid,
	telValid,
} from 'util/helper';
import { isGuiNumberValid } from 'taiwan-id-validator';
import { t } from 'util/i18n';
import { openNotification } from 'models/notification';
import { GetState, State as GlobalState } from '../reducers';
import { openModal } from '../modal';

const setSignupEmail = createAction('SET_SIGNUP_EMAIL', (email: string) => {
	const valid = emailValid(email) && !haveFullwidth(email);
	return { valid, value: email };
});

const setSignupConfirmPassword = createAction(
	'SET_SIGNUP_CONFIRM_PASSWORD',
	(confirmPassword: string) => async (_: Dispatch, getState: GetState) => {
		const {
			authSignup: { password },
		} = getState();

		const valid = confirmPassword === password.value && isExist(confirmPassword);
		return { valid, value: confirmPassword };
	},
);

const setSignupPassword = createAction(
	'SET_SIGNUP_PASSWORD',
	(password: string) => async (dispatch: Dispatch) => {
		dispatch(setSignupConfirmPassword(''));

		const valid = passwordValid(password);
		return { valid, value: password };
	},
);

const CheckEmailExist = createAction(
	'CHECK_EMAIL_EXIST',
	(email: string) => async (dispatch: Dispatch) => {
		const { status, message } = await wrapFetch(`member/${email}/exists`);

		switch (true) {
			case status === 200: // Email not found or invalid
				dispatch(
					openNotification({
						type: 'error',
						message: t('auth:accountExist'),
					}),
				);
				throw new Error(t('auth:accountExist'));
			case status !== 404:
				dispatch(
					openNotification({
						type: 'error',
						message,
					}),
				);
				throw new Error(message);
			default:
				dispatch(openModal({ category: 'auth', type: 'basicInfo' }));
		}
	},
);

const setSignupCompany = createAction('SET_SIGNUP_COMPANY', (company: string) => {
	const valid = isExist(company);
	return { valid, value: company };
});

const setSignupGUI = createAction('SET_SIGNUP_GUI', (GUI: string) => {
	const valid = (isGuiNumberValid(GUI) || isEmpty(GUI));
	return { valid, value: GUI };
});

const setSignupDist = createAction('SET_SIGNUP_DIST', (dist: string) => {
	const valid = isExist(dist);
	return { valid, value: dist };
});

const setSignupCity = createAction(
	'SET_SIGNUP_CITY',
	(city: string) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			authSignup: { city: prevCity },
		} = getState();

		if (city !== prevCity.value) {
			dispatch(setSignupDist(''));
		}

		const valid = isExist(city);
		return { valid, value: city };
	},
);

const setSignupAddress = createAction('SET_SIGNUP_ADDRESS', (address: string) => {
	const valid = isExist(address);
	return { valid, value: address };
});

const setSignupCountry = createAction('SET_SIGNUP_COUNTRY', (country: string) => {
	const valid = isExist(country);
	return { valid, value: country };
});

const setSignupName = createAction('SET_SIGNUP_NAME', (name: string) => {
	const valid = isExist(name);
	return { valid, value: name };
});

const setSignupNumber = createAction('SET_SIGNUP_NUMBER', (number: string) => {
	const valid = (mobileValid(number) || telValid(number)) && !haveFullwidth(number);
	return { valid, value: number };
});

const Signup = createAction('SIGNUP', () => async (dispatch: Dispatch, getState: GetState) => {
	const {
		i18n: { lan },
		authSignup: {
			email,
			password,
			confirmPassword,
			company,
			GUI,
			dist,
			address,
			country,
			name,
			number,
		},
	} = getState();

	/* eslint-disable */
	const body =
		lan === 'zh'
			? {
					email: email.value,
					password: password.value,
					password_confirmation: confirmPassword.value,
					company: company.value,
					tax_id: GUI.value,
					dist_id: dist.value,
					address: address.value,
					contact_name: name.value,
					contact_phone: number.value,
			  }
			: {
					email: email.value,
					password: password.value,
					password_confirmation: confirmPassword.value,
					company: company.value,
					tax_id: GUI.value,
					country: country.value,
					contact_name: name.value,
					contact_phone: number.value,
			  };
	/* eslint-enable */

	const { status, message } = await wrapFetch('member/register', {
		method: 'POST',
		body: JSON.stringify(body),
	});

	if (status !== 200) {
		dispatch(
			openNotification({
				type: 'error',
				message,
			}),
		);
		throw new Error(message);
	}

	dispatch(
		openModal({
			category: 'alert',
			type: 'verifyEmailSent',
		}),
	);
	return {};
});

interface InputType {
	value: string;
	valid: boolean;
}

export interface State {
	email: InputType;
	password: InputType;
	confirmPassword: InputType;
	company: InputType;
	GUI: InputType;
	city: InputType;
	dist: InputType;
	address: InputType;
	country: InputType;
	name: InputType;
	number: InputType;
	loading: boolean;
	error: boolean;
	message: string;
	success: boolean;
}

export const initialState: State = {
	email: {
		value: '',
		valid: false,
	},
	password: {
		value: '',
		valid: false,
	},
	confirmPassword: {
		value: '',
		valid: false,
	},
	company: {
		value: '',
		valid: false,
	},
	GUI: {
		value: '',
		valid: true,
	},
	city: {
		value: '',
		valid: false,
	},
	dist: {
		value: '',
		valid: false,
	},
	address: {
		value: '',
		valid: false,
	},
	country: {
		value: '',
		valid: false,
	},
	name: {
		value: '',
		valid: false,
	},
	number: {
		value: '',
		valid: false,
	},
	loading: false,
	error: false,
	message: '',
	success: false,
};

export const reducer = {
	authSignup: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_SIGNUP_EMAIL: (state, action) => ({
				...state,
				email: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_SIGNUP_CONFIRM_PASSWORD_FULFILLED: (state, action) => ({
				...state,
				confirmPassword: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_SIGNUP_PASSWORD_FULFILLED: (state, action) => ({
				...state,
				password: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			CHECK_EMAIL_EXIST_PENDING: state => ({
				...state,
				loading: true,
			}),

			CHECK_EMAIL_EXIST_FULFILLED: state => ({
				...state,
				loading: false,
				error: false,
			}),

			CHECK_EMAIL_EXIST_REJECTED: (state, action) => ({
				...state,
				loading: false,
				error: true,
				success: false,
				message: action.payload.message,
			}),

			SET_SIGNUP_COMPANY: (state, action) => ({
				...state,
				company: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_SIGNUP_GUI: (state, action) => ({
				...state,
				GUI: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_SIGNUP_CITY_FULFILLED: (state, action) => ({
				...state,
				city: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_SIGNUP_DIST: (state, action) => ({
				...state,
				dist: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_SIGNUP_ADDRESS: (state, action) => ({
				...state,
				address: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_SIGNUP_COUNTRY: (state, action) => ({
				...state,
				country: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_SIGNUP_NAME: (state, action) => ({
				...state,
				name: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_SIGNUP_NUMBER: (state, action) => ({
				...state,
				number: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SIGNUP_PENDING: state => ({
				...state,
				loading: true,
			}),

			SIGNUP_FULFILLED: state => ({
				...state,
				loading: false,
				error: false,
				success: true,
			}),

			SIGNUP_REJECTED: (state, action) => ({
				...state,
				loading: false,
				error: true,
				success: false,
				message: action.payload.message,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.authSignup,
});

export const useAuthSignup = () =>
	useRedux(mapHooksToState, {
		setSignupEmail,
		setSignupPassword,
		setSignupConfirmPassword,
		CheckEmailExist,
		setSignupCompany,
		setSignupGUI,
		setSignupCity,
		setSignupDist,
		setSignupAddress,
		setSignupCountry,
		setSignupName,
		setSignupNumber,
		Signup,
	});
