import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/policies',
	components: () => [import(/* webpackChunkName: 'policies' */ './component')],
	render: ([Policies]) => <Policies />,
};

export default routes;
