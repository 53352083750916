import { createAction, handleActions } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';

import { wrapAuthFetch } from 'util/api';
import { State as GlobalState } from '../../reducers';

export const fetchMemberInquireDetail = createAction(
	'FETCH_MEMBER_INQUIRE_DETAIL',
	(id: string) => async () => {
		const { status, message, data } = await wrapAuthFetch(`member/quotation/${id}`, {
			method: 'GET',
		});

		if (status !== 200) {
			throw Error(message);
		}

		return data;
	},
);

export const initializeMemberInquireDetail = createAction(
	'INITIALIZE_MEMBER_INQUIRE_DETAIL',
	(id: string) => async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fetchMemberInquireDetail(id));
	},
);

export const clearMemberInquireDetail = createAction('CLEAR_MEMBER_INQUIRE_DETAIL');

interface InquireProductType {
	id: number;
	pic: string;
	pic_alt: string;
	product_no: string;
	name: string;
	quantity: number;
}

export interface State {
	id: number;
	inquire_no: string;
	products: InquireProductType[];
	notes: string;
	file: {
		url: string;
		name: string;
	};
}

export const initialState: State = {
	id: 0,
	inquire_no: '',
	products: [],
	notes: '',
	file: {
		url: '',
		name: '',
	},
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	memberInquireDetail: handleActions<State, any>(
		{
			FETCH_MEMBER_INQUIRE_DETAIL_FULFILLED: (state, action) => ({
				...state,
				id: action.payload.id,
				inquire_no: action.payload.quotation_no,
				products: action.payload.products,
				notes: action.payload.memo,
				file: {
					url: action.payload.file,
					name: action.payload.filename,
				},
			}),

			CLEAR_MEMBER_INQUIRE_DETAIL: state => ({
				...state,
				...initialState,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.memberInquireDetail,
});

export const useMemberInquireDetail = () => useRedux(mapHooksToState, {});
