import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { wrapFetchFormData } from 'util/api';
import { emailValid, haveFullwidth, isExist, mobileValid, telValid } from 'util/helper';
import { GetState, State as GlobalState } from './reducers';
import { openNotification } from './notification';

const setContactGender = createAction('SET_CONTACT_GENDER', (gender: string) => {
	const valid = isExist(gender);
	return { valid, gender };
});

const setContactName = createAction('SET_CONTACT_NAME', (name: string) => {
	const valid = isExist(name);
	return { valid, name };
});

const setContactCountry = createAction('SET_CONTACT_COUNTRY', (country: string) => {
	const valid = true;
	return { valid, country };
});

const setContactCompany = createAction('SET_CONTACT_COMPANY', (company: string) => {
	const valid = true;
	return { valid, company };
});

const setContactNumber = createAction('SET_CONTACT_NUMBER', (number: string) => {
	const valid = (mobileValid(number) || telValid(number)) && !haveFullwidth(number);
	return { valid, number };
});

const setContactEmail = createAction('SET_CONTACT_EMAIL', (email: string) => {
	const valid = emailValid(email) && !haveFullwidth(email);
	return { valid, email };
});

const setContactContent = createAction('SET_CONTACT_CONTENT', (content: string) => {
	const valid = isExist(content);
	return { valid, content };
});

const setContactFile = createAction('SET_CONTACT_FILE', (file: File | '') => {
	const VALID_TYPE = ['image/jpeg', 'application/pdf'];
	const valid =
		(file instanceof File &&
			file.size > 0 &&
			file.size < 1024 * 1024 * 3 &&
			VALID_TYPE.includes(file.type)) ||
		file === '';
	return { valid, file };
});

const fetchContactForm = createAction(
	'FETCH_CONTACT_FORM',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			contact: { gender, name, country, company, number, email, content, file },
		} = getState();

		interface BodyType {
			gender: string;
			name: string;
			country: string;
			company: string;
			number: string;
			email: string;
			content: string;
			file: string | File;
		}

		const body = new FormData();
		const bodyData: BodyType = {
			gender: gender.gender,
			name: name.name,
			country: country.country,
			company: company.company,
			number: number.number,
			email: email.email,
			content: content.content,
			file: file.file,
		};

		console.log('form data: ', bodyData);

		Object.keys(bodyData).forEach(key => {
			body.append(key, bodyData[key as keyof BodyType]);
		});

		const { status, message, data } = await wrapFetchFormData('contact', {
			method: 'POST',
			body,
		});

		if (status !== 200) {
			dispatch(
				openNotification({
					type: 'error',
					message: `送出失敗。\n請嘗試重新填寫表單，或直接與我們電話聯繫，謝謝！`,
				}),
			);
			throw new Error(message);
		}

		dispatch(
			openNotification({
				type: 'success',
				message: `送出成功！\n感謝您的填寫，我們將盡快與您聯繫。`,
			}),
		);

		return data;
	},
);

export interface State {
	gender: { valid: boolean; gender: string };
	name: { valid: boolean; name: string };
	country: { valid: boolean; country: string };
	company: { valid: boolean; company: string };
	number: { valid: boolean; number: string };
	email: { valid: boolean; email: string };
	content: { valid: boolean; content: string };
	file: { valid: boolean; file: File | string };
	loading: boolean;
	message: string;
	fetchResult: { id?: number } | null;
}

const initialState: State = {
	gender: { valid: false, gender: '' },
	name: { valid: false, name: '' },
	country: { valid: true, country: '' },
	company: { valid: true, company: '' },
	number: { valid: false, number: '' },
	email: { valid: false, email: '' },
	content: { valid: false, content: '' },
	file: { valid: true, file: '' },
	loading: false,
	message: '',
	fetchResult: null,
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	contact: handleActions<State, any>(
		{
			SET_CONTACT_GENDER: (state, action) => ({
				...state,
				gender: {
					valid: action.payload.valid,
					gender: action.payload.gender,
				},
			}),
			SET_CONTACT_NAME: (state, action) => ({
				...state,
				name: {
					valid: action.payload.valid,
					name: action.payload.name,
				},
			}),
			SET_CONTACT_COUNTRY: (state, action) => ({
				...state,
				country: {
					valid: action.payload.valid,
					country: action.payload.country,
				},
			}),
			SET_CONTACT_COMPANY: (state, action) => ({
				...state,
				company: {
					valid: action.payload.valid,
					company: action.payload.company,
				},
			}),
			SET_CONTACT_NUMBER: (state, action) => ({
				...state,
				number: {
					valid: action.payload.valid,
					number: action.payload.number,
				},
			}),
			SET_CONTACT_EMAIL: (state, action) => ({
				...state,
				email: {
					valid: action.payload.valid,
					email: action.payload.email,
				},
			}),
			SET_CONTACT_CONTENT: (state, action) => ({
				...state,
				content: {
					valid: action.payload.valid,
					content: action.payload.content,
				},
			}),
			SET_CONTACT_FILE: (state, action) => ({
				...state,
				file: {
					valid: action.payload.valid,
					file: action.payload.file,
				},
			}),
			FETCH_CONTACT_FORM_PENDING: state => ({
				...state,
				loading: true,
			}),
			FETCH_CONTACT_FORM_REJECTED: (state, action) => ({
				...state,
				loading: false,
				message: action.payload.message,
			}),
			FETCH_CONTACT_FORM_FULFILLED: (state, action) => ({
				...state,
				...initialState,
				fetchResult: action.payload,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.contact,
});

export const useContact = () =>
	useRedux(mapHooksToState, {
		setContactGender,
		setContactName,
		setContactCountry,
		setContactCompany,
		setContactNumber,
		setContactEmail,
		setContactContent,
		setContactFile,
		fetchContactForm,
	});
