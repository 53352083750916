import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import { wrapFetch } from 'util/api';
import { emailValid, haveFullwidth, passwordValid } from 'util/helper';
import { t } from 'util/i18n';
import { openNotification } from 'models/notification';
import { fetchAccount } from 'models/member/account';
import { GetState, State as GlobalState } from '../reducers';
import { openModal, closeModal } from '../modal';
import { updateAccessToken } from './common';

const setLoginEmail = createAction('SET_LOGIN_EMAIL', (email: string) => {
	const valid = emailValid(email) && !haveFullwidth(email);
	return { valid, value: email };
});

const setLoginPassword = createAction('SET_LOGIN_PASSWORD', (password: string) => {
	const valid = passwordValid(password);
	return { valid, value: password };
});

const setCheckedEmail = createAction('SET_CHECKED_EMAIL', (checked: boolean) => checked);

const CheckEmailExist = createAction(
	'CHECK_EMAIL_EXIST',
	(email: string) => async (dispatch: Dispatch) => {
		const { status, message } = await wrapFetch(`member/${email}/exists`);

		switch (true) {
			case status === 404: // Email not found or invalid
				dispatch(
					openNotification({
						type: 'error',
						message: t('auth:accountNotExist'),
					}),
				);
				throw new Error(t('auth:accountExist'));
			case status !== 200:
				dispatch(
					openNotification({
						type: 'error',
						message,
					}),
				);
				throw new Error(message);
			default:
				dispatch(setCheckedEmail(true));
		}
	},
);

const Login = createAction('LOGIN', () => async (dispatch: Dispatch, getState: GetState) => {
	const {
		authLogin: { email, password },
	} = getState();

	const { status, message, data } = await wrapFetch('member/login', {
		method: 'POST',
		body: JSON.stringify({
			email: email.value,
			password: password.value,
		}),
	});

	switch (true) {
		case status === 400:
			dispatch(
				openNotification({
					type: 'error',
					message: t('auth:invalidPassword'),
				}),
			);
			throw new Error(t('auth:invalidPassword')); // Invalid email or password
		case status === 403:
			dispatch(openModal({ category: 'alert', type: 'unverifiedAccount' })); // Account not verified
			throw new Error(message);
		case status !== 200:
			dispatch(
				openNotification({
					type: 'error',
					message,
				}),
			);
			throw new Error(message);
		default:
			dispatch(
				openNotification({
					type: 'success',
					message: t('auth:loginSuccess'),
				}),
			);
			dispatch(updateAccessToken(data?.token));
			dispatch(fetchAccount());
			dispatch(closeModal({ category: 'auth' }));
			dispatch(closeModal({ category: 'alert' }));
	}
});

interface InputType {
	value: string;
	valid: boolean;
}

export interface State {
	email: InputType;
	password: InputType;
	checkedEmail: boolean;
	emailExist: boolean;
	loading: boolean;
	error: boolean;
	message: string;
	success: boolean;
}

const initialState: State = {
	email: {
		value: '',
		valid: false,
	},
	password: {
		value: '',
		valid: false,
	},
	checkedEmail: false,
	emailExist: false,
	loading: false,
	error: false,
	message: '',
	success: false,
};

export const reducer = {
	authLogin: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_LOGIN_EMAIL: (state, action) => ({
				...state,
				email: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
				checkedEmail: false,
			}),

			SET_LOGIN_PASSWORD: (state, action) => ({
				...state,
				password: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_CHECKED_EMAIL: (state, action) => ({
				...state,
				checkedEmail: action.payload,
			}),

			CHECK_EMAIL_EXIST_PENDING: state => ({
				...state,
				loading: true,
			}),

			CHECK_EMAIL_EXIST_FULFILLED: state => ({
				...state,
				loading: false,
				error: false,
				emailExist: false,
			}),

			CHECK_EMAIL_EXIST_REJECTED: (state, action) => ({
				...state,
				loading: false,
				error: true,
				message: action.payload.message,
				emailExist: true,
			}),

			LOGIN_PENDING: state => ({
				...state,
				loading: true,
			}),

			LOGIN_FULFILLED: state => ({
				...state,
				loading: false,
				error: false,
				success: true,
			}),

			LOGIN_REJECTED: (state, action) => ({
				...state,
				loading: false,
				error: true,
				success: false,
				message: action.payload.message,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.authLogin,
});

export const useAuthLogin = () =>
	useRedux(mapHooksToState, {
		setLoginEmail,
		setLoginPassword,
		setCheckedEmail,
		CheckEmailExist,
		Login,
	});
