import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';
import { initializeCart } from 'models/cart/list';
import ResultRoute from './Result';

const routes: CustomRoute = {
	path: '/cart',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'cart' */ './component')],
			render: ([Cart]) => <Cart />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter Cart');
				const children = await next();
				store.dispatch(initializeCart());
				store.dispatch(storeActivePage('cart'));
				console.log('on Enter Cart / end');
				return children;
			},
		},
		ResultRoute,
	],
};
export default routes;
