import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useRouting } from 'models/routing';
import { useCommon } from 'models/common';

interface SEOProperty {
	title?: string;
	description?: string;
	image?: string;
	jsonLd?: string;
}

const SEO: React.FC<SEOProperty> = ({ title = '', description = '', image = '', jsonLd = '' }) => {
	const [{ pathname, search }] = useRouting();
	const { SELF_HOST_ENDPOINT } = process.env;

	const [{ metaData }] = useCommon();

	const meta = {
		title: title || metaData.meta_title,
		description: description || metaData.meta_description,
		image: image === '' ? metaData.meta_image : image,
		url: `${SELF_HOST_ENDPOINT}${pathname}${search}`,
		jsonLd: jsonLd || metaData.json_ld,
	};

	return (
		<>
			<Helmet title={meta.title}>
				<meta name="description" content={meta.description} />
				<meta name="image" content={meta.image} />
				<meta property="og:url" content={meta.url} />
				<meta property="og:title" content={meta.title} />
				<meta property="og:description" content={meta.description} />
				<meta property="og:image" content={meta.image} />
				<meta property="og:image:secure_url" content={meta.image} />
				<meta property="og:image:width" content="600" />
				<meta property="og:image:height" content="315" />
				<meta property="og:site_name" content="YJ Stainless Co, LTD.正言" />
				<script type="application/ld+json">{`${meta.jsonLd}`}</script>
			</Helmet>
		</>
	);
};

export default SEO;
