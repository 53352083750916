import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';

import { wrapAuthFetch } from 'util/api';
import { State as GlobalState } from '../../reducers';

export const fetchMemberOrderDetail = createAction(
	'FETCH_MEMBER_ORDER_DETAIL',
	(id: string) => async () => {
		const { status, message, data } = await wrapAuthFetch(`member/order/${id}`, {
			method: 'GET',
		});

		if (status !== 200) {
			throw Error(message);
		}

		return data;
	},
);

export const initializeMemberOrderDetail = createAction(
	'INITIALIZE_MEMBER_ORDER_DETAIL',
	(id: string) => async (dispatch: Dispatch) => {
		dispatch(fetchMemberOrderDetail(id));
	},
);

export const clearMemberOrderDetail = createAction('CLEAR_MEMBER_ORDER_DETAIL');

interface OrderProductType {
	id: number;
	pic: string;
	pic_alt: string;
	product_no: string;
	name: string;
	quantity: number;
	price_total: string;
}

export interface State {
	id: number;
	orderNo: string;
	detail: {
		products: OrderProductType[];
		amount: number;
		discount: number;
		deliveryFee: number;
		total: number;
	};
	payment: string;
	payment_date: string;
	receipt: {
		type: string;
		GUI: string;
		heading: string;
	};
	recipient: {
		name: string;
		number: string;
		email: string;
		city: string;
		dist: string;
		address: string;
	};
}

export const initialState: State = {
	id: 0,
	orderNo: '',
	detail: {
		products: [],
		amount: 0,
		discount: 0,
		deliveryFee: 0,
		total: 0,
	},
	payment: '',
	payment_date: '',
	receipt: {
		type: '',
		GUI: '',
		heading: '',
	},
	recipient: {
		name: '',
		number: '',
		email: '',
		city: '',
		dist: '',
		address: '',
	},
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	memberOrderDetail: handleActions<State, any>(
		{
			FETCH_MEMBER_ORDER_DETAIL_FULFILLED: (state, action) => ({
				...state,
				id: action.payload.id,
				orderNo: action.payload.order_no,
				detail: {
					products: action.payload.products,
					amount: action.payload.subtotal,
					discount: action.payload.promo_free,
					deliveryFee: action.payload.shipping_fee,
					total: action.payload.total,
				},
				payment: action.payload.payment_type,
				payment_date: action.payload.paid_at,
				receipt: {
					type: action.payload.invoice.type,
					GUI: action.payload.invoice.tax_id,
					heading: action.payload.invoice.title,
				},
				recipient: {
					name: action.payload.addressee.name,
					number: action.payload.addressee.number,
					email: action.payload.addressee.email,
					city: action.payload.addressee.city,
					dist: action.payload.addressee.dist,
					address: action.payload.addressee.address,
				},
			}),

			CLEAR_MEMBER_ORDER_DETAIL: state => ({
				...state,
				...initialState,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.memberOrderDetail,
});

export const useMemberOrderDetail = () => useRedux(mapHooksToState, {});
