import React from 'react';
import classnames from 'classnames';
import { useRouting } from 'models/routing';
import { useLanguage } from 'models/i18n';
import { changeLng } from 'util/i18n';
import GlobeIcon from 'images/common/globe.inline.svg';
import { useAuth } from 'models/auth/common';
import Icon from '../Icon';
import styles from './index.css';

interface ButtonLanguageProperty {
	className?: string;
}

const ButtonLanguage: React.FC<ButtonLanguageProperty> = ({ className }) => {
	const [
		{
			i18n: { lan: prevLan },
		},
	] = useLanguage();

	const [{ pathname, search }, { historyPush }] = useRouting();

	const [, { logoutWithoutRedirect }] = useAuth();

	const onClickHandler = (nextLan = '') => () => {
		const regex = new RegExp(`/${prevLan}`, 'g');
		const pathnameWithoutLan = pathname.replace(regex, '');
		// 切換成英文時要登出
		if (nextLan === 'en') {
			logoutWithoutRedirect();
		}
		changeLng(nextLan);
		historyPush({ pathname: pathnameWithoutLan, lan: nextLan, search });
		window.location.reload();
	};

	return (
		<button
			onClick={onClickHandler(prevLan === 'zh' ? 'en' : 'zh')}
			className={classnames(styles.buttonLanguage, className)}
			type="button"
		>
			<Icon className={styles.icon} src={GlobeIcon} />
			&nbsp;
			{prevLan === 'zh' ? 'EN' : 'ZH'}
		</button>
	);
};

export default ButtonLanguage;
