import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';
import { initializeCompare } from 'models/compare';

const routes: CustomRoute = {
	path: '/compare',
	components: () => [import(/* webpackChunkName: 'compare' */ './component')],
	render: ([Compare]) => <Compare />,
	onEnter: async ({ store, next }) => {
		console.log('on Enter Compare');
		const children = await next();
		store.dispatch(initializeCompare());
		store.dispatch(storeActivePage('compare'));
		console.log('on Enter Compare / end');
		return children;
	},
};

export default routes;
