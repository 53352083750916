import { createAction, handleActions } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import storage from 'util/storage';
import { wrapAuthFetchFormData, wrapLangFetch } from 'util/api';
import { emailValid, haveFullwidth, isExist, mobileValid, telValid } from 'util/helper';
import { t } from 'util/i18n';
import { openNotification } from 'models/notification';
import { historyPush } from 'models/routing';
import { GetState, State as GlobalState } from './reducers';

interface IdListProperty {
	id: number;
	quantity: number;
}

interface ApiInquireListProperty {
	id: number;
	pic: string;
	pic_alt: string;
	name: string;
	product_no: string;
}

export interface InquireListProperty extends ApiInquireListProperty {
	quantity: number;
}

export const checkItemInInquire = (id: number, list: IdListProperty[]) =>
	list && list.some(({ id: listId }) => listId === id);

export const setInquireItemList = createAction(
	'SET_INQUIRE_ITEM_LIST',
	(list: (IdListProperty | InquireListProperty)[]) => {
		storage.setItem('inquire-list', JSON.stringify(list));

		const totalQuantity = list.reduce((total, { quantity }) => total + quantity, 0);

		return { list, totalQuantity };
	},
);

const addQuantityToList = createAction(
	'ADD_QUANTITY_TO_LIST',
	(apiList: ApiInquireListProperty[]) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			inquire: { list },
		} = getState();

		const newItemList = apiList.map(item => {
			const matched = list.find(({ id }) => id === item.id);

			if (matched) {
				return { ...item, quantity: matched.quantity };
			}

			return { ...item, quantity: 1 };
		});

		dispatch(setInquireItemList(newItemList));

		return newItemList;
	},
);

const fetchInquireList = createAction(
	'FETCH_INQUIRE_LIST',
	(list: (IdListProperty | InquireListProperty)[]) => async (dispatch: Dispatch) => {
		const idList = list.map(({ id }) => id);
		const { status, message, data } = await wrapLangFetch('product/cart/check', {
			method: 'POST',
			body: JSON.stringify({
				ids: idList,
			}),
		});

		if (status !== 200) {
			throw new Error(message);
		}

		dispatch(addQuantityToList(data));
	},
);

const setQuantity = createAction(
	'SET_QUANTITY',
	(quantity: number, id: number) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			inquire: { list },
		} = getState();

		const exited = list.some(({ id: itemId }) => itemId === id);

		const newItemList = list.map(item => {
			if (item.id === id) {
				return { ...item, quantity };
			}

			return item;
		});

		dispatch(setInquireItemList(exited ? newItemList : [...list, { id, quantity }]));
	},
);

const incrementQuantity = createAction(
	'INCREMENT_QUANTITY',
	({ id, quantity = 1 }: { id: number; quantity?: number }) => async (
		dispatch: Dispatch,
		getState: GetState,
	) => {
		const {
			inquire: { list },
		} = getState();

		const exited = list.some(({ id: itemId }) => itemId === id);

		const newItemList = list.map(item => {
			if (item.id === id) {
				return { ...item, quantity: item.quantity + quantity };
			}

			return item;
		});

		dispatch(setInquireItemList(exited ? newItemList : [...list, { id, quantity }]));
	},
);

const decrementQuantity = createAction(
	'DECREMENT_QUANTITY',
	({ id, quantity = 1 }: { id: number; quantity?: number }) => async (
		dispatch: Dispatch,
		getState: GetState,
	) => {
		const {
			inquire: { list },
		} = getState();

		const newItemList = list.map(item => {
			if (item.id === id) {
				return { ...item, quantity: item.quantity - quantity };
			}

			return item;
		});

		dispatch(setInquireItemList(newItemList));
	},
);

const deleteItem = createAction(
	'DELETE_ITEM',
	(id: number) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			inquire: { list },
		} = getState();

		const newItemList = list.filter(item => item.id !== id);

		dispatch(setInquireItemList(newItemList));
	},
);

export const clearInquire = createAction('CLEAR_INQUIRE', () => async (dispatch: Dispatch) => {
	dispatch(setInquireItemList([]));
});

export const initializeInquire = createAction(
	'INITIALIZE_INQUIRE',
	() => async (dispatch: Dispatch<AnyAction>, getState: GetState) => {
		const {
			inquire: { list },
		} = getState();

		if (list.length === 0) return;
		dispatch(fetchInquireList(list));
	},
);

const setInquireNotes = createAction('SET_INQUIRE_NOTES', (notes: string) => {
	const valid = true;
	return { valid, value: notes };
});

const setInquireFile = createAction('SET_INQUIRE_FILE', (file: File | '') => {
	const VALID_TYPE = ['image/jpeg', 'application/pdf'];
	const valid =
		(file instanceof File &&
			file.size > 0 &&
			file.size < 1024 * 1024 * 3 &&
			VALID_TYPE.includes(file.type)) ||
		file === '';
	return { valid, value: file };
});

const setInquireName = createAction('SET_INQUIRE_NAME', (name: string) => {
	const valid = isExist(name);
	return { valid, value: name };
});

const setInquireGender = createAction('SET_INQUIRE_GENDER', (gender: string) => {
	const valid = isExist(gender);
	return { valid, value: gender };
});

const setInquireNumber = createAction('SET_INQUIRE_NUMBER', (number: string) => {
	const valid = (mobileValid(number) || telValid(number)) && !haveFullwidth(number);
	return { valid, value: number };
});

const setInquireCompany = createAction('SET_INQUIRE_COMPANY', (company: string) => {
	const valid = true;
	return { valid, value: company };
});

const setInquireEmail = createAction('SET_INQUIRE_EMAIL', (email: string) => {
	const valid = emailValid(email) && !haveFullwidth(email);
	return { valid, value: email };
});

const setInquireCountry = createAction('SET_INQUIRE_COUNTRY', (country: string) => {
	const valid = true;
	return { valid, value: country };
});

const sendInquireForm = createAction(
	'SEND_INQUIRE_FORM',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			auth: { isLogin },
			inquire: { list, notes, file, name, gender, number, company, email, country },
		} = getState();

		interface BodyType {
			products: string;
			memo: string;
			file: string | File;
			visitor_name?: string;
			visitor_gender?: string;
			visitor_number?: string;
			visitor_company?: string;
			visitor_email?: string;
			visitor_country?: string;
		}

		const body = new FormData();
		/* eslint-disable */
		const bodyData: BodyType = isLogin
			? {
					products: JSON.stringify(
						list.map(({ id, quantity }) => ({
							id,
							quantity,
						})),
					),
					memo: notes.value,
					file: file.value,
			  }
			: {
					products: JSON.stringify(
						list.map(({ id, quantity }) => ({
							id,
							quantity,
						})),
					),
					memo: notes.value,
					file: file.value,
					visitor_name: name.value,
					visitor_gender: gender.value,
					visitor_number: number.value,
					visitor_company: company.value,
					visitor_email: email.value,
					visitor_country: country.value,
			  };
		/* eslint-enable */

		console.log('form data: ', bodyData);

		Object.keys(bodyData).forEach(key => {
			body.append(key, bodyData[key as keyof BodyType] || '');
		});
		const { status, message, data } = await wrapAuthFetchFormData('quotation', {
			method: 'POST',
			body,
		});
		if (status !== 200) {
			dispatch(
				openNotification({
					type: 'error',
					message: t('inquire:sendFailed'),
				}),
			);
			throw new Error(message);
		}
		dispatch(clearInquire());
		dispatch(historyPush({ pathname: '/inquire/result' }));
		return data;
	},
);
interface InputType {
	value: string;
	valid: boolean;
}

type Inputs = 'notes' | 'name' | 'gender' | 'number' | 'company' | 'email' | 'country';

export type State = {
	inquireTotalQuantity: number;
	list: InquireListProperty[];
	file: { valid: boolean; value: File | string };
	loading: boolean;
	message: string;
	sendResult: {
		inquire_no: string;
		products: InquireListProperty[];
		notes: string;
		file: {
			name: string;
			url: string;
		};
	};
} & Record<Inputs, InputType>;

export const initialState: State = {
	inquireTotalQuantity: 0,
	list: [],
	notes: { valid: true, value: '' },
	file: { valid: true, value: '' },
	name: { valid: false, value: '' },
	gender: { valid: false, value: '' },
	number: { valid: false, value: '' },
	company: { valid: true, value: '' },
	email: { valid: false, value: '' },
	country: { valid: true, value: '' },
	loading: false,
	message: '',
	sendResult: {
		inquire_no: '',
		products: [],
		notes: '',
		file: {
			name: '',
			url: '',
		},
	},
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	inquire: handleActions<State, any>(
		{
			SET_INQUIRE_ITEM_LIST: (state, action) => ({
				...state,
				list: action.payload.list,
				inquireTotalQuantity: action.payload.totalQuantity,
			}),

			SET_INQUIRE_NOTES: (state, action) => ({
				...state,
				notes: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_INQUIRE_FILE: (state, action) => ({
				...state,
				file: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_INQUIRE_NAME: (state, action) => ({
				...state,
				name: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_INQUIRE_GENDER: (state, action) => ({
				...state,
				gender: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_INQUIRE_NUMBER: (state, action) => ({
				...state,
				number: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_INQUIRE_COMPANY: (state, action) => ({
				...state,
				company: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_INQUIRE_EMAIL: (state, action) => ({
				...state,
				email: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SET_INQUIRE_COUNTRY: (state, action) => ({
				...state,
				country: {
					valid: action.payload.valid,
					value: action.payload.value,
				},
			}),

			SEND_INQUIRE_FORM_PENDING: state => ({
				...state,
				loading: true,
			}),

			SEND_INQUIRE_FORM_REJECTED: (state, action) => ({
				...state,
				loading: false,
				message: action.payload.message,
			}),

			SEND_INQUIRE_FORM_FULFILLED: (state, action) => ({
				...state,
				...initialState,
				sendResult: {
					inquire_no: action.payload.quotation_no,
					products: action.payload.products,
					notes: action.payload.memo,
					file: {
						name: action.payload.filename,
						url: action.payload.file,
					},
				},
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.inquire,
});

export const useInquire = () =>
	useRedux(mapHooksToState, {
		setQuantity,
		incrementQuantity,
		decrementQuantity,
		deleteItem,
		setInquireNotes,
		setInquireFile,
		setInquireName,
		setInquireGender,
		setInquireNumber,
		setInquireCompany,
		setInquireEmail,
		setInquireCountry,
		sendInquireForm,
	});
