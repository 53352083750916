import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { openModal } from 'models/modal';

const routes: CustomRoute = {
	path: '/reset',
	components: () => [import(/* webpackChunkName: 'reset' */ './component')],
	render: ([ResetPassword]) => <ResetPassword />,
	onEnter: async ({ store }) => {
		store.dispatch(openModal({ category: 'auth', type: 'reset' }));
	},
};
export default routes;
