import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';

const routes: CustomRoute = {
	path: '/contact',
	components: () => [import(/* webpackChunkName: 'contact' */ './component')],
	render: ([Contact]) => <Contact />,
	onEnter: async ({ store, next }) => {
		console.log('on Enter Contact');
		const children = await next();
		store.dispatch(storeActivePage('contact'));
		console.log('on Enter Contact / end');
		return children;
	},
};

export default routes;
