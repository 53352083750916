import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';
import storage from 'util/storage';
import { useRedux } from 'util/hook/redux';
import { isExist } from 'util/helper';
import { clearMemberOrder } from 'models/member/order/list';
import { clearMemberOrderDetail } from 'models/member/order/detail';
import { clearMemberInquire } from 'models/member/inquire/list';
import { clearMemberInquireDetail } from 'models/member/inquire/detail';
import { clearMemberAccount } from '../member/account';
import { historyPush } from '../routing';
import { State as GlobalState } from '../reducers';

export const updateAccessToken = createAction('UPDATE_ACCESS_TOKEN', (token: string) => {
	storage.setItem('token', JSON.stringify(token));

	return token;
});

const clearTokenData = createAction('CLEAR_TOKEN_DATA');

export const logout = createAction('LOGOUT', () => async (dispatch: Dispatch) => {
	storage.removeItem('token');

	await Promise.all([
		dispatch(clearTokenData()),
		dispatch(clearMemberAccount()),
		dispatch(clearMemberInquire()),
		dispatch(clearMemberInquireDetail()),
		dispatch(clearMemberOrder()),
		dispatch(clearMemberOrderDetail()),
	]);

	dispatch(historyPush({ pathname: '/' }));
});

export const logoutWithoutRedirect = createAction(
	'LOGOUT_WITHOUT_REDIRECT',
	() => async (dispatch: Dispatch) => {
		storage.removeItem('token');

		await Promise.all([
			dispatch(clearTokenData()),
			dispatch(clearMemberAccount()),
			dispatch(clearMemberInquire()),
			dispatch(clearMemberInquireDetail()),
			dispatch(clearMemberOrder()),
			dispatch(clearMemberOrderDetail()),
		]);
	},
);

export interface State {
	token: string;
	isLogin: boolean;
}

export const initialState: State = {
	token: '',
	isLogin: false,
};

export const reducer = {
	auth: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			UPDATE_ACCESS_TOKEN: (state, action) => ({
				...state,
				token: action.payload,
				isLogin: isExist(action.payload),
			}),

			CLEAR_TOKEN_DATA: state => ({
				...state,
				...initialState,
			}),
		},
		initialState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	...state.auth,
});

export const useAuth = () =>
	useRedux(mapHooksToState, {
		logout,
		logoutWithoutRedirect,
	});
