import { createAction, handleActions } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { wrapLangFetch } from 'util/api';
import { GetState, State as GlobalState } from '../reducers';

interface ShopBanner {
	id: number;
	pic: string;
}

interface CategoryType {
	id: number;
	name: string;
}

interface ProductType {
	id: number;
	pic: string;
	pic_alt: string;
	product_no: string;
	name: string;
	price: number;
	is_recommend: 'Y' | 'N';
	is_new: 'Y' | 'N';
	inventory: number;
}

export const setShopPerPage = createAction('SET_SHOP_PER_PAGE', (perPage: number) => perPage);

const fetchShopBanner = createAction('FETCH_SHOP_BANNER', () => async () => {
	const { status, message, data } = await wrapLangFetch('shop/banner', { method: 'GET' });

	if (status !== 200) {
		throw Error(message);
	}

	return data;
});

const fetchShopCategory = createAction('FETCH_SHOP_CATEGORY', () => async () => {
	const { status, message, data } = await wrapLangFetch('shop/productCategory', { method: 'GET' });

	if (status !== 200) {
		throw Error(message);
	}

	return data;
});

export const fetchShopList = createAction(
	'FETCH_SHOP_LIST',
	() => async (_: Dispatch, getState: GetState) => {
		const {
			routing: { queries },
			shop: { shopPerPage },
		} = getState();

		const { status, message, data } = await wrapLangFetch(
			'shop/product',
			{ method: 'GET' },
			{
				product_category_id: queries.category || '',
				page: +queries.page || 1,
				per_page: shopPerPage,
			},
		);

		if (status !== 200) {
			throw Error(message);
		}

		return data;
	},
);

export const initializeShop = createAction(
	'INITIALIZE_SHOP',
	() => async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fetchShopBanner());
		dispatch(fetchShopCategory());
		dispatch(fetchShopList());
	},
);

export interface State {
	meta: {
		title: string;
		description: string;
		json_ld: string;
	};
	banners: {
		desktop: ShopBanner[];
		mobile: ShopBanner[];
	};
	categories: CategoryType[];
	category: {
		id: number;
		name: string;
	};
	shopPerPage: number;
	products: ProductType[];
	pagination: {
		current_page: number;
		last_page: number;
		total: number;
	};
}

const initialState: State = {
	meta: {
		title: '',
		description: '',
		json_ld: '',
	},
	banners: {
		desktop: [],
		mobile: [],
	},
	categories: [],
	category: {
		id: 0,
		name: '',
	},
	shopPerPage: window.innerWidth >= 1280 ? 9 : 12,
	products: [],
	pagination: {
		current_page: 3,
		last_page: 0,
		total: 0,
	},
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	shop: handleActions<State, any>(
		{
			FETCH_SHOP_BANNER_FULFILLED: (state, action) => ({
				...state,
				banners: {
					desktop: action.payload.pc,
					mobile: action.payload.mobile,
				},
			}),
			FETCH_SHOP_CATEGORY_FULFILLED: (state, action) => ({
				...state,
				categories: action.payload,
			}),
			FETCH_SHOP_LIST_FULFILLED: (state, action) => ({
				...state,
				meta: {
					title: action.payload.productCategory?.meta_title,
					description: action.payload.productCategory?.meta_description,
					json_ld: action.payload.productCategory?.json_ld,
				},
				category: {
					id: action.payload.productCategory?.id,
					name: action.payload.productCategory?.name,
				},
				products: action.payload.products.data,
				pagination: action.payload.products.meta,
			}),
			SET_SHOP_PER_PAGE: (state, action) => ({
				...state,
				shopPerPage: action.payload,
			}),
		},
		initialState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

const mapHooksToState = (state: GlobalState) => ({
	...state.shop,
});

export const useShop = () =>
	useRedux(mapHooksToState, {
		setShopPerPage,
		fetchShopList,
	});
