import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { wrapFetch, wrapLangFetch } from 'util/api';
import storage from 'util/storage';
import isEmpty from 'lodash/isEmpty';
import { GetState, State as GlobalState } from './reducers';
import { setCompareIdList } from './compare';
import { setCartItemList } from './cart/list';
import { logout, updateAccessToken } from './auth/common';
import { setAllMemberInputFromAPI } from './member/account';
import { pushQueries } from './routing';
import { setInquireItemList } from './inquire';

interface MetaType {
	meta_title: string;
	meta_description: string;
	json_ld: string;
	meta_image: string;
}

interface CategoryType {
	id: number;
	name: string;
	icon: string;
}

interface NewsType {
	id: number;
	name: string;
}

interface ArticleType {
	id: number;
	name: string;
}

export interface ProductHot {
	id: number;
	product_no: string;
	price: number;
	name: string;
	pic: string;
	pic_alt: string;
}

interface CityType {
	id: number;
	name: string;
}

export const storeActivePage = createAction('STORE_ACTIVE_PAGE', (page: string) => page);

const getLocalCompareList = createAction(
	'GET_LOCAL_COMPARE_LIST',
	() => async (dispatch: Dispatch) => {
		const idList = storage.getItem('compare-list')
			? storage.getItem('compare-list').split(',')
			: [];

		if (!idList.length) return;
		dispatch(setCompareIdList(idList.map(Number)));
	},
);

const getLocalCartList = createAction('GET_LOCAL_CART_LIST', () => async (dispatch: Dispatch) => {
	const itemList = JSON.parse(storage.getItem('cart-list')) || [];

	if (!itemList.length) return;

	dispatch(setCartItemList(itemList));
});

const getLocalInquireList = createAction(
	'GET_LOCAL_INQUIRE_LIST',
	() => async (dispatch: Dispatch) => {
		const itemList = JSON.parse(storage.getItem('inquire-list')) || [];

		if (!itemList.length) return;

		dispatch(setInquireItemList(itemList));
	},
);

const checkAccount = createAction(
	'CHECK_ACCOUNT',
	(token: string) => async (dispatch: Dispatch) => {
		const { status, message, data } = await wrapLangFetch('member/profile', {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		switch (true) {
			case status === 401: // invalid token
				dispatch(logout());
				console.log('登入時間過長，請重新登入');
				return;
			case status !== 200:
				throw new Error(message);
			default:
				await dispatch(updateAccessToken(token));
				dispatch(setAllMemberInputFromAPI(data));
		}
	},
);

const getAccountToken = createAction(
	'GET_ACCOUNT_TOKEN',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			routing: { queries },
		} = getState();
		const token = queries.token || JSON.parse(storage.getItem('token'));

		if (isEmpty(token)) {
			console.log('no access token');
			return;
		}

		dispatch(pushQueries({ queries: { token: undefined } }));
		await dispatch(checkAccount(token));
	},
);

export const fetchMeta = createAction('FETCH_META', (queryUrl: string) => async () => {
	const { status, message, data } = await wrapLangFetch(
		'seo',
		{ method: 'GET' },
		{ url: queryUrl || '' },
	);

	if (status !== 200) {
		throw Error(message);
	}

	return data;
});

const fetchCategory = createAction('FETCH_CATEGORY', () => async () => {
	const { status, message, data } = await wrapLangFetch('productCategory', { method: 'GET' });

	if (status !== 200) {
		throw Error(message);
	}

	return data;
});

const fetchNewsTypes = createAction('FETCH_NEWS_TYPES', () => async () => {
	const { status, message, data } = await wrapLangFetch('newsCategory', { method: 'GET' });

	if (status !== 200) {
		throw Error(message);
	}

	return data;
});

const fetchArticleTypes = createAction('FETCH_ARTICLE_TYPES', () => async () => {
	const { status, message, data } = await wrapLangFetch('postCategory', { method: 'GET' });

	if (status !== 200) {
		throw Error(message);
	}

	return data;
});

const fetchProductHots = createAction('FETCH_PRODUCT_HOTS', () => async () => {
	const { status, message, data } = await wrapLangFetch(
		'product/recommend',
		{
			method: 'GET',
		},
		{ is_homepage: 1 },
	);

	if (status !== 200) {
		throw Error(message);
	}

	return data.data;
});

const fetchCityList = createAction('FETCH_CITY_LIST', () => async () => {
	const { status, message, data } = await wrapFetch('city', {
		method: 'GET',
	});

	if (status !== 200) {
		throw Error(message);
	}

	const result = data.sort((a: CityType, b: CityType) => a.id - b.id);

	return result;
});

// TODO: fetch active city list （排除外島的縣市名單）
const fetchActiveCityList = createAction('FETCH_ACTIVE_CITY_LIST', () => async () => {
	const { status, message, data } = await wrapFetch(
		'city',
		{
			method: 'GET',
		},
		{
			is_active: 'true',
		},
	);

	if (status !== 200) {
		throw Error(message);
	}

	const result = data.sort((a: CityType, b: CityType) => a.id - b.id);

	return result;
});

export const initializeCommon = createAction(
	'INITIALIZE_COMMON',
	(queryUrl: string) => async (dispatch: Dispatch) => {
		dispatch(getLocalCompareList());
		dispatch(getLocalCartList());
		dispatch(getLocalInquireList());
		dispatch(fetchMeta(queryUrl));
		dispatch(fetchCategory());
		dispatch(fetchNewsTypes());
		dispatch(fetchArticleTypes());
		dispatch(fetchProductHots());
		dispatch(fetchCityList());
		dispatch(fetchActiveCityList());
		await dispatch(getAccountToken());
	},
);
export interface State {
	activePage: string;
	metaData: MetaType;
	categories: CategoryType[];
	newsTypes: NewsType[];
	articleTypes: ArticleType[];
	productHots: ProductHot[];
	cityList: CityType[];
	activeCityList: CityType[];
	commonInitialized: boolean;
}

export const initialState: State = {
	activePage: '',
	metaData: {
		meta_title: '',
		meta_description: '',
		meta_image: '',
		json_ld: '',
	},
	categories: [],
	newsTypes: [],
	articleTypes: [],
	productHots: [],
	cityList: [],
	activeCityList: [],
	commonInitialized: false,
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	common: handleActions<State, any>(
		{
			STORE_ACTIVE_PAGE: (state, action) => ({
				...state,
				activePage: action.payload,
			}),

			FETCH_META_FULFILLED: (state, action) => ({
				...state,
				metaData: action.payload ?? state.metaData,
			}),

			FETCH_CATEGORY_FULFILLED: (state, action) => ({
				...state,
				categories: action.payload,
			}),

			FETCH_NEWS_TYPES_FULFILLED: (state, action) => ({
				...state,
				newsTypes: action.payload,
			}),

			FETCH_ARTICLE_TYPES_FULFILLED: (state, action) => ({
				...state,
				articleTypes: action.payload,
			}),

			FETCH_PRODUCT_HOTS_FULFILLED: (state, action) => ({
				...state,
				productHots: action.payload,
			}),

			FETCH_CITY_LIST_FULFILLED: (state, action) => ({
				...state,
				cityList: action.payload,
			}),

			FETCH_ACTIVE_CITY_LIST_FULFILLED: (state, action) => ({
				...state,
				activeCityList: action.payload,
			}),

			INITIALIZE_COMMON_FULFILLED: state => ({
				...state,
				commonInitialized: true,
			}),
		},
		initialState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

const mapHooksToState = (state: GlobalState) => ({
	...state.common,
});

export const useCommon = () => useRedux(mapHooksToState, {});
