import { createAction, handleActions } from 'redux-actions';
import { Dispatch, AnyAction } from 'redux';
import { useRedux } from 'util/hook/redux';
import { wrapLangFetch } from 'util/api';
import { GetState, State as GlobalState } from '../reducers';

interface ProductProperty {
	id: number;
	pic: string;
	pic_alt: string;
	product_no: string;
	name: string;
}
// 從 API 成功返回的資料型別
interface FetchProductListPayload {
	productCategory: {
		meta_title: string;
		meta_description: string;
		json_ld: string;
		id: number;
		name: string;
        name_h2: string;
		info: string;
	};
	products: {
		data: ProductProperty[];
		meta: {
			current_page: number;
			last_page: number;
			total: number;
		};
	};
}
// 定義一個 Thunk Function 用於處理異步行為（發送 API）
const fetchProductList = createAction(
	'FETCH_PRODUCT_LIST',
	() => async (_: Dispatch, getState: GetState) => {
		const {
			routing: { queries },
		} = getState();

		const { status, message, data } = await wrapLangFetch(
			'product',
			{ method: 'GET' },
			{
				product_category_id: queries.category || '',
				page: +queries.page || 1,
				per_page: 12,
			},
		);

		if (status !== 200) {
			throw Error(message);
		}
        // console.log('data :>> ', data);
		return data;
	},
);
// 於路由載入時執行 dispatch
export const initializeProduct = createAction(
	'INITIALIZE_PRODUCT',
	() => async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fetchProductList());
	},
);
// Prodcut 於 Redux 中全域狀態型別
export interface State {
	meta: {
		title: string;
		description: string;
		json_ld: string;
	};
	category: {
		id: number;
		name: string;
        name_h2: string;
		description: string;
	};
	products: ProductProperty[];
	pagination: {
		current_page: number;
		last_page: number;
		total: number;
	};
}
// Product 全域狀態初始值
const initialState: State = {
	meta: {
		title: '',
		description: '',
		json_ld: '',
	},
	category: {
		id: 0,
		name: '',
        name_h2: '',
		description: '',
	},
	products: [],
	pagination: {
		current_page: 1,
		last_page: 0,
		total: 0,
	},
};
// Product Reducer
export const reducer = {
	product: handleActions<State, FetchProductListPayload>(
		{
            // API 成功返回資料後，將資料注入到 Product 全域狀態裡
			FETCH_PRODUCT_LIST_FULFILLED: (state, action) => ({
				...state,
				meta: {
					title: action.payload.productCategory?.meta_title,
					description: action.payload.productCategory?.meta_description,
					json_ld: action.payload.productCategory?.json_ld,
				},
				category: {
					id: action.payload.productCategory?.id,
					name: action.payload.productCategory?.name,
                    name_h2: action.payload.productCategory?.name_h2,
					description: action.payload.productCategory?.info,
				},
				products: action.payload.products?.data,
				pagination: action.payload.products?.meta,
			}),
		},
		initialState,
	),
};
// Product Selector
const mapHooksToState = (state: GlobalState) => ({
	...state.product,
});
// Redux 與 React 溝通的勾子
export const useProduct = () => useRedux(mapHooksToState, { fetchProductList });
