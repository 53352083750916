import { CustomRoute } from 'util/hook/useRouter';
import ResetPasswordRoute from './Reset';
import VerifyRoute from './Verify';
import LoginRoute from './Login';
import ForgetRoute from './Forget';

const routes: CustomRoute = {
	path: '/auth',
	components: () => [],
	render: (_, children) => children,
	children: [ResetPasswordRoute, VerifyRoute, LoginRoute, ForgetRoute],
};

export default routes;
