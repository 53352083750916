import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { storeActivePage } from 'models/common';
import { initializeProduct } from 'models/product/product';
import DetailRoute from './ProductDetail';

const routes: CustomRoute = {
	path: '/product',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'product' */ './component')],
			render: ([Product]) => <Product />,
			onEnter: async ({ store, next }) => {
				console.log('on Enter Product');
				const children = await next();
				store.dispatch(storeActivePage('product'));
				store.dispatch(initializeProduct());
				console.log('on Enter Product / end');
				return children;
			},
		},
		DetailRoute,
	],
};

export default routes;
