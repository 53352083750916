import { createAction, handleActions } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';

import { wrapLangFetch } from 'util/api';
import { pushQueries } from 'models/routing';
import { GetState, State as GlobalState } from '../reducers';

export interface NewsListType {
	id: number;
	news_category: {
		id: number;
		name: string;
	};
	title: string;
	list_info: string;
	published_at: string;
	pic: string;
}

export const setNewsPerPage = createAction('SET_NEWS_PER_PAGE', (perPage: number) => perPage);

export const fetchNewsLists = createAction(
	'FETCH_NEWS_LISTS',
	() => async (_: Dispatch, getState: GetState) => {
		const {
			news: { newsPerPage },
			routing: { queries },
		} = getState();

		const { status, message, data } = await wrapLangFetch(
			'news',
			{ method: 'GET' },
			{
				news_category_id: queries.type || '',
				sort: `${queries.sort || 'order'}|${queries.order || 'desc'}`,
				page: +queries.page || 1,
				per_page: newsPerPage,
			},
		);

		if (status !== 200) {
			throw Error(message);
		}

		return data;
	},
);

export const initializeNews = createAction(
	'INITIALIZE_NEWS',
	() => async (dispatch: Dispatch<AnyAction>) => {
		await dispatch(pushQueries({ queries: { sort: 'order', order: 'desc' } })); // default sort
		dispatch(fetchNewsLists());
	},
);

export interface State {
	meta: {
		title: string;
		description: string;
		json_ld: string;
	};
	type: {
		id: number;
		name: string;
	};
	newsPerPage: number;
	newsLists: NewsListType[];
	pagination: {
		current_page: number;
		last_page: number;
		total: number;
	};
}

const initialState: State = {
	meta: {
		title: '',
		description: '',
		json_ld: '',
	},
	type: {
		id: 0,
		name: '',
	},
	newsPerPage: window.innerWidth >= 1280 ? 6 : 3,
	newsLists: [],
	pagination: {
		current_page: 1,
		last_page: 0,
		total: 0,
	},
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	news: handleActions<State, any>(
		{
			SET_NEWS_PER_PAGE: (state, action) => ({
				...state,
				newsPerPage: action.payload,
			}),
			FETCH_NEWS_LISTS_FULFILLED: (state, action) => ({
				...state,
				meta: {
					title: action.payload.newsCategory?.meta_title,
					description: action.payload.newsCategory?.meta_description,
					json_ld: action.payload.newsCategory?.json_ld,
				},
				type: {
					id: action.payload.newsCategory?.id,
					name: action.payload.newsCategory?.name,
				},
				newsLists: action.payload.news?.data,
				pagination: action.payload.news?.meta,
			}),
		},
		initialState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

const mapHooksToState = (state: GlobalState) => ({
	...state.news,
});

export const useNews = () =>
	useRedux(mapHooksToState, {
		setNewsPerPage,
		fetchNewsLists,
	});
